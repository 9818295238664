/**
 * v0 by Vercel.
 * @see https://v0.dev/t/JfOFxENvIZp
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
"use client"

import { useState } from "react"
import { Input } from "@/components/ui/input"
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip"
import { Button } from "@/components/ui/button"
import { SquareArrowOutUpRight } from "lucide-react"
import { cn } from "@/lib/utils"

export default function CopyLink(code,classes) {
   
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(code.code)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }

  const handleRedirect = () => {
    window.open(url.url,"_blank")
  }
  return (
    <div className={cn(`flex justify-end items-center gap-2`,classes)}>
        {/* <span className="text-sm hidden md:flex">Airlink</span>
      <Input type="text" value={url.url} readOnly className="flex-1 lg:w-64 font-semibold" /> */}
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button className="h-8 self-end" onClick={handleCopy}>
              <CopyIcon className="h-4 w-4" />
              Copy Link
            </Button>
            
          </TooltipTrigger>
          <TooltipContent>Click to copy</TooltipContent>
        </Tooltip>
          
      </TooltipProvider>
      {isCopied && <div className="text-sm text-muted-foreground">Copied</div>}
    </div>
  )
}

function CopyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
      <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
    </svg>
  )
}