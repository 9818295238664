import React, { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { DateTimePicker } from "../Custom/DateTimePicker";
import TimelineComp from "../Custom/TimelineComp";
import { Separator } from "../ui/separator";
import { Card } from "../ui/card";
import { CircleCheckBig, Clock, Phone } from "lucide-react";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import LeadChatModal from "./lead-chat";
import ChatComponent from "@/components/ui/chat"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Switch } from "../ui/switch";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import instagram_logo from "../../assets/instagram.jpg";
import logout_logo from "../../assets/logout.png";
import chatwithio_logo from "../../assets/chatwithio.png";
import myoperator_logo from "../../assets/myoperator.png";
import website_logo from "../../assets/website.png";
import social from "@/assets/social.png";
import whatsapp_icon from '../../assets/WhatsApp.svg'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { EllipsisVertical } from "lucide-react";
import { ownerOptions } from "@/lib/data";
import axios from "axios";
import useLeadsStore from "@/store/leadsStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const sourceImgs = {
  instagram: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  chatbot: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  instacomment: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  whatsapp: <img src={whatsapp_icon} className="w-4 h-4" />,
  ChatWithIO: <img src={chatwithio_logo} className="w-4 h-4" />,
  website: (
    <img
      src="https://img.icons8.com/ios-glyphs/48/domain.png"
      className="w-4 h-4"
    />
  ),
  logout: <img src={logout_logo} className="w-4 h-4" />,
  facebook: (
    <img
      src="https://img.icons8.com/color/48/facebook-new.png"
      className="w-4 h-4"
    />
  ),
};

const LeadDetails = ({ lead }) => {
  const [isRemindersOpen, setIsRemindersOpen] = React.useState(false);
  const [isReminderTrue, setIsReminderTrue] = React.useState(false);
  const [remarks, setRemarks] = React.useState([]);
  const { teamMates } = useLeadsStore();
  const [remarkMessage, setRemarkMessage] = React.useState("");
  const [selectedTeamMate, setSelectedTeamMate] = React.useState(null);
  const [reminderTime, setReminderTime] = React.useState("");
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)
  function formatDateTime(dateTime) {
    if (!dateTime) {
      return null;
    }

    return new Date(dateTime).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  const getRemarks = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/company-enquiry-follow-up/${
          lead.id
        }/`
      );
      setRemarks(response.data);
    } catch (error) {
      console.error(
        "Error fetching team members:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const setTeamMatesState = (v) => {
    setSelectedTeamMate(teamMates.find((p) => p.label == v)?.value);
  };

  const handleReminderSave = async () => {
    setIsRemindersOpen(false);
    // Construct the formatted data
    const formattedData = {
      follow_up_remark: remarkMessage,
    };

    const formattedReminderData = {
      reminder_remark: remarkMessage,
      reminder_time: isReminderTrue && reminderTime ? reminderTime : null,
      asigned_to: selectedTeamMate,
    };
    if (isReminderTrue) {
      try {
        // Axios POST request
        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/edit-enquiry-reminder/${
            lead.id
          }/`,
          { enquiryReminder: formattedReminderData }
        );
      } catch (error) {
        console.error("Error saving data:", error);
      }
    } else {
      try {
        // Axios POST request
        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/edit-enquiry-remark/${
            lead.id
          }/`,
          { enquiryRemark: formattedData }
        );
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
    getRemarks();
  };

  React.useEffect(() => {
    getRemarks();
  }, []);


  return (
    <div>
      <ScrollArea className="p-4 max-h-[75vh] lg:max-h-[100vh] overflow-auto">
        <Tabs className="w-full mt-2" defaultValue="details">
          <TabsList className="ml-auto w-full">
            <TabsTrigger
              value="details"
              className="text-zinc-600 w-full dark:text-zinc-200"
            >
              Details
            </TabsTrigger>
            <TabsTrigger
              value="timeline"
              className="text-zinc-600 w-full dark:text-zinc-200"
            >
              Timeline
            </TabsTrigger>
            <TabsTrigger
              value="remarks"
              className="text-zinc-600 w-full dark:text-zinc-200"
            >
              Remarks
            </TabsTrigger>
            <TabsTrigger
                value="chat"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Chat
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details" className="w-full">
            <div className="flex flex-col gap-4 pt-4">
              <div className="flex items-start justify-between">
                <div className="flex items-end gap-2">
                  <span className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                    {lead.customer_profile?.fullname[0]}
                  </span>
                  <div>
                    <h2 className="font-semibold">
                      {lead.customer_profile?.fullname}
                    </h2>
                    <div className="flex items-center gap-1">
                    <span className="flex text-muted-foreground items-center gap-1">
                      {/* <FaWhatsapp size={14} /> */}

                      {lead.customer_profile?.mobile}
                    </span>
                    <div className="flex items-center justify-between">
                         
                         {lead.customer_profile?.mobile && (
                           <div className="flex items-center gap-1">
                             <img
                               onClick={() => {
                                 // setSelectedLead(item);
                                 setIsChatModalOpen(true);
                               }}
                               src={whatsapp_icon}
                               className="w-6 h-6"
                             />
                             <a className="text-primary hover:text-primary" href={`tel:${lead.customer_profile.mobile}`}>
                               <Phone size={18} />
                             </a>
                           </div>
                         )}
                       </div>
                    </div>
                  </div>
                 
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVertical className="mt-2" size={16} />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem>Edit Enquiry</DropdownMenuItem>
                    <DropdownMenuItem>View Details</DropdownMenuItem>
                    <DropdownMenuItem>Edit Customer Profile</DropdownMenuItem>
                    <DropdownMenuItem>Send Email</DropdownMenuItem>
                    <DropdownMenuItem>Delete Enquiry</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              {lead?.event && <div className="flex gap-2 items-center">
               <div className="flex gap-2 items-center">
                  <div className="relative w-8 h-8 rounded-md overflow-hidden">
                    <img
                      src={`${import.meta.env.VITE_APP_API_URL}${
                        lead.event?.pic
                      }`}
                      className="absolute object-cover w-full h-full"
                    />
                  </div>
                  <span className="font-medium text-sm ">
                    {lead.event?.name}
                  </span>
                </div>
              </div>}

              <div className="flex gap-2">
                {lead.is_pdf_downloaded && (
                  <Badge
                    className={
                      "items-center text-[0.6rem] rounded-md font-medium px-1 flex gap-1"
                    }
                    variant="outline"
                  >
                    <CircleCheckBig size={12} />
                    PDF Downloaded
                  </Badge>
                )}
                <Badge
                  className={
                    "items-center text-[0.6rem] rounded-md px-1 flex gap-1 font-medium"
                  }
                  variant={"outline"}
                >
                  {lead.source == "instagram" ||
                  lead.source == "instacomment" ||
                  lead.source == "chatbot" ? (
                    <div className="">
                      {/* <FaInstagram className="" size={16} /> */}
                      {sourceImgs[lead.source]}
                      {/* <Badge
                                           className={
                                             "items-center text-[0.6rem] bg-[#e1306c] font-medium rounded-md px-1 flex gap-1"
                                           }
                                           variant="default"
                                         >
                                           
                                         </Badge> */}
                    </div>
                  ) : lead?.source == "whatsapp" ? (
                    <div className="">
                      {sourceImgs[lead.source]}
                      {/* <Badge
                                           className={
                                             "items-center text-[0.6rem] bg-[#25d366] font-medium rounded-md px-1 flex gap-1"
                                           }
                                           variant="default"
                                         >
                                           
                                         </Badge> */}
                    </div>
                  ) : (
                    <div className="">
                      {/* {item?.source} */}
                      {lead?.source !== "website"
                        ? sourceImgs[lead.source]
                        : ""}

                      {lead?.source?.includes("website") ||
                      (lead?.origin_domain?.includes("http") &&
                        !lead?.origin_domain?.includes("/linkinbio") &&
                        !lead?.origin_domain?.includes("logout.world")) ? (
                        <div className="flex items-center gap-1">
                          {sourceImgs["website"]} <span>Website</span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!lead?.origin_domain?.includes("/linkinbio") &&
                        lead?.origin_domain?.includes("logout.world") && (
                          <div className="flex items-center gap-1">
                            {sourceImgs["logout"]} <span>Logout</span>
                          </div>
                        )}
                      {/* <Badge
                                           className={
                                             "items-center capitalize text-[0.6rem] font-medium rounded-md px-1 flex gap-1"
                                           }
                                           variant="outline"
                                         >
                                           
                                         </Badge> */}
                    </div>
                  )}
                  {lead.source !== "website" && (
                    <span className="capitalize">{lead.source}</span>
                  )}
                </Badge>
                <Badge
                  className={
                    "items-center text-[0.6rem] rounded-md px-1 flex gap-1 font-medium"
                  }
                  variant="outline"
                >
                  <Clock size={12} />
                  {formatDateTime(lead.created_at)}
                </Badge>
              </div>
              <div className="flex items-center justify-start gap-2">
                <span className="w-3 h-3 rounded-full bg-green-400"></span>
                <span className="text-xs text-muted-foreground">
                  Last Activity:{" "}
                  <span className="text-sm text-black">
                    {formatDateTime(lead.modified_at)}
                  </span>
                </span>
              </div>
              <Separator />
              <div>
                <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">
                      Preference
                    </span>
                    <span className="text-sm">
                      {lead.preferred_start_date ? formatDateTime(lead.preferred_start_date) : '--'}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">Pax</span>
                    <span className="text-sm">{lead.no_of_guests ? lead.no_of_guests : '--'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">
                      Enquiry type
                    </span>
                    <span className="text-sm">{lead.type ? lead.type : '--'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">
                      Category
                    </span>
                    <span className="text-sm">
                      {lead.preferred_event_category?.name ? lead.preferred_event_category?.name : '--'}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">
                      Customer Message
                    </span>
                    <span className="text-sm">{lead.message ? lead.message : '--'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">
                      Status
                    </span>
                    <span className="text-sm">{lead.status ? lead.status : '--'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">Stage</span>
                    <span className="text-sm">{lead.funnel_stage ? lead.funnel_stage : '--'}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-xs">
                      Assigned to
                    </span>
                    <span className="text-sm">{lead.asigned_to?.username ? lead.asigned_to?.username : '--'}</span>
                  </div>
                </div>
              </div>

              {/* <Separator className="my-2" /> */}
              {/* <div className="flex gap-2 items-center">
                <img src="/thumbnail.png" className="w-8 h-8 rounded-md" />
                <span className="font-medium">{lead.eventName}</span>
              </div> */}
              <Separator className="my-0" />
              <div>
                <div className="grid grid-cols-2 gap-y-4 justify-start items-end gap-2 w-full">
                  {/* <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button
                    variant="outline"
                    className="h-8 w-full items-center text-[0.6rem]"
                  >
                    <CirclePlus size={12} />
                    Remarks & Reminders
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => setIsRemarksOpen(true)}>
                    Add Remarks
                  </DropdownMenuItem>

                  <DropdownMenuItem onClick={() => setIsRemindersOpen(true)}>
                    Add Reminders
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu> */}
                  {/* <div className="flex flex-col gap-1">
                    <Label className="font-normal" htmlFor="status">
                      Status
                    </Label>
                    <ComboboxDemo id="status" options={statusOptions} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Label className="font-normal" htmlFor="status1">
                      Stage
                    </Label>
                    <ComboboxDemo id="status1" options={stageOptions} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Label className="font-normal" htmlFor="status2">
                      Owner
                    </Label>
                    <ComboboxDemo id="status2" options={ownerOptions} />
                  </div> */}
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="timeline" className=" h-full">
            <div className="max-h-screen overflow-y-auto">
              <TimelineComp lead={lead.id} />
            </div>
          </TabsContent>
          <TabsContent value="remarks" className="w-full h-full">
            <div className="mt-4">
              <div>
                <h2>Remarks</h2>
                <Separator />
              </div>
              <div className="mt-4 flex flex-col gap-4">
                {remarks?.map((remark, idx) => (
                  <Card key={idx} className="p-4">
                    <h2>{remark.follow_up_remark}</h2>
                    <p className="text-gray-500">
                      by:{" "}
                      <span>
                        {remark?.created_by?.username} @{" "}
                        {formatDateTime(remark?.follow_up_time)}
                      </span>
                    </p>
                  </Card>
                ))}
                <div className="flex justify-end">
                  <Button
                    onClick={() => {
                      setIsRemindersOpen(true);
                    }}
                    className=""
                  >
                    Add Remark
                  </Button>
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="chat" className=" h-full">
            <div className="max-h-screen overflow-y-auto">
              <ChatComponent number={lead.customer_profile?.mobile}/>
            </div>
          </TabsContent>
        </Tabs>
        {isChatModalOpen && (
          <LeadChatModal
            isOpen={isChatModalOpen}
            setIsOpen={setIsChatModalOpen}
            row={lead}
          />
        )}
        <Dialog open={isRemindersOpen} onOpenChange={setIsRemindersOpen}>
          <DialogContent className="lg:max-w-md">
            <DialogHeader>
              <DialogTitle className="text-lg">Add Remark</DialogTitle>
            </DialogHeader>
            <div className="flex flex-col gap-4">
              <div className="grid w-full gap-1.5">
                <Label htmlFor="remarkmessage">Remark</Label>
                <Textarea
                  className="h-[100px] focus-visible:ring-offset-0 focus-visible:ring-0"
                  placeholder="Type your message here."
                  id="remarkmessage"
                  value={remarkMessage}
                  onChange={(e) => setRemarkMessage(e.target.value)}
                />
              </div>
              <div className="flex justify-between items-center">
                <span>Set reminder</span>
                <Switch
                  defaultChecked={isReminderTrue}
                  onCheckedChange={setIsReminderTrue}
                  className="h-5"
                />
              </div>
              {isReminderTrue && (
                <div className="flex flex-col gap-2">
                  <div>
                    <Label htmlFor="remindto">Remind to</Label>
                    <Select
                      onValueChange={(value) => {
                        setSelectedTeamMate(value);
                      }}
                      required={true}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Assignee" />
                      </SelectTrigger>
                      <SelectContent>
                        {teamMates?.map((team) => (
                          <SelectItem
                            key={team.value}
                            value={team.value.toString()}
                          >
                            {team.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {/* <ComboboxDemo id="remindto" defaultValue={selectedTeamMate} options={teamMates} onChange={(value) => {console.log("combo",value);setTeamMatesState(value);}} /> */}
                  </div>
                  <div>
                    <Label htmlFor="remindto">Reminder time</Label>
                    <DateTimePicker
                      id="remindto"
                      value={reminderTime}
                      onChange={(value) => {
                        setReminderTime(value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="w-full">
              <Button className="w-full" onClick={() => handleReminderSave()}>
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </ScrollArea>
    </div>
  );
};

export default LeadDetails;
