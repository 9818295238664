import { AutoCommentorList } from "@/components/auto-commentor/auto-commentor";
import useFetch from "@/hooks/useFetchLSD";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { useAuthStore } from "@/store/store";

const AutoCommentorPage = () => {
  const [posts, setPosts] = useState([]);
  const [events, setEvents] = useState([]);
  const [loadedMore,setLoadedMore] = useState(false)
  const {companyslug} = useAuthStore();

  const {
    data: postData,
    isLoading,
    isFetching,
    error,
    refetch
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/${companyslug}/instagram/${loadedMore ? '?q=next' : ''}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );



  useEffect(() => {
    console.log(posts)
  },[posts])

  useEffect(() => {
    if (postData?.post_data?.length > 0) {
        setPosts((prevPosts) => {
            const existingIds = new Set(prevPosts?.map((post) => post.id)); // Collect existing IDs
            const newPosts = postData?.post_data.filter((post) => !existingIds.has(post.id)); // Filter out duplicates
            return [...prevPosts, ...newPosts];
        });
    }
}, [postData]);

  return (
    <>
    <h1 className="section_heading mb-4">Auto Commentor</h1>
    <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4">
      {isLoading && (
        <div className="flex flex-col md:flex-row items-center gap-4">
          {[1,2,3,4].map((item, index) => (
          <Card key={index} className="w-full card__content relative transition-transform duration-1000">
            <div class="card__front pb-2">
              <CardHeader className="p-2 pb-4">
                <div className="w-auto aspect-square relative overflow-hidden rounded-md">
                  <Skeleton className="w-full h-full" />
                </div>
              </CardHeader>
              <CardContent className="px-4 py-0 flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <Skeleton className="w-20 h-8" />
                  <Separator orientation="vertical" className="my-2 mx-2 h-6" />
                  <Skeleton className="w-20 h-8" />
                  <Separator orientation="vertical" className="my-2 mx-2 h-6" />
                  <Skeleton className="w-20 h-8" />
                </div>
                <Skeleton className="w-full h-6" />
                <Skeleton className="w-full h-6" />
              </CardContent>
            </div>
          </Card>
          ))}
        </div>
      )}
      {!isLoading && posts?.length>0 &&
        posts?.map((item, index) => {
          return (
            <AutoCommentorList
              key={item.id}
              eventList={postData.eventdata}
              post={item}
            />
          );
        })}
    </div>
        {postData?.has_next && <div className="flex justify-center w-full my-4">
          <Button className='w-28' onClick={()=>{setLoadedMore(true); refetch()}}>
            {isFetching ? <div className="dots"></div> : 'Load More'}
          </Button>
        </div>}
    </>
  );
};

export default AutoCommentorPage;
