import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "@/components/ui/label";
import { DateTimePicker } from "@/components/Custom/DateTimePicker";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  CirclePlus,
  EllipsisVertical,
  Info,
  TicketPlus,
  Trash,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Ticket } from "lucide-react";
import { MultiSelect } from "@/components/ui/multi-select";
import { Textarea } from "@/components/ui/textarea";
import axios from "axios";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { useCompanyStore } from "@/store/companyStore";
import { useMutation } from "react-query";
import { Skeleton } from "@/components/ui/skeleton";

const EditMemberships = ({ open, setIsOpen }) => {
  const [sellingPrices, setSellingPrices] = useState([]);
  const fileRef = useRef(null);
  const { companyslug } = useAuthStore((state) => state);
  const [existingPlans, setExistingPlans] = useState([]);
  const [newPlans, setNewPlans] = useState(0);
  const { companyData } = useCompanyStore();
  const [eventOptions, setEventOptions] = useState([]);

  useEffect(() => {
    var events = [];
    companyData?.events?.data?.map((event) => {
      events.push({ label: event.name, value: event.id.toString() });
    });
    setEventOptions(events);
  }, [companyData.events]);

  const {
    data: plans,
    isLoading,
    isError,
    refetch,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/get-membership-plans/?companyslug=${companyslug}`,
    {},
    {
      retry: 1,
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
   setExistingPlans(plans);
  }, [plans]);

  const removePrice = (planIndex, priceIndex) => {
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[planIndex].prices.splice(priceIndex, 1);
      return updatedPlans;
    });
  };

  console.log(eventOptions);

  const updatePlan = (index, field, value) => {
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index] = {
        ...updatedPlans[index],
        [field]: value, // Dynamically update the specified field
      };
      return updatedPlans;
    });
  };

  const updatePrices = (index, priceIndex, field, value) => {
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index].prices[priceIndex] = {
        ...updatedPlans[index].prices[priceIndex],
        [field]: value,
      };
      return updatedPlans;
    });
  };

  const handleFile = (index, file) => {
    console.log(file);
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index].cover_pic = file;
      return updatedPlans;
    });
  };

  const addPlan = () => {
    setNewPlans(newPlans + 1);
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans.push({
        name: "",
        discount: "",
        discount_type: "",
        details: "",
        active: false,
        visible: false,
        cover_pic: "",
        prices: [],
        events: [],
      });
      return updatedPlans;
    });
  };

  const addPrice = (planIndex) => {
    console.log("called", planIndex);
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[planIndex].prices.push({
        price: "",
        validity_duration_type: "",
      });
      return updatedPlans;
    });
  };

  const handleChange = (planIndex, priceIndex, key, value) => {
    setExistingPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[planIndex].prices[priceIndex][key] = value;
      return updatedPlans;
    });
  };

  const removePlan = (index, key) => {
    if (key == "new") {
      setNewPlans(newPlans - 1);
    }
    axios
      .post(
        `${import.meta.env.VITE_APP_API_URL}/v3/delete-membership-plan/${existingPlans[index].id}/`,
      )
      .then((res) => {
        console.log(res);
        refetch();
      });

  };

  

  const editPlan = (index) => {
    console.log(existingPlans);
    const formData = new FormData();
    const editedPlan = existingPlans[index];

    formData.append("companyslug", companyslug);
    if (editedPlan.id) {
      formData.append("id", editedPlan.id);
    }
    formData.append("name", editedPlan.name);
    formData.append("discount", editedPlan.discount);
    formData.append("discount_type", editedPlan.discount_type);
    formData.append("details", editedPlan.details);
    formData.append("active", editedPlan.active);
    formData.append("visible", editedPlan.visible);
    formData.append("cover_pic", editedPlan.cover_pic);
    formData.append("prices", JSON.stringify(editedPlan.prices));
    formData.append("events", editedPlan.events.toString());
    axios
      .post(
        `${import.meta.env.VITE_APP_API_URL}/v3/company-create-membership-api/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        refetch();
      });
    setNewPlans(0);
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={setIsOpen}>
        <DialogContent
          className={
            "lg:max-w-2xl p-4 overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Membership Plans</DialogTitle>
          </DialogHeader>
          {isLoading ? <div>
            <Skeleton className="w-32 h-4 mt-4"></Skeleton>
            <Skeleton className="w-32 h-4 mt-4"></Skeleton>
          </div> : <div className="w-full">
            <div className="py-4 flex flex-col gap-4">
              <div className="tickets_section">
                {existingPlans
                  ?.slice(0, existingPlans.length - newPlans)
                  ?.map((plan, index) => (
                    <>
                      <Accordion className="mb-4" type="single" collapsible>
                        <AccordionItem value="tickets">
                          <AccordionTrigger className="pb-1 pt-0">
                            <div className="flex items-center gap-1">
                              {plan.cover_pic && (
                                <img
                                  src={`${import.meta.env.VITE_APP_API_URL}${
                                    plan.cover_pic
                                  }`}
                                  className="w-8 h-8 rounded-md"
                                />
                              )}
                              {plan.name} -{" "}
                              {plan.discount_type == "Amount" ? "₹" : ""}
                              {plan.discount}
                              {plan.discount_type == "Percentage"
                                ? "%"
                                : ""}{" "}
                              off
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="flex pt-4 flex-col px-1 w-full gap-4">
                              <div className="flex flex-col gap-4">
                                <div className="flex w-full justify-between">
                                  <Label
                                    className="flex justify-between w-full"
                                    htmlFor="event_name"
                                  >
                                    Name
                                  </Label>
                                  <Input
                                    onChange={(e) =>
                                      updatePlan(index, "name", e.target.value)
                                    }
                                    className="max-w-48"
                                    id={`name-${plan.id}`}
                                    type="text"
                                    value={plan?.name}
                                  />
                                </div>
                                <div className="flex w-full justify-between">
                                  <Label
                                    className="flex justify-between w-full"
                                    htmlFor="event_name"
                                  >
                                    Cover Pic
                                  </Label>
                                  <Input
                                    onChange={(e) =>
                                      handleFile(index, e.target.files[0])
                                    }
                                    className="max-w-48 pt-[4px]"
                                    id={`cover-pic-${plan.id}`}
                                    type="file"
                                  />
                                </div>
                                <div className="flex justify-between gap-1">
                                  <Label htmlFor="event_value">
                                    Display On Website
                                  </Label>
                                  <Switch
                                    checked={plan.visible}
                                    onCheckedChange={(value) => {
                                      updatePlan(index, "visible", value);
                                    }}
                                    className="h-5"
                                    id="display"
                                  />
                                </div>
                                <div className="flex justify-between gap-1">
                                  <Label htmlFor="event_value">Active</Label>
                                  <Switch
                                    checked={plan.active}
                                    onCheckedChange={(value) => {
                                      updatePlan(index, "active", value);
                                    }}
                                    className="h-5"
                                    id="active"
                                  />
                                </div>
                                <div className="flex justify-between gap-1">
                                  <Label htmlFor="event_inventory">
                                    Type of Discount
                                  </Label>
                                  <Select
                                    // defaultValue="Percentage"
                                    value={plan.discount_type || "Percentage"}
                                    onValueChange={(value) => {
                                      updatePlan(index, "discount_type", value);
                                    }}
                                  >
                                    <SelectTrigger className="max-w-48">
                                      <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectItem value="Percentage">
                                        Percentage
                                      </SelectItem>
                                      <SelectItem value="Amount">
                                        Amount
                                      </SelectItem>
                                    </SelectContent>
                                  </Select>
                                </div>
                                <div className="flex justify-between gap-1">
                                  <Label htmlFor="event_value">Discount</Label>
                                  <Input
                                    onChange={(e) => {
                                      updatePlan(
                                        index,
                                        "discount",
                                        e.target.value
                                      );
                                    }}
                                    className="max-w-48"
                                    type="number"
                                    value={plan?.discount}
                                  />
                                </div>
                                <div>
                                  <div className="tickets_section">
                                    <Accordion
                                      className="mb-4"
                                      type="single"
                                      collapsible
                                    >
                                      <AccordionItem value="tickets">
                                        <AccordionTrigger className="pb-1 pt-0">
                                          <div className="flex items-center gap-1">
                                            <Ticket size={16} />
                                            Selling Prices
                                          </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                          {plan?.prices?.map(
                                            (price, priceIndex) => (
                                              <>
                                                <div className="flex pt-4 flex-col px-1 w-full gap-4">
                                                  <div className="flex items-center gap-4">
                                                    <div className="flex w-full flex-col gap-1">
                                                      <Label
                                                        className="flex justify-between w-full"
                                                        htmlFor="event_name"
                                                      >
                                                        Selling Price
                                                      </Label>
                                                      <Input
                                                        onChange={(e) =>
                                                          updatePrices(
                                                            index,
                                                            priceIndex,
                                                            "price",
                                                            e.target.value
                                                          )
                                                        }
                                                        id={`name-${price.id}`}
                                                        type="number"
                                                        className="max-w-48"
                                                        value={price.price}
                                                      />
                                                    </div>
                                                    <div className="flex w-full flex-col gap-1">
                                                      <Label
                                                        className="flex justify-between w-full"
                                                        htmlFor="event_name"
                                                      >
                                                        Validity Duration
                                                      </Label>
                                                      <Input
                                                        onChange={(e) =>
                                                          updatePrices(
                                                            index,
                                                            priceIndex,
                                                            "validity_duration",
                                                            e.target.value
                                                          )
                                                        }
                                                        id={`name-${price.id}`}
                                                        type="number"
                                                        className="max-w-48"
                                                        value={
                                                          price.validity_duration
                                                        }
                                                      />
                                                    </div>
                                                    <div className="flex flex-col gap-1 w-full">
                                                      <Label htmlFor="event_value">
                                                        Validity Duration Type
                                                      </Label>
                                                      <Select
                                                        value={
                                                          price.validity_duration_type || ""
                                                        }
                                                        onValueChange={(
                                                          value
                                                        ) => {
                                                          updatePrices(
                                                            index,
                                                            priceIndex,
                                                            "validity_duration_type",
                                                            value
                                                          );
                                                        }}
                                                      >
                                                        <SelectTrigger className="max-w-48">
                                                          <SelectValue placeholder="Select Duration" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                          <SelectItem value="Days">
                                                            Days
                                                          </SelectItem>
                                                          <SelectItem value="Weeks">
                                                            Weeks
                                                          </SelectItem>
                                                          <SelectItem value="Months">
                                                            Months
                                                          </SelectItem>
                                                          <SelectItem value="Years">
                                                            Years
                                                          </SelectItem>
                                                        </SelectContent>
                                                      </Select>
                                                    </div>
                                                  </div>
                                                  <div className="flex gap-2 justify-end items-center">
                                                    {priceIndex ==
                                                      plan.prices.length -
                                                        1 && (
                                                      <div
                                                        onClick={
                                                          () =>
                                                            addPrice(
                                                              index,
                                                              priceIndex
                                                            )
                                                          // handleAddTicket(index)
                                                        }
                                                        className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                                      >
                                                        <CirclePlus size={16} />
                                                        <span className="underline">
                                                          Add Prices
                                                        </span>
                                                      </div>
                                                    )}
                                                    <div
                                                      onClick={
                                                        () =>
                                                          removePrice(
                                                            index,
                                                            priceIndex
                                                          )
                                                        // handleAddTicket(index)
                                                      }
                                                      className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                                    >
                                                      <Trash size={16} />
                                                      <span className="underline">
                                                        Remove
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )}
                                          {plan.prices.length==0 && (
                                            <div
                                              onClick={
                                                () =>
                                                  addPrice(index)
                                                // handleAddTicket(index)
                                              }
                                              className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                            >
                                              <CirclePlus size={16} />
                                              <span className="underline">
                                                Add Prices
                                              </span>
                                            </div>
                                          )}
                                        </AccordionContent>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <Label htmlFor="event_inventory">
                                    Applicable Events
                                  </Label>
                                  {eventOptions?.length > 0 && (
                                    <MultiSelect
                                      defaultValue={plan.events}
                                      options={eventOptions}
                                      onValueChange={(value) => {
                                        updatePlan(index, "events", value);
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="flex flex-col gap-1">
                                  <Label htmlFor="event_inventory">
                                    Plan Details
                                  </Label>
                                  <Textarea
                                    onChange={(e) => {
                                      updatePlan(
                                        index,
                                        "details",
                                        e.target.value
                                      );
                                    }}
                                    value={plan.details}
                                  />
                                </div>
                                <div className="flex gap-4 justify-end items-center">
                                  <div
                                    onClick={() => removePlan(index)}
                                    className="flex gap-2 items-center"
                                  >
                                    <Trash size={16} />
                                    <span className="underline">Remove</span>
                                  </div>
                                  <div>
                                    <Button onClick={() => editPlan(index)}>
                                      Update
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </>
                  ))}
              </div>
              <div>
                {newPlans > 0 &&
                  existingPlans
                    .slice(
                      existingPlans.length - newPlans,
                      existingPlans.length
                    )
                    .map((plan, index) => (
                      <div className="flex pt-4 flex-col px-1 w-full gap-4">
                        <div className="flex flex-col gap-4">
                          <div className="flex w-full justify-between">
                            <Label
                              className="flex justify-between w-full"
                              htmlFor="event_name"
                            >
                              Name
                            </Label>
                            <Input
                              onChange={(e) =>
                                updatePlan(
                                  existingPlans.length - newPlans + index,
                                  "name",
                                  e.target.value
                                )
                              }
                              className="max-w-48"
                              id={`name-${plan.id}`}
                              type="text"
                              value={plan?.name}
                            />
                          </div>
                          <div className="flex w-full justify-between">
                            <Label
                              className="flex justify-between w-full"
                              htmlFor="event_name"
                            >
                              Cover Pic
                            </Label>
                            <Input
                              onChange={(e) =>
                                handleFile(
                                  existingPlans.length - newPlans + index,
                                  e.target.files[0]
                                )
                              }
                              className="max-w-48 pt-[4px]"
                              id={`cover-pic-${plan.id}`}
                              type="file"
                            />
                          </div>
                          <div className="flex justify-between gap-1">
                            <Label htmlFor="event_value">
                              Display On Website
                            </Label>
                            <Switch
                              checked={plan.visible}
                              onCheckedChange={(value) => {
                                updatePlan(
                                  existingPlans.length - newPlans + index,
                                  "visible",
                                  value
                                );
                              }}
                              className="h-5"
                              id="display"
                            />
                          </div>
                          <div className="flex justify-between gap-1">
                            <Label htmlFor="event_value">Active</Label>
                            <Switch
                              checked={plan.active}
                              onCheckedChange={(value) => {
                                updatePlan(
                                  existingPlans.length - newPlans + index,
                                  "active",
                                  value
                                );
                              }}
                              className="h-5"
                              id="active"
                            />
                          </div>
                          <div className="flex justify-between gap-1">
                            <Label htmlFor="event_inventory">
                              Type of Discount
                            </Label>
                            <Select
                              // defaultValue="Percentage"
                              value={plan.discount_type || "Percentage"}
                              onValueChange={(value) => {
                                updatePlan(
                                  existingPlans.length - newPlans + index,
                                  "discount_type",
                                  value
                                );
                              }}
                            >
                              <SelectTrigger className="max-w-48">
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Percentage">
                                  Percentage
                                </SelectItem>
                                <SelectItem value="Amount">Amount</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="flex justify-between gap-1">
                            <Label htmlFor="event_value">Discount</Label>
                            <Input
                              onChange={(e) => {
                                updatePlan(
                                  existingPlans.length - newPlans + index,
                                  "discount",
                                  e.target.value
                                );
                              }}
                              className="max-w-48"
                              type="number"
                              value={plan?.discount}
                            />
                          </div>
                          <div>
                            <div className="tickets_section">
                              <Accordion
                                className="mb-4"
                                type="single"
                                collapsible
                              >
                                <AccordionItem value="tickets">
                                  <AccordionTrigger className="pb-1 pt-0">
                                    <div className="flex items-center gap-1">
                                      <Ticket size={16} />
                                      Selling Prices
                                    </div>
                                  </AccordionTrigger>
                                  <AccordionContent>
                                    {plan?.prices?.map((price, priceIndex) => (
                                      <>
                                        <div className="flex pt-4 flex-col px-1 w-full gap-4">
                                          <div className="flex items-center gap-4">
                                            <div className="flex w-full flex-col gap-1">
                                              <Label
                                                className="flex justify-between w-full"
                                                htmlFor="event_name"
                                              >
                                                Selling Price
                                              </Label>
                                              <Input
                                                onChange={(e) =>
                                                  updatePrices(
                                                    index,
                                                    priceIndex,
                                                    "price",
                                                    e.target.value
                                                  )
                                                }
                                                id={`name-${price.id}`}
                                                type="number"
                                                className="max-w-48"
                                                value={price.price}
                                              />
                                            </div>
                                            <div className="flex w-full flex-col gap-1">
                                              <Label
                                                className="flex justify-between w-full"
                                                htmlFor="event_name"
                                              >
                                                Validity Duration
                                              </Label>
                                              <Input
                                                onChange={(e) =>
                                                  updatePrices(
                                                    index,
                                                    priceIndex,
                                                    "validity_duration",
                                                    e.target.value
                                                  )
                                                }
                                                id={`name-${price.id}`}
                                                type="number"
                                                className="max-w-48"
                                                value={price.validity_duration}
                                              />
                                            </div>
                                            <div className="flex flex-col gap-1 w-full">
                                              <Label htmlFor="event_value">
                                                Validity Duration Type
                                              </Label>
                                              <Select
                                                value={
                                                  price.validity_duration_type || ""
                                                }
                                                onValueChange={(value) => {
                                                  updatePrices(
                                                    index,
                                                    priceIndex,
                                                    "validity_duration_type",
                                                    value
                                                  );
                                                }}
                                              >
                                                <SelectTrigger className="max-w-48">
                                                  <SelectValue placeholder="Select Duration" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                  <SelectItem value="Days">
                                                    Days
                                                  </SelectItem>
                                                  <SelectItem value="Weeks">
                                                    Weeks
                                                  </SelectItem>
                                                  <SelectItem value="Months">
                                                    Months
                                                  </SelectItem>
                                                  <SelectItem value="Years">
                                                    Years
                                                  </SelectItem>
                                                </SelectContent>
                                              </Select>
                                            </div>
                                          </div>
                                          <div className="flex gap-2 justify-end items-center">
                                            {(
                                              priceIndex ==
                                                plan.prices.length - 1) && (
                                              <div
                                                onClick={
                                                  () =>
                                                    addPrice(
                                                      existingPlans.length -
                                                        newPlans +
                                                        index,
                                                      priceIndex
                                                    )
                                                  // handleAddTicket(index)
                                                }
                                                className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                              >
                                                <CirclePlus size={16} />
                                                <span className="underline">
                                                  Add Prices
                                                </span>
                                              </div>
                                            )}
                                            <div
                                              onClick={
                                                () =>
                                                  removePrice(
                                                    existingPlans.length -
                                                      newPlans +
                                                      index,
                                                    priceIndex
                                                  )
                                                // handleAddTicket(index)
                                              }
                                              className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                            >
                                              <Trash size={16} />
                                              <span className="underline">
                                                Remove
                                              </span>
                                            </div>
                                          </div>
                                         
                                        </div>
                                      </>
                                    ))}
                                     {plan.prices.length==0 && (
                                            <div
                                              onClick={
                                                () =>
                                                  addPrice(existingPlans.length -
                                                    newPlans +
                                                    index)
                                                // handleAddTicket(index)
                                              }
                                              className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                            >
                                              <CirclePlus size={16} />
                                              <span className="underline">
                                                Add Prices
                                              </span>
                                            </div>
                                          )}
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <Label htmlFor="event_inventory">
                              Applicable Events
                            </Label>
                            {eventOptions?.length > 0 && (
                              <MultiSelect
                                defaultValue={plan.events}
                                options={eventOptions}
                                onValueChange={(value) => {
                                  updatePlan(
                                    existingPlans.length - newPlans + index,
                                    "events",
                                    value
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="flex flex-col gap-1">
                            <Label htmlFor="event_inventory">
                              Plan Details
                            </Label>
                            <Textarea
                              onChange={(e) => {
                                updatePlan(
                                  existingPlans.length - newPlans + index,
                                  "details",
                                  e.target.value
                                );
                              }}
                              value={plan.details}
                            />
                          </div>
                          <div className="flex justify-end gap-4">
                            <div
                              onClick={() => {
                                removePlan(
                                  existingPlans.length - newPlans + index,
                                  "new"
                                );
                              }}
                              className="flex gap-2 justify-end items-center"
                            >
                              <Trash size={16} />
                              <span className="underline">Remove</span>
                            </div>
                            <div>
                              <Button
                                onClick={() =>
                                  editPlan(
                                    existingPlans.length - newPlans + index
                                  )
                                }
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>

              <div onClick={addPlan} className="flex justify-end mt-0">
                <Button className=" w-24 ">Add Plan</Button>
              </div>
            </div>
            {/* <Button className="h-8" >
                  Submit
                </Button> */}
          </div>}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditMemberships;
