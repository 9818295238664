import { create } from 'zustand';
import axios from 'axios';
import { persist, createJSONStorage } from 'zustand/middleware'

// Function to load user data from local storage
const loadFromLocalStorage = () => {
  const storedData = localStorage.getItem('userData');
  return storedData ? JSON.parse(storedData) : null;
};

// Store for managing user-related data
const userStore = (set, get) => ({
  // User-related data (initially from localStorage or empty if not present)
  userData: loadFromLocalStorage() || {
    users: [],
    selectedUser: null,
  },
  tabs: [],

  // Function to fetch and store a specific user's details
  fetchUserDetails: async () => {
    set({ isLoading: true, isError: false });

    try {
      const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/user/`);
      set({
        userData: {
          ...get().userData,
          user: response.data,
        },
        isLoading: false,
      });
      // Save updated user data to local storage
      localStorage.setItem('userData', JSON.stringify(get().userData));
    } catch (error) {
      set({ isLoading: false, isError: true, error });
    }
  },

  fetchTabsDetails: async (slug) => {
    set({ isLoading: true, isError: false });
  
    try {
      const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/access-control/${slug}/`);
      set({
        userData: {
          ...get().userData, // Preserve other data in userData
        },
        tabs: response.data, // Assuming you want to store the tabs in the 'tabs' field
        isLoading: false,
      });
    } catch (error) {
      set({ isLoading: false, isError: true, error });
    }
  },

  // Update a specific user's information
  updateUserData: (userId, updatedData) => {
    set((state) => {
      const updatedUsers = state.userData.users.map(user =>
        user.id === userId ? { ...user, ...updatedData } : user
      );
      const updatedUserData = { ...state.userData, users: updatedUsers };
      
      // Save the updated data to local storage
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      
      return { userData: updatedUserData };
    });
  },

  // Function to clear all user data from the store and localStorage
  clearUserData: () => {
    localStorage.removeItem('userData');
    set({
      userData: {
        users: [],
        selectedUser: null,
      },
    });
  },

  // Set loading and error states
  setLoading: (loading) => set({ isLoading: loading }),
  setError: (error) => set({ isError: error }),
});

const useUserStore = create(persist(userStore, {
  name: "user",
  storage: createJSONStorage(() => localStorage),
}));

export { useUserStore };