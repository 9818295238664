import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "../ui/switch";
import useLeadsStore from "@/store/leadsStore";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import axios from "axios";
import { useAuthStore } from "@/store/store";
import { useToast } from "@/hooks/use-toast";

const AssignLeadsModal = ({ isOpen, setIsOpen, selectedRows }) => {
  const { teamMates } = useLeadsStore();
  const {companyslug} = useAuthStore()
  const [selectedOwner, setSelectedOwner] = useState(null)
  const [notifyOwner, setNotifyOwner] = useState(false)
  const {toast} = useToast()

  const handleAssign = async () => {
    const data = {
        enquiry_list: Object.keys(selectedRows),
        owner_id: selectedOwner,
        notify_owner: notifyOwner
    }
    try{

        const res = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/assign-bulk-enquiry/${companyslug}/`, data)
        if(res.status == 200){
            toast({
                message: 'Updated Successfully',
                variant: 'success',
                duration: 2000
            })
        }
    }
    catch(err){
        toast({
            message: err.response.data.message,
            variant: 'error',
            duration: 2000
        })
    }
    setIsOpen(false)
   
  }

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={"overflow-y-scroll max-h-[90vh] lg:max-w-md"}>
          <DialogHeader>
            <DialogTitle>Bulk Assign Leads</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-3">
            {/* Owner Section */}
            <div className="flex flex-col gap-1">
              <Label>Owner</Label>
              <div className="flex items-center gap-2">
                <Select onValueChange={(value) => setSelectedOwner(value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Assignee" />
                  </SelectTrigger>
                  <SelectContent>
                    {teamMates?.map((team) => (
                      <SelectItem key={team.value} value={team.value}>
                        {team.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Notify Owner Section */}
            <div className="flex flex-col gap-1">
              <Label>Notify Owner</Label>
              <Switch onCheckedChange={setNotifyOwner} checked={notifyOwner} className="h-5" />
            </div>

            {/* Assign Button */}
            <Button onClick={handleAssign}>Assign</Button>
          </div>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssignLeadsModal;
