"use client";
import { useEffect, useState } from "react";
import errorGIF from './error.gif'
import { ErrorBoundary } from "react-error-boundary";
import AppRoutes from "./AppRoutes";
import { ThemeProvider } from "./context/theme-provider";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import { QueryClient, QueryClientProvider } from "react-query";
import { useCompanyStore } from "@/store/companyStore";
import { useUserStore } from "@/store/userStore";
import { useAuthStore } from "@/store/store";
import setupAxios from "./setup/axiosSetup";
import axios from "axios";
import { Toaster } from "@/components/ui/toaster";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://791ce708b82f421e8fd208f14d15a278@o1267431.ingest.us.sentry.io/4508719965536256",
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

function App() {
  const getQueryParam = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search); // Get URL parameters
    return urlParams.get(paramName); // Get the value of the specified query parameter
  };

  const [completed, setCompleted] = useState(false);
  const setUser = useAuthStore((state) => state.setUser);

  const {
    companyslug,
    companyData,
    fetchTeamMembers,
    fetchEvents,
    fetchCategories,
    fetchCompanyData,
    isLoading,
    isError,
  } = useCompanyStore((state) => state);
  const { fetchUserDetails } = useUserStore();

  const token = getQueryParam("access_token");
  useEffect(() => {
    setCompleted(true);
  }, []);

  function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
      <div role="alert pt-16">
       
        <div className="error_image mt-8" style={{backgroundImage: `url(${errorGIF})`, height: '400px', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'}}></div>
        <h2 className="text-2xl font-semibold text-center">Uh oh, something unexpected happened. <br></br>
         <span className="text-xl font-medium"> Refresh the page and try once more</span></h2>
      </div>
    );
  }

  return (
    <>
      {/* <ThemeProvider> */}
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary fallbackRender={fallbackRender}>
          {<AppRoutes />}
        </ErrorBoundary>

        <Toaster />

        {/* <Fireworks  autorun={{ speed: 3, duration: 8 }} />; */}
      </QueryClientProvider>
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
