import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { PhoneInput } from "../ui/phone-input";
import { useCompanyStore } from "@/store/companyStore";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import axios from "axios";
import { format } from "date-fns";
import { useAuthStore } from "@/store/store";
import { parsePhoneNumber } from "react-phone-number-input";
import useLeadsStore from "@/store/leadsStore";

const AddLeadModal = ({ isOpen, setIsOpen }) => {
  const allevents = useCompanyStore().companyData.events.data;
  const [batches, setBatches] = useState([]);
  const [formData, setFormData] = useState({})
  const [customerProfileData, setCustomerProfileData] = useState([])
  const [loading, setLoading] = useState(false)
  const {companyslug} = useAuthStore()
  const {refetchApi} = useLeadsStore()

  const getBatches = async (value) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/event-batches/${value}/`
      );
      console.log(response.data);
      setBatches(response.data);
    } catch (error) {
      console.error(
        "Error fetching batches:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleEventChange = async (value) => {
    setFormData({ ...formData, event: {slug: value} });
    await getBatches(value);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = async () => {
    console.log(formData);
    setLoading(true)
    const res = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/add-company-enquiry/${companyslug}/`,{
      ...formData,
      customer_profile_data: customerProfileData
    })

    setLoading(false)
    setIsOpen(false)
    refetchApi()
  }

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Enquiry</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <Label>Name</Label>
              <Input
                type="text"
                className="max-w-48"
                placeholder="Enter Name"
                name="fullname"
                onChange={(e)=>{setCustomerProfileData({...customerProfileData, fullname:e.target.value})}}
              />
            </div>
            <div className="flex justify-between items-center">
              <Label>Phone Number</Label>
              <PhoneInput
                placeholder="8123456789"
                className="max-w-48"
                defaultCountry="IN"
                onChange={(value)=>{ setCustomerProfileData({...customerProfileData, mobile:parsePhoneNumber(value)?.nationalNumber})}}
              />
            </div>
            <div className="flex justify-between items-center">
              <Label>Event</Label>
              <Select
                className="max-w-48"
                onValueChange={(value) => handleEventChange(value)}
                // value={formData.event?.slug || formData.event}
              >
                <SelectTrigger className="max-w-48">
                  <SelectValue placeholder="Select Event" />
                </SelectTrigger>
                <SelectContent>
                  {allevents?.map((event) => (
                    <SelectItem
                      key={event.slug}
                      value={event.slug || "--"}
                      className="max-w-48"
                    >
                      {event.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-between items-center">
              <Label>Batches</Label>
              <Select
                className="max-w-48"
                onValueChange={(value) => setFormData({ ...formData, eventbatches: {id: value} })}
                // value={formData.event?.slug || formData.event}
              >
                <SelectTrigger className="max-w-48">
                  <SelectValue placeholder="Select Batch" />
                </SelectTrigger>
                <SelectContent>
                  {batches?.map((batch) => (
                    <SelectItem
                      key={batch.id}
                      value={batch.id || "--"}
                      className="max-w-48"
                    >
                      {batch.startdate}{" - "}{format(new Date(batch.starttime), "hh:mm a")} 
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-between items-center">
              <Label>No. of guests</Label>
              <Input
                className="max-w-48"
                placeholder="Enter No. of guests"
                type="number"
                name="no_of_guests"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Label>Customer Message</Label>
              <Textarea name="message" onChange={handleChange} placeholder="Enter Message" />
            </div>
            <div className="flex justify-end">
              <Button className="w-24" onClick={handleSubmit}>
                {loading ? <div className="dots"></div> : 'Update'}
                </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddLeadModal;
