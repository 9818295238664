import React, { useEffect } from "react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import { Switch } from "@/components/ui/switch";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import { getEventBatches } from "./apiservice";
import { useCompanyStore } from "@/store/companyStore";
import { set } from "lodash";

const BuyerDetails = ({ form, eventId, batchId, formData, allevents  }) => {
  const [batchList,setBathchList] = React.useState([]);
  const [eventList,setEventList] = React.useState([])
 

  useEffect(() => {
    if(!eventId){
    setEventList([
      { value: "-1", label: "Select Event" },
      ...allevents.map((event) => ({
        value: `${event.id}`,
        label: `${event.name}`,
      })),
    ]);
  }else{
    setEventList([
      {value:`${eventId}`,label:`${allevents?.find((event)=>event.id.toString()===eventId.toString())?.name}`},
      ...allevents.map((event) => ({
        value: `${event.id}`,
        label: `${event.name}`,
      })),
    ]);
  }
  }, [eventId]);

  useEffect(()=>{
    if(eventId)
    form.setValue("event",`${eventId}`);
  },[eventId])

  useEffect(()=>{
    if(batchId)
    form.setValue("batch",`${batchId}`);
  },[batchId])
 
  const event_id = form.watch("event");
  const selectedBatch = form.watch("batch");

  // const getEventBatches = async (id) => {
  //   await getBatches(id);
  // }

  const createBatchList = async function(){
    if(event_id){
      const {data,error} = await getEventBatches(event_id);
      
      if(batchId){
        console.log("BATCH",batchId)
        setBathchList([{value:`${batchId}`,label:`${data?.data.find((batch)=>batch.id.toString()===batchId.toString())?.startdate} - ${data?.data.find((batch)=>batch.id.toString()===batchId.toString())?.starttime || ""}`},
          ...data?.data?.map((batch)=>{return {value:`${batch.id}`,label:`${batch.startdate} - ${batch.starttime || ""}`}})]
        );
        form.setValue("batch",`${batchId}`);
      }
      else{
        setBathchList([{value:"-1",label:"Select Batch"},
          ...data?.data?.map((batch)=>{return {value:`${batch.id}`,label:`${batch.startdate} - ${batch.starttime || ""}`}})
        ]);
      form.setValue("batch",`${data?.data[0]?.id}`);
      }
    }
  }


  useEffect(() => {
    createBatchList();
  },[event_id])


  return (
    <div className="flex md:h-auto px-4 flex-col">
      {event_id && <div className="flex mx-auto items-center gap-2 pb-4">
        <div className="w-8 h-8 rounded-md relative overflow-hidden">
        <img src={`${import.meta.env.VITE_APP_API_URL}${allevents?.find((event)=>event.id.toString()===event_id.toString())?.image}`} className="h-full w-full absolute top-0 left-0 object-cover" />
        </div>
        <h2 className="text-sm leading-5 max-w-[12rem]">{allevents?.find((event)=>event.id.toString()===event_id.toString())?.name}</h2>
      </div>}
      <div className="flex flex-col gap-4">
        <FormField
          name="event"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Select Event</FormLabel>
              <FormControl>
                {<ComboboxDemo
                  fontSize={"text-sm"}
                  onChange={() => {}}
                  options={eventList}
                  {...field}
                 
                />}
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="batch"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Select Batch</FormLabel>
              <FormControl>
              {<ComboboxDemo
                  fontSize={"text-sm"}
                  onChange={() => {}}
                  // defaultValue={'1277'}
                  options = {batchList}
                
                  
                  {...field}
                /> }
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="buyerData.name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input required placeholder="Full Name" type="text" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="buyerData.number"
          render={({ field }) => (
            <FormItem className="flex mt-1 flex-col items-start">
              <FormLabel>Phone number (WhatsApp)</FormLabel>
              <FormControl className="w-full">
                <PhoneInput
                required
                  placeholder="8123456789"
                  {...field}
                  defaultCountry="IN"
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="buyerData.email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input required placeholder="Email Address" type="email" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default BuyerDetails;
