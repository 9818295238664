import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useAuthStore } from "@/store/store";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Info } from "lucide-react";
import { useState } from "react";
import { Textarea } from "../ui/textarea";
import { Checkbox } from "../ui/checkbox";
import axios from "axios";

const InitiateRefundModal = function ({ item, isOpen, setIsOpen }) {
  const { companyslug } = useAuthStore();
  const [refundAmount, setRefundAmount] = useState(
    ((item.amount - item.amount_refunded) / 100).toFixed(2)
  );
  const [refundMessage, setRefundMessage] = useState();
  const [bookingCancelled, setBookingCancelled] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [error, setError] = useState(false);
  const initiateRefund = async function () {
    setRefunding(true);
    const res = await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/refund/`,
      {
        bookingId: item.id,
        refundAmount: refundAmount,
        refundCancledBooking: bookingCancelled,
      }
    );
    console.log(res);
    if (res.status == 200) {
      const resp = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/refund-mail/`,
        {
          bookingId: item.id,
          refundAmount: $scope.refundAmount,
          refundMessage: $scope.refundMessage,
        }
      );
      console.log(resp)
    }
    setRefunding(false)
    setIsOpen(false)
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="lg:max-w-md max-w-sm">
        <DialogHeader>
          <DialogTitle>Initiate Refund</DialogTitle>
        </DialogHeader>
        Are you sure want to refund this payment?
        <div className="flex items-center gap-6">
          <div className="flex flex-col gap-1">
            <span className="text-muted-foreground">Name</span>
            <span>{item.name}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-muted-foreground">Total Amount</span>
            <span>&#8377;{(item.amount / 100).toFixed(2)}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-muted-foreground">Amount Refunded</span>
            <span>&#8377;{(item.amount_refunded / 100).toFixed(2)}</span>
          </div>
        </div>
        <div className="flex items-end gap-2">
          <div className="flex-grow">
            <Label>Refund Amount</Label>
            <Input
              min={1}
              max={(item.amount - item.amount_refunded) / 100}
              onChange={(e) => {
                setError(false);
                if (
                  e.target.value >
                  (item.amount - item.amount_refunded) / 100
                ) {
                  setError(true);
                } else {
                  setRefundAmount(e.target.value);
                }
              }}
              value={refundAmount}
              defaultValue={(
                (item.amount - item.amount_refunded) /
                100
              ).toFixed(2)}
              type="number"
              placeholder="Enter refund amount"
            />
          </div>
          <div className="flex items-center gap-1">
            <Info size={14} />
            <span className="text-sm">
              {refundAmount < (item.amount - item.amount_refunded) / 100
                ? "Partial Refund"
                : "Full Refund"}
            </span>
          </div>
        </div>
        {error && (
          <span className="text-xs text-red-500 mt-[-10px]">
            Refund amount must be less than total amount
          </span>
        )}
        <div className="flex-grow">
          <Label>Message</Label>
          <Textarea
            value={refundMessage}
            onChange={(e) => setRefundMessage(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            checked={bookingCancelled}
            onCheckedChange={(value) => setBookingCancelled(value)}
            className="h-4 w-4"
          />
          <Label>Cancel booking</Label>
        </div>
        <div className="space-y-2 flex gap-2 justify-end items-center space-x-6">
          <Button disabled={error} variant="default" className="w-32" onClick={initiateRefund}>
            {refunding ? <div className="dots"></div> : 'Initiate Refund'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InitiateRefundModal;
