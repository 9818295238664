"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const frameworks = [
  {
    value: "next.js",
    label: "Next.js",
  },
  {
    value: "sveltekit",
    label: "SvelteKit",
  },
  {
    value: "nuxt.js",
    label: "Nuxt.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
];

export function ComboboxDemo({
  defaultValue,
  options,
  width,
  fontSize,
  classes,
  selectedOption,
  onChange,
  onUpdate,
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // React.useEffect(() => {
  //  onChange(value)
  // },[value])

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full px-2 h-8 ${classes} ${
            fontSize ? fontSize : "text-[0.6rem]"
          } leading-4 items-center justify-between`}
        >
          <span className="max-w-48 truncate">
            {value
              ? options.find((framework) => framework.value === value)?.label
              : options[0]?.label}
          </span>
          <ChevronsUpDown size={14} className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={`${
          width ? "width" : "w-[--radix-popover-trigger-width]"
        } p-0`}
      >
        <Command
          filter={(value, search, keywords = []) => {
            const extendValue = value + " " + keywords.join(" ");
            if (extendValue.toLowerCase().includes(search.toLowerCase())) {
              return 1;
            }
            return 0;
          }}
          className={`${fontSize ? fontSize : "text-[0.6rem]"}`}
        >
          <CommandInput autoFocus={false} placeholder="Search" />
          <CommandList>
            {/* <CommandEmpty>No framework found.</CommandEmpty> */}
            <CommandGroup>
              {options.map((framework) => (
                <div
                  className="flex items-center w-full gap-2"
                  key={framework.value}
                >
                  {framework.image && (
                    <div>
                      <img
                        className="h-4 w-4 rounded-full"
                        src={framework.image}
                        alt={framework.label}
                      />
                    </div>
                  )}

                  <CommandItem
                    className={`${
                      fontSize ? fontSize : "text-[0.6rem]"
                    } w-full max-w-sm`}
                    key={framework.value}
                    
                    value={framework.value}
                    keywords={[framework.label]}
                    onSelect={(currentValue) => {
                      setValue(currentValue === value ? "" : currentValue);
                      onChange(currentValue === value ? "" : currentValue);

                      setOpen(false);
                    }}
                  >
                    {framework.label ? framework.label : framework.name}
                    <Check
                      className={cn(
                        "ml-auto",
                        value === framework.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                </div>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
