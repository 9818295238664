"use client";
import * as React from "react";
import axios from "axios";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { DataTableColumnHeader } from "../ui/data-table-column-header";
import { cn } from "@/lib/utils";
import { useMemo } from "react";
import { Skeleton } from "../ui/skeleton";
import { useAuthStore } from "@/store/store";
import {
  ArrowUpDown,
  ChevronDown,
  Mail,
  Search,
  Users,
  Edit,
  Trash2,
  CloudUpload,
  Paperclip,
  Settings2,
} from "lucide-react";
import useFetch from "@/hooks/useFetchLSD";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Card } from "../ui/card";
import { MetricCard } from "../card-info";
import AddMember from "./add-member";
import { format } from "date-fns";
import { useQueryClient } from "react-query";
import { useToast } from "@/hooks/use-toast";

const columns = [
  {
    accessorKey: "serialNo",
    header: "Serial No",
    cell: ({ row }) => <div>{row.index + 1}</div>, // Auto-generate serial numbers
  },
  {
    accessorKey: "name",
     header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => <div className="capitalize">{row.original.name}</div>,
    enableSorting: true,
    enableHiding: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
),
    cell: ({ row }) => <div className="lowercase">{row.getValue("email")}</div>,
    enableSorting: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    cell: ({ row }) => <div>{row.getValue("mobile") || "N/A"}</div>,
    filterFn: "includesString",
  },
  {
    accessorKey: "membershipPlan",
    accessorFn: (row) => row.membershipPlan.name,
    header: "Plan",
    cell: ({ getValue }) => (
      <div>{getValue()}</div>
    ),
    filterFn: "includesString",
  },
  {
    accessorKey: "valid_from",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Valid From" />
),
    cell: ({ row }) => (
      <div>{row.getValue("valid_from") ? format(row.getValue("valid_from"), "dd/MM/yyyy") : "N/A"}</div>
    ),
    enableSorting: true,
  },
  {
    accessorKey: "valid_until",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Valid Until" />
),
    cell: ({ row }) => (
      <div>{row.getValue("vali_until") ? format(row.getValue("valid_until"), "dd/MM/yyyy") : "N/A"}</div>
    ),
    enableSorting: true,
  },
  {
    accessorKey: "active",
    header: "Active",
    cell: ({ row }) => <div>{row.getValue("active") ? "Yes" : "No"}</div>,
  },
  {
    accessorKey: "email_sent_time",
    header: "Email Sent",
    cell: ({ row }) => (
      <div>{row.getValue("email_sent_time") ? "Yes" : "No"}</div>
    ),
  },
  {
    id: "actions",
    header: "Action",
    cell: ({ row }) => {
      const queryClient = useQueryClient();
      const { companyslug } = useAuthStore();

      const sendEmail = async (id) => {
        const res = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/${id}/send-membership-email/`
        );
        queryClient.invalidateQueries("members");
      }

      const deleteMember = async () => {
        const res = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/delete-member/${
            row.original.id
          }/`
        );
        console.log(res.data);
        queryClient.invalidateQueries("members");
      };

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>Actions</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => deleteMember()}
              className="flex items-center gap-2"
            >
              <Trash2 size={14} /> Delete
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => sendEmail(row.original.id)}
              className="flex items-center gap-2"
            >
              <Mail size={14} /> Email
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
    
  },
];

const exampleData = [
  {
    name: "Chandler",
    email: "john@example.com",
    mobile: "798799979",
    plan: "Voyager",
    valid_from: "10-12-2020",
    valid_until: "10-12-2021",
  },
  {
    name: "Monica",
    email: "mary@example.com",
    mobile: "5465465",
    plan: "Loyals",
    valid_from: "09-8-2020",
    valid_until: "5-12-2021",
  },
  {
    name: "Joey",
    email: "july@example.com",
    mobile: "4646546",
    plan: "Premium",
    valid_from: "10-12-2020",
    valid_until: "10-12-2021",
  },
];

export function TableDemo() {
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [isAddMemberOpen, setIsAddMemberOpen] = React.useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);
  const [chosenFile, setChosenFile] = React.useState(null);
  const fileRef = React.useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const { toast } = useToast();
  const { companyslug } = useAuthStore();
  const {
    data: members,
    error,
    isFetching,
    refetch,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/company-member-api/?companyslug=${companyslug}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    },
    "members"
  );

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setChosenFile(e.target.files[0]);
  };

  

  const uploadMembersCSV = async () => {
    var formData = new FormData();
    formData.append("members_list_csv", chosenFile);
    try {
      const res = await axios.post(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/upload-members-csv/${companyslug}/`,
        formData
      );
      console.log("response", res);
    } catch (err) {
      console.log(err)
      toast({
        className: cn(
          'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
        ),
        title: err.response.data.message,
        duration: 2000
      });
    }
  };

  const exportMembers = async () => {
    window.open(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/export-company-members/${companyslug}/`
    );
    // console.log(res.data)
  };

  const tableData = React.useMemo(
    () => (isFetching ? Array(10).fill({}) : members || []),
    [isFetching, members]
  );

  const tableColumns = React.useMemo(
    () =>
      isFetching
        ? (columns || []).map((column) => ({
            ...column,
            cell: <Skeleton className={"w-20 h-4"} />,
          }))
        : columns || [],
    [isFetching, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter, 
    onRowSelectionChange: setRowSelection,
  });

  return (
    <div className="w-full">
      <div>
        <h1 className="section_heading">Members</h1>
      </div>
      <div className="max-w-xs py-4">
        <MetricCard title={"Active Members"} value={members?.length} />
      </div>
      <AddMember />
      <div className="flex gap-2 items-center my-2">
        <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <form>
            <div className="relative">
              <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search"
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="pl-8 h-8 focus-visible:outline-none  focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            </div>
          </form>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="h-8" variant="outline">
              Actions
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => setIsAddMemberOpen(true)}>
              Add New Member
            </DropdownMenuItem>

            <DropdownMenuItem onSelect={() => setIsUploadModalOpen(true)}>
              Upload Member CSV
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={() => exportMembers()}>
              Download Member List
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              <Settings2 size={16} />
              View
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="flex flex-col gap-2 p-2">
            <DropdownMenuSeparator />
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="rounded-md border mt-6">
        <Table>
          <TableHeader className="bg-neutral-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {isAddMemberOpen && (
        <AddMember isOpen={isAddMemberOpen} setIsOpen={setIsAddMemberOpen} />
      )}

      {isUploadModalOpen && (
        <Dialog open={isUploadModalOpen} onOpenChange={setIsUploadModalOpen}>
          <DialogContent className={"overflow-y-scroll max-h-[90vh]"}>
            <DialogHeader>
              <DialogTitle>Add Members</DialogTitle>
            </DialogHeader>
            <div className="flex flex-col gap-4 ">
              <Table className="w-full border border-gray-200">
                <TableHeader>
                  <TableRow className="bg-gray-100 ">
                    <TableHead className="px-4 py-2 text-[9px]">name</TableHead>
                    <TableHead className="px-4 py-2 text-[9px]">
                      email
                    </TableHead>
                    <TableHead className="px-4 py-2 text-[9px]">
                      mobile
                    </TableHead>
                    <TableHead className="px-4 py-2 text-[9px]">plan</TableHead>
                    <TableHead className="px-4 py-2 text-[9px]">
                      valid_from
                    </TableHead>
                    <TableHead className="px-4 py-2 text-[9px]">
                      valid_until
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {exampleData.map((item, index) => (
                    <TableRow key={index} className="border-t border-gray-200">
                      <TableCell className="px-4 py-2 text-[9px]">
                        {item.name}
                      </TableCell>
                      <TableCell className="px-4 py-2 text-[9px]">
                        {item.email}
                      </TableCell>
                      <TableCell className="px-4 py-2 text-[9px]">
                        {item.mobile}
                      </TableCell>
                      <TableCell className="px-4 py-2 text-[9px]">
                        {item.plan}
                      </TableCell>
                      <TableCell className="px-4 py-2 text-[9px]">
                        {item.valid_from}
                      </TableCell>
                      <TableCell className="px-4 py-2 text-[9px]">
                        {item.valid_until}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div>
                <h2 className="font-normal text-muted-foreground text-sm">
                  * Keep the exact column names (headers) in lower case
                </h2>
                <h2 className="font-normal text-muted-foreground text-sm">
                  * Caution: All names are CASE SENSITIVE
                </h2>
              </div>
              <input
                onChange={handleFileChange}
                type="file"
                accept=".csv"
                ref={fileRef}
                style={{ display: "none" }}
              />
              <div
                onClick={() => fileRef.current.click()}
                className="border-dashed rounded-md flex flex-col gap-1 justify-between items-center border-2 p-12"
              >
                <CloudUpload size={24} />
                <h2>Click to upload members CSV</h2>
                <span className="text-xs text-muted-foreground">
                  Supported Formats: .csv
                </span>
              </div>
              {chosenFile && (
                <div className="flex p-2 bg-gray-100 rounded-md gap-2 items-center">
                  <Paperclip size={20} />
                  {chosenFile.name}
                </div>
              )}
              <div className="flex justify-end">
                <Button disabled={!chosenFile} onClick={uploadMembersCSV}>
                  Update
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
