import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { ownerOptions, sourceOptions, stageOptions, statusOptions } from "@/lib/data";
import { DatePicker } from "../Custom/DatePicker";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuthStore } from "@/store/store";
import useLeadsStore from "@/store/leadsStore";
import axios, { all } from 'axios';
import { useCompanyStore } from "@/store/companyStore";


const EditEnquiry = ({ lead, isOpen, setIsOpen, refetch }) => {
      const [formData, setFormData] = React.useState(lead || {});
      const [batches, setBatches] = React.useState([]);
      const {teamMates} = useLeadsStore();
      const [loading, setLoading] = useState(false);
      const { companyslug } = useAuthStore();
      
      const allevents = useCompanyStore().companyData.events.data
      const [events, setEvents] = React.useState(allevents);

      React.useEffect(()=>{
        if(formData?.event?.slug){
          getBatches(formData?.event?.slug)
        }
      }, [])

      useEffect(() => {
        console.log(formData)
      },[formData])

    const submitForm = async () => {
      setLoading(true)
      try {
        const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/edit-company-enquiry/${companyslug}/`, {"enquiry_details": formData});
        // setEvents(response.data?.results);
        setLoading(false)
        setIsOpen(false)
        refetch()
      } catch (error) {
        console.error("Error Submitting form:", error.response ? error.response.data : error.message);
      }
    }

    const handleChange = (key, value) => {
      if(key=="eventbatches"){
        setFormData((prev) => ({
          ...prev,
          [key]: {"id":value},
        }));
      }
      else if(key=="event"){
        setFormData((prev) => ({
          ...prev,
          [key]: {"slug":value},
        }));
      }
      else{
        setFormData((prev) => ({
          ...prev,
          [key]: value,
        }));
      }
    };

    const getBatches = async (value) => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/event-batches/${value}/`);
        setBatches(response.data);
      } catch (error) {
        console.error("Error fetching batches:", error.response ? error.response.data : error.message);
      }
    }

    const changeEvent = async (value) => {
      handleChange("event", value)
      getBatches(value)
    }

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
        className={
            "lg:max-w-2xl overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }>
          <DialogHeader>
            <DialogTitle>Edit Enquiry</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-2 py-4">
            <div className="profile_section grid grid-cols-3 pb-2">
                <div className="flex flex-col gap-1">
                    <span className="text-xs text-muted-foreground">Fullname</span>
                    <span>{lead.customer_profile?.fullname}</span>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="text-xs text-muted-foreground">Email</span>
                    <span>{lead.customer_profile?.email}</span>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="text-xs text-muted-foreground">Contact</span>
                    <span>{lead.customer_profile?.mobile}</span>
                </div>
            </div>
            <div>
              <div className="event_section flex flex-col gap-2">
                <div className="flex justify-between">
                  <label>Event</label>
                  <Select
                  className="max-w-48"
                    onValueChange={(value) => changeEvent(value)}
                    value={formData.event?.slug || formData.event}
                  >
                    <SelectTrigger  className="max-w-48">
                      <SelectValue placeholder="Select Event" />
                    </SelectTrigger>
                    <SelectContent>
                      {events?.map((event) => (
                        <SelectItem
                          key={event.slug}
                          value={event.slug || "--"}
                          className="max-w-48"
                        >
                          {event.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <ComboboxDemo
                    onChange={(value) => handleChange("event", value)}
                    classes="max-w-sm"
                    options={eventOptions}
                    value={formData.event}
                  /> */}
                </div>
                <div className="flex justify-between">
                  <label>Batch</label>
                  <Select
                    onValueChange={(value) => handleChange("eventbatches", value)}
                    value={formData.eventbatches?.id}
                  >
                    <SelectTrigger className="max-w-48">
                      <SelectValue placeholder="Select Batch" />
                    </SelectTrigger>
                    <SelectContent>
                      {batches?.map((batch) => (
                        <SelectItem
                          // key={batch.id}
                          value={batch.id}
                          className="max-w-48"
                        >
                          {batch.startdate}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <ComboboxDemo
                    onChange={(value) => handleChange("eventbatches", value)}
                    classes="max-w-sm"
                    options={batchOptions}
                    value={formData.eventbatches}
                  /> */}
                </div>
              </div>


              <div className="duration_section flex-col flex gap-2">
              <div className="flex-grow flex pt-2 justify-between">
                <label>Preferred Date</label>
                <div className="w-48">
                  <DatePicker
                    onChange={(value) => handleChange("preferred_start_date", value)}
                    value={formData.preferred_start_date}
                  />
                </div>
              </div>
                <div className="flex-grow flex justify-between">
                  <label htmlFor="duration">Duration</label>
                  <Input
                    id="duration"
                    className="max-w-48"
                    type="text"
                    placeholder="Duration"
                    value={formData.duration}
                    onChange={(e) => handleChange("duration", e.target.value)}
                  />
                </div>
                <div className="flex-grow flex justify-between">
                  <label htmlFor="no_of_guests">No of Guests</label>
                  <Input
                    id="no_of_guests"
                    type="text"
                    className="max-w-48"
                    placeholder="No of Guests"
                    value={formData.no_of_guests}
                    onChange={(e) => handleChange("no_of_guests", e.target.value)}
                  />
                </div>
                <div className="flex-grow flex justify-between">
                  <label htmlFor="location">Location</label>
                  <Input
                    id="location"
                    type="text"
                    className="max-w-48"
                    placeholder="Location"
                    value={formData.location}
                    onChange={(e) => handleChange("location", e.target.value)}
                  />
                </div>
              </div>

              <div className="quick_actions flex flex-col gap-2">
                <div className="flex-grow pt-2 flex justify-between">
                  <label>Status</label>
                  <Select
                    onValueChange={(value) => handleChange("status", value)}
                    value={formData.status}
                  >
                    <SelectTrigger className="max-w-48">
                      <SelectValue placeholder="Select Status" />
                    </SelectTrigger>
                    <SelectContent>
                      {statusOptions?.map((status) => (
                        <SelectItem
                          key={status.value}
                          value={status.value.toString()}
                          className="max-w-48"
                        >
                          {status.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <ComboboxDemo
                    onChange={(value) => handleChange("status", value)}
                    classes="max-w-48"
                    options={statusOptions}
                    value={formData.status}
                  /> */}
                </div>
                <div className="flex-grow flex justify-between">
                  <label>Stage</label>
                  <Select
                    onValueChange={(value) => handleChange("funnel_stage", value)}
                    value={formData.funnel_stage}
                  >
                    <SelectTrigger className="max-w-48">
                      <SelectValue placeholder="Select Stage" />
                    </SelectTrigger>
                    <SelectContent>
                      {stageOptions?.map((stage) => (
                        <SelectItem
                          key={stage.value}
                          value={stage.value.toString()}
                          className="max-w-48"
                        >
                          {stage.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <ComboboxDemo
                    onChange={(value) => handleChange("funnel_stage", value)}
                    classes="max-w-48"
                    options={stageOptions}
                    value={formData.funnel_stage}
                  /> */}
                </div>
                <div className="flex-grow flex justify-between">
                  <label>Source</label>
                  <Select
                    onValueChange={(value) => handleChange("source", value)}
                    value={formData.source}
                  >
                    <SelectTrigger className="max-w-48">
                      <SelectValue placeholder="Select Source" />
                    </SelectTrigger>
                    <SelectContent>
                      {sourceOptions?.map((source) => (
                        <SelectItem
                          key={source.value}
                          value={source.value.toString()}
                          className="max-w-48"
                        >
                          {source.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <ComboboxDemo
                    onChange={(value) => handleChange("source", value)}
                    classes="max-w-48"
                    options={sourceOptions}
                    value={formData.source}
                  /> */}
                </div>
                <div className="flex-grow flex justify-between">
                  <label>Assigned to</label>
                  <Select
                    onValueChange={(value) => handleChange("asigned_to", value)}
                    value={formData.asigned_to?.peopleid || formData.asigned_to?.id}
                  >
                    <SelectTrigger className="max-w-48">
                      <SelectValue placeholder="Select Assignee" />
                    </SelectTrigger>
                    <SelectContent>
                      {teamMates?.map((team) => (
                        <SelectItem
                          key={team.value}
                          value={team.value}
                        >
                          {team.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <ComboboxDemo
                    onChange={(value) => handleChange("assigned_to", value)}
                    classes="max-w-48"
                    options={ownerOptions}
                    value={formData.assigned_to}
                  /> */}
                </div>
              </div>

              <div className="customer_message pt-2">
                <div className="flex-grow">
                  <label>Customer Message</label>
                  <Textarea
                    value={formData.message}
                    onChange={(e) => handleChange("message", e.target.value)}
                  />
                </div>
              </div>
            </div>
           <div className="flex self-end">
            <Button onClick={()=>{submitForm()}}>{!loading ? 'Update' : <div className="dots"></div>}</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditEnquiry;
