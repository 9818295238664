import React, { useEffect } from "react";
import { Drawer } from "vaul";
import { Separator } from "../ui/separator";
import useFetch from "@/hooks/useFetchLSD";
import { Badge } from "../ui/badge";
import { Calendar } from "lucide-react";
import { format } from "date-fns";
import { Skeleton } from "../ui/skeleton";

export function CustomerDetails({ isOpen, setIsOpen, id, isMobile }) {
  const [timeline, setTimeline] = React.useState([]);
  const {
    data: timelinedata,
    error: timelineError,
    isLoading: timelineLoading,
    refetch: timelineRefetch,
    isFetching: timelineFetching,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/customer-booking-timeline/${id}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    setTimeline(timelinedata?.data);
  }, [timelinedata]);
  return (
    <Drawer.Root open={isOpen} onOpenChange={setIsOpen} direction={`${isMobile ? 'bottom' : 'right'}`}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed z-10 inset-0 bg-black/40" />
        <Drawer.Content className={`${!isMobile ? 'right-0 top-0' : ''} bg-white bottom-0 fixed z-20 outline-none ${!isMobile ? 'w-[30vw]' : 'w-full'} px-4 py-6 flex flex-col gap-2`}>
          <div>
            <h2>Timeline</h2>
            <Separator className="mt-1 mb-2" />
          </div>
          <div className={`mt-0 ${!isMobile ? 'max-h-[100vh]' : 'max-h-[70vh]'} overflow-y-auto`}>
            {
              timelineFetching ? (
                [...Array(5)].map((_, index) =>(
                  <ol className="relative border-s ml-4 mt-4 border-gray-200 dark:border-gray-700" key={index}>
                  <li className="mb-6 ms-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 border bg-white rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                          <svg
                            className="w-2.5 h-2.5 dark:text-blue-300"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                          </svg>
                        </span>
                        <div className="flex items-center gap-2">
                          <h3 className="flex items-center text-sm font-medium text-gray-900 dark:text-white">
                          <Skeleton className="w-28 h-4" />
                          </h3>
                          <div className="text-sm">
                            <Skeleton className="w-16 h-4" />
                            
                          </div>
                        </div>
                        <time className="block mb-2 mt-2 text-sm font-normal leading-none ">
                          <div className="flex items-start gap-2">
                            <div>
                              <Skeleton className="w-8 h-8 rounded-md" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <span><Skeleton className={'w-24 h-4'}/></span>
                              <span className="flex gap-1 text-xs items-center">
                                <Calendar size={14} />
                                <Skeleton className="w-24 h-4" />
                              </span>
                            </div>
                          </div>
                        </time>
                      </li>
                      </ol>
                ))
               
              )
              :
              (
                <div>
                <ol className="relative border-s ml-4 mt-4 border-gray-200 dark:border-gray-700">
                  {timeline?.map((item, index) => {
                    return (
                      <li className="mb-6 ms-6" key={index}>
                        <span className="absolute flex items-center justify-center w-6 h-6 border-2 bg-white rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                          <svg
                            className="w-2.5 h-2.5 dark:text-blue-300"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                          </svg>
                        </span>
                        <div className="flex items-center gap-2">
                          <h3 className="flex items-center text-sm font-medium text-gray-900 dark:text-white">
                           {format(new Date(item.date), "dd/MM/yyyy")}
                          </h3>
                          <div className="text-sm">
                            <Badge
                              variant="outline"
                              className="text-[0.6rem] rounded-md py-0 "
                            >
                              ID: {item.id}
                            </Badge>
                          </div>
                        </div>
                        <time className="block mb-2 mt-2 text-sm font-normal leading-none ">
                          <div className="flex items-start gap-2">
                            <div className="w-8 h-8 rounded-md overflow-hidden relative">
                              <img
                                src={`${import.meta.env.VITE_APP_API_URL}${item.cover_pic}`}
                                alt="event-pic"
                                className="absolute top-0 left-0 w-full h-full object-cover"
                              />
                            </div>
                            <div className="flex flex-col gap-1">
                              <span className="max-w-56">{item.batch}</span>
                              <span className="flex gap-1 text-xs items-center">
                                <Calendar size={14} />
                                {item.batch_date}
                              </span>
                            </div>
                          </div>
                        </time>
                      </li>
                    );
                  })}
                </ol>
              </div>
              )
            }
           
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
