import React, { useCallback, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { ImageUp, X } from "lucide-react";
import { useState } from "react";

import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { Skeleton } from "@/components/ui/skeleton";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";

const UploadImageModal = ({ isOpen, setIsOpen, eventID }) => {
  const { companyslug } = useAuthStore();
  const [images, setImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removedImages,setRemovedImages] = useState([]);
  const {toast} = useToast();
  const fileRef = useRef(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const handleFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const uploaded = [...uploadedImages];
    chosenFiles.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedImages(uploaded);
  };

  const {
    data: eventImages,
    isLoading: eventImagesLoading,
    error: eventImageserror,
    isError: isEventImagesError,
    isFetching: isEventImagesFetching,
    refetch: refetchEventImages,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/events/${companyslug}/${eventID}/get-event-pics/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: null,
        enabled: true,
      },
    }
  );

  useEffect(() => {
    setImages(eventImages?.eventsphoto);
    setTimeout(setLoaded(true), 500);
  }, [eventImages]);

  const saveEventImages = function () {
    const formData = new FormData();

    uploadedImages?.forEach((file, index) => {
      formData.append(`file_${index}`, file); // Appending each file separately
    });
    formData.append('removed',JSON.stringify(removedImages));
    const postRequest =  () => {
      setLoading(true);
      toast({
        variant: "loading",
        title: "Updating images",
        duration: 2000
      })
      try {
        axios.post(
          `${
            import.meta.env.VITE_APP_API_URL
          }/v3/events/${companyslug}/${eventID}/upload-event-pics/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response)=>{
          setUploadedImages([]);
          setLoading(false);
          // refetchEventImages();
          toast({
            variant: "success",
            title: "Updated successfully",
            duration: 1500
          })
          setIsOpen(false);
          return { data: response.data, isError: false, error: null }; 
        }
        );
      } catch (err) {
        return { data: null, isError: true, error: err };
      }
    };
    postRequest();
  };

  const removeImagesfromUploadedImage = (image) => {
    setUploadedImages((prev) => prev.filter((img) => img !== image));
  };

  const removeImages = (id) =>{
    setRemovedImages([...removedImages,{'photo_id':id}])
    setImages((prev)=>(prev.filter((img)=> img.id !== id)));
  }

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={"overflow-y-scroll max-h-[90vh]"}>
          <DialogHeader>
            <DialogTitle>Upload Images</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4 ">
            <input
              onChange={handleFileChange}
              multiple
              type="file"
              ref={fileRef}
              style={{ display: "none" }}
            />
            <div
              onClick={() => fileRef.current.click()}
              className="border-dashed rounded-md flex flex-col gap-1 justify-between items-center border-2 p-12"
            >
              <ImageUp size={24} />
              <h2>Click to upload images</h2>
              <span className="text-xs text-muted-foreground">
                Supported Formats: .png, .jpg
              </span>
            </div>
            {uploadedImages.length > 0 && (
              <div>
                <div className="columns-1 sm:columns-2 lg:columns-3 xl:columns-3 gap-2">
                  {uploadedImages.map((image, index) => (
                    <div
                      key={index}
                      className="mb-2 relative break-inside-avoid"
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt="thumbnail"
                        className="rounded-md"
                      />
                      <span className="absolute top-2 right-2 w-4 h-4 flex items-center justify-center bg-black/40 rounded-full">
                        <X className="text-white" size={10} onClick={()=>{removeImagesfromUploadedImage(image)}}/>
                      </span>
                    </div>
                  ))}
                </div>
                <Separator className="mt-2" />
              </div>
            )}
            <div>
              <h2 className="text-lg font-semibold">Manage Gallery</h2>
              <Separator className="mt-1 mb-2" />
              {isEventImagesFetching && (
                <div className="grid grid-cols-3 gap-x-4">
                  <Skeleton className="h-32 w-42 rounded-xl" />
                  <Skeleton className="h-32 w-42 rounded-xl" />
                  <Skeleton className="h-32 w-42 rounded-xl" />
                  {/* <Skeleton className="h-32 w-24 rounded-xl" /> */}
                </div>
              )}
              <div className="columns-1 sm:columns-2 lg:columns-3 xl:columns-3 gap-2">
                {loaded && images?.length > 0
                  ? images?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="mb-2 relative break-inside-avoid"
                          id={`image-${item.id}`}
                        >
                          <img
                            // src="https://images.unsplash.com/photo-1719937051058-63705ed35502?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            src={`${import.meta.env.VITE_APP_API_URL}${
                              item.photo
                            }`}
                            alt="thumbnail"
                            className="rounded-md"
                          />
                          <span className="absolute top-2 right-2 w-4 h-4 flex items-center justify-center bg-black/40 rounded-full">
                            <X className="text-white" size={10} onClick={()=>{removeImages(item.id)}}/>
                          </span>
                        </div>
                      );
                    })
                  : "No images"}
              </div>
            </div>
          </div>
          <Button disabled={loading} onClick={saveEventImages}>Save</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadImageModal;
