"use client";

import React from "react";

import { DataTableColumnHeader } from "@/components/ui/data-table-column-header";


export const analyticsColumns = [
  {
    accessorKey: "ID",
    size: 60,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="SNo." />
    ),
    cell: ({ row }) => {
      const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
      return (
        <div className="text-xs cursor-pointer flex flex-col gap-1">
         {row.index+1}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "day",
    size: 150,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Day" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex cursor-pointer gap-2 text-xs">
         {row.original.date}
        </div>
      );
    },
  },
  {
    accessorKey: "bookings",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Bookings" />
    ),
    size: 120,
    enableSorting: false,
    cell: ({ row }) => {
      
      return (
        <div className="text-xs">
          {row.original.Bookings}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "revenue",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Revenue" />
    ),
    cell: ({ row }) => {
      
      return (
        <div className=" text-xs">
        &#8377;{row.original.Revenue}
        </div>
      );
    },
   
  },
 
];
