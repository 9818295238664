import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Textarea } from "../ui/textarea";
import { ArrowLeft, ChevronLeft, Trash, X } from "lucide-react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Store } from "./Store";
import CategorySelect from "./CategorySelect";
import GreetingForm from "./GreetingForm";
import { Separator } from "../ui/separator";
import NodeDetails from "./NodeDetails";
import EventSelect from "./EventSelect";

export const replyTypes = {
  TEXT: {
    type: "text",
    value: "",
    description: "",
  },

  QUICK_REPLY: {
    type: "quick_reply",
    value: [
      {
        text: "",
        payload: "",
      },
    ],
    description: "",
  },

  CUSTOM_QUICK_REPLY: {
    type: "custom_quick_reply",
    value: [
      {
        text: "",
        response: "",
        payload: "",
      },
    ],
  },
};

export const Sidebar = ({
  stageSelected,
  setStageSelected,
  setIsStageSelected,
  isStageSelected,
  freeStage,
  activeStageArea,
  updateStageForm,
  handleSave,
  addCustomStage,
  getUpdatedStage,
  setFreeStage
}) => {
  const modalref = useRef(null);
  const FormRenderer = function () {
    const description = stageSelected?.meta_data;

    if (!description) return;

    if (description["identifier"] === "GREETINGS") {
      return (
        // <GreetingForm data={stageSelected} updateStageForm={updateStageForm} />
        <NodeDetails selectedStage={stageSelected} getUpdatedStage={getUpdatedStage} updateStageForm={updateStageForm}/>
      );
    } else if (description["identifier"] === "CATEGORY") {
      return (
        <CategorySelect
          data={stageSelected}
          updateStageForm={updateStageForm}
        />
      );
    } else if (description["identifier"] === "EVENTS") {
      return (
        <EventSelect data={stageSelected} updateStageForm={updateStageForm} />
      );
    } else
      return (
        <NodeDetails
          selectedStage={stageSelected}
          getUpdatedStage={getUpdatedStage}
          updateStageForm={updateStageForm}
        />
      );
  };

  const [newFreeStage, setNewFreeStage] = useState(
    replyTypes.CUSTOM_QUICK_REPLY
  );
  const [newQuickReplies, setNewQuickReplies] = useState(
    replyTypes.CUSTOM_QUICK_REPLY.value
  );

  const handleCustomNodeChange = (e, field) => {
    if (field == "name") {
      newFreeStage["name"] = e.target.value;
      return;
    } else if (field === "header") {
      newFreeStage["header"] = e.target.value;
    }
  };

  const handleAddQuickReply = () => {
    const newReply = replyTypes.CUSTOM_QUICK_REPLY.value[0];
    setNewQuickReplies([...newQuickReplies, newReply]);
  };

  const handleRemoveQuickReply = (index) => {
    if (index > -1) {
      const updated = [...newQuickReplies];
      // only splice array when item is found
      updated.splice(index, 1); // 2nd parameter means remove one item only
      setNewQuickReplies(updated);
    }
  };

  const handleReplyChange = (index, field, value) => {
    if(field === "text"){
      const payload = `CUSTOM_QUICK_REPLY,${value}`;
      setNewQuickReplies((prevItems) =>
        prevItems.map((item, ind) =>
          index === ind ? { ...item, [field]: value, payload:payload } : item
        )
      );
    }else{
      setNewQuickReplies((prevItems) =>
        prevItems.map((item, ind) =>
          index === ind ? { ...item, [field]: value } : item
        )
      );

    }
  };

  const handleAddCustomNode = () => {
    console.log("NE quick reply", newQuickReplies);
    const stageData = {
      name: newFreeStage?.name,
      forms: [
        {
          type: "custom_quick_reply",
          value: [
            {
              text: "",
              response: "",
              payload: "",
            },
          ],
        },
        {
          type: "text",
          value: "",
          description: "",
        },
      ],
    };
    console.log(replyTypes.CUSTOM_QUICK_REPLY, replyTypes);
    stageData.forms[0].value = newQuickReplies;
    stageData.forms[1].value = newFreeStage?.header;
    console.log("STAGE DATA", stageData);
    addCustomStage(stageData);
    modalref.current.click();

    console.log("New Stage", newFreeStage);
  };
  return (
    <div className="flex pl-4 ml-auto bg-white h-full w-80 rightbar border-l-2">
      {/* <Topbar saveFlow={saveFlow} /> */}

      {!isStageSelected? (
        <>
          <Button onClick={()=>{handleSave(false)}}>Save Changes</Button>
          {freeStage && (
            <Store stages={freeStage} activeStageArea={activeStageArea} setFreeStage={setFreeStage}/>
          )}
          <Dialog>
            <DialogTrigger>
              <Button ref={modalref}>Add Node</Button>
            </DialogTrigger>
            <DialogContent className="lg:max-w-sm lg:max-h-[80vh] overflow-auto">
              <DialogHeader>
                <DialogTitle>Add custom node</DialogTitle>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="name">Name</Label>
                  <Input
                    onChange={(e) => handleCustomNodeChange(e, "name")}
                    type="text"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="header">Header</Label>
                  <Textarea
                    onChange={(e) => handleCustomNodeChange(e, "header")}
                    type="text"
                    id="header"
                    placeholder="Header"
                  />
                </div>
                <Separator className="my-1" />
                <div className="flex flex-col gap-4 ">
                  {newQuickReplies.map((reply, index) => (
                    <div
                      className="flex relative flex-col gap-2 border-[1px] rounded-md p-4"
                      key={index}
                    >
                      <div
                        onClick={() => handleRemoveQuickReply(index)}
                        className="absolute top-2 right-2"
                      >
                        <X size={14} />
                      </div>
                      <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="reply">Header</Label>
                        <Input
                          onChange={(e) =>
                            handleReplyChange(index, "text", e.target.value)
                          }
                          type="text"
                          placeholder="Question"
                          value={reply.text}
                        />
                      </div>
                      <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="reply">Response</Label>
                        <Textarea
                          onChange={(e) =>
                            handleReplyChange(index, "response", e.target.value)
                          }
                          // onChange={(e) => handleCustomNodeChange(e, "response")}
                          type="text"
                          id="reply"
                          placeholder="Reply"
                          value={reply.response}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-0">
                  <Button className="w-full" onClick={handleAddQuickReply}>
                    Add New Reply
                  </Button>
                </div>
              </div>
              <div className="flex justify-end">
                <Button onClick={handleAddCustomNode}>Update</Button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <>
          <div className="flex items-center gap-2">
            <ArrowLeft
              size={14}
              onClick={() => {
                setIsStageSelected(false);
                setStageSelected(null);
              }}
            />
          </div>
          <div className="mt-4">{FormRenderer()}</div>
        </>
      )}
      {/* {flowData?.flow?.length>0 && <GreetingForm data={flowData?.flow[0]} flowStage={flowStage} setFlowStage={setFlowStage}/>} */}
      {/* <EventSelect /> */}
    </div>
  );
};
