import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import BookingCard from "@/components/bookings/components/bookingCard";
import { CalendarDatePickerWithPresets } from "@/components/Custom/DateRangeWithPresets";
import { columns } from "@/components/bookings/components/columns";
import { DataTable } from "@/components/ui/data-table";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { Card } from "@/components/ui/card";
import { debounce } from "lodash";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTableToolbar } from "@/components/ui/data-table-toolbar";
import { ComboboxDemo } from "@/components/Custom/ComboboxInput";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Search, ListFilter, LucideDownload } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ownerOptions } from "@/lib/data";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { DateRangePickerPresets } from "@/components/Custom/DateRangePickerTest";
import { format } from "date-fns";
import { useIsMobile } from "@/hooks/use-mobile";
import DownloadBookingModal from "@/components/bookings/download-booking-modal";
import { useCompanyStore } from "@/store/companyStore";

export default function BookingsPage() {
  const { companyslug } = useAuthStore();
  const isMobile = useIsMobile();
  const [hasMore, setHasMore] = useState(true);
  const ROOT_URL = `${
    import.meta.env.VITE_APP_API_URL
  }/v3/bookings/${companyslug}`;
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [bookings, setBookings] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const loadedMoreRef = useRef(false);
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [apiURL, setApiURL] = useState(ROOT_URL);

  const [bookingType, setBookingType] = useState("All");
  const [assignedTo, setAssignedTo] = useState("");
  const [bookingDateFilters, setBookingDateFilters] = useState({});
  const [eventDateFilters, setEventDateFilters] = useState({});
  const [isDownloadBookingOpen, setisDownloadBookingOpen] = useState(false);

  const [filters, setFilters] = useState({
    q: "",
    page: "",
    size: "",
  });
  const [quickFilters, setQuickFilters] = useState({});

  const teamMates =
    useCompanyStore().companyData.teamMembers.data.sales_team_mates;

  const handleBookingDateSelect = (value) => {
    setBookingDateFilters({
      bookingfilter_timestamp_from_date: format(value.range.from, "dd-MM-yyyy"),
      bookingfilter_timestamp_to_date: format(value.range.to, "dd-MM-yyyy"),
    });
  };

  const handleEventDateSelect = (value) => {
    setEventDateFilters({
      eventfilter_timestamp_to_date: format(value.range.to, "dd-MM-yyyy"),
      eventfilter_timestamp_from_date: format(value.range.from, "dd-MM-yyyy"),
    });
    //setFilters({...filters,eventfilter_timestamp_to_date:format(value.range.from,'dd-MM-yyyy'),  eventfilter_timestamp_from_date: format(value.range.to,'dd-MM-yyyy')})
  };

  const updateFilters = () => {
    setIsFiltersOpen(false);
    setFilters((prev) => ({
      ...prev,
      page: 1,
      size: 10,
      salerep: assignedTo,
    }));
    refetchBookingsData();
  };

  const clearFilters = () => {
    setAssignedTo("");
    setEventDateFilters({});
    setBookingDateFilters({});
    setFilters((prev) => ({
      ...prev,
      salerep: "",
    }));
    refetchBookingsData();
    setIsFiltersOpen(false);
  };

  const debouncedSearch = useCallback(
    debounce((newSearch) => {
      setFilters((prev) => {
        return { ...prev, q: newSearch };
      });
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const {
    data: bookingsData,
    error: bookingsError,
    isLoading: bookingLoading,
    refetch: refetchBookingsData,
    isFetching: bookingsFetching,
  } = useFetch(
    apiURL,
    {
      ...filters,
      ...bookingDateFilters,
      ...eventDateFilters,
    },
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 2,
        enabled: false,
        keepPreviousData: true,
      },
    }
  );


  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: 1,
      size: 10,
      type: bookingType,
    }));
    setQuickFilters({ ...filters });
  }, [bookingType]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
      type: bookingType,
    }));
    setQuickFilters({ ...filters });
  }, [pagination]);

  useEffect(() => {
    refetchBookingsData();
  }, [apiURL, quickFilters]);

  useEffect(() => {
    if (bookingsData?.results) {
      if (loadedMoreRef.current) {
        setBookings((prevBookings) => [
          ...prevBookings,
          ...bookingsData.results,
        ]);
        loadedMoreRef.current = false;
      } else {
        setBookings(bookingsData.results);
      }
    }
    if (bookingsData) {
      setHasMore(bookingsData.current_page < bookingsData.total_pages);
    }
  }, [bookingsData]);

  useEffect(() => {
    setApiURL(`${ROOT_URL}/?type=${bookingType}`);
  }, [bookingType]);

  const [columnVisibility, setColumnVisibility] = React.useState({
    id: true,
    eventDetails: true,
    bookingDetails: true,
    transactionAmount: true,
    refunded: true,
    pending: true,
    fees: false,
    settlement: false,
  });

  useEffect(() => {
    refetchBookingsData();
  }, [filters]);

  const handleLoadMore = () => {
    setFilters({ ...filters, page: bookingsData?.current_page + 1 });
    loadedMoreRef.current = true;
  };

  const tableData = React.useMemo(
    () => (bookingsFetching ? Array(10).fill({}) : bookings || []),
    [bookingsFetching, bookings]
  );

  const tableColumns = React.useMemo(
    () =>
      bookingsFetching
        ? (columns || []).map((column) => ({
            ...column,
            cell: <Skeleton className={"w-20 h-4"} />,
          }))
        : columns || [],
    [bookingsFetching, columns]
  );

  const passAssignedto = (assign) => {
    console.log(assign);
    setFilters((prev) => ({
      ...prev,
      salerep: assign,
    }));
  };

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting: sorting || [],
      columnVisibility: columnVisibility || {},
      rowSelection: rowSelection || {},
      columnFilters: columnFilters || [],
      pagination: pagination || { pageIndex: 0, pageSize: 10 },
    },
    pageCount: bookingsData?.total_pages || 0,
    manualPagination: true,
    enableRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
    customParams: {
      hideSelectionInfo: true,
    },
  });

  return (
    <>
      <div className="flex mb-4 items-center gap-2">
        {/* <div className="section_heading_image">
          <img
            src="/booking_photo.jpg"
            alt="leads"
            className="w-full object-cover h-full absolute top-0 left-0"
          />
        </div> */}
        <h2 className="mb-0 section_heading">Bookings</h2>
      </div>
      <div className="flex flex-col mt-1 pb-8">
        <div className="flex gap-1 items-center">
          <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <form>
              <div className="relative">
                <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Search"
                  onChange={handleSearchChange}
                  className="pl-8 h-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                />
              </div>
            </form>
          </div>
          {isMobile ? (
            <Button
              variant="icon"
              className={"rounded-md"}
              onClick={() => {
                setisDownloadBookingOpen(true);
              }}
            >
              <LucideDownload size={18} />
            </Button>
          ) : (
            <Button
              variant="outline"
              className={"rounded-md"}
              onClick={() => {
                setisDownloadBookingOpen(true);
              }}
            >
              <LucideDownload size={14} />
              Download Bookings
            </Button>
          )}

          <DownloadBookingModal
            isDownloadBookingOpen={isDownloadBookingOpen}
            setisDownloadBookingOpen={setisDownloadBookingOpen}
          />
          <Button
            onClick={refetchBookingsData}
            className="h-8 gap-2"
            variant="outline"
          >
            {bookingsFetching && !bookingLoading ? (
              <div>
                <div className="spinner"></div>
              </div>
            ) : (
              ""
            )}
            Refresh
          </Button>
          <Popover
            open={isFiltersOpen}
            onOpenChange={setIsFiltersOpen}
            align="end"
          >
            <PopoverTrigger>
              <Button
                onClick={() => {
                  setIsFiltersOpen(true);
                }}
                className="h-8"
                variant="outline"
              >
                <ListFilter size={14} />
                <span className="hidden md:flex">Filters</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto">
              <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <Label>Booking date</Label>

                  <DateRangePickerPresets onUpdate={handleBookingDateSelect} />
                </div>
                <div className="flex flex-col gap-1">
                  <Label>Event date</Label>
                  <DateRangePickerPresets onUpdate={handleEventDateSelect} />
                </div>
                <div className="flex flex-col gap-1">
                  <Label>Assigned to</Label>
                  <Select
                    value={assignedTo}
                    onValueChange={(value) => setAssignedTo(value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Assignee" />
                    </SelectTrigger>
                    <SelectContent>
                      {teamMates.map((teamMate, index) => (
                        <SelectItem value={teamMate.peopleid} key={index}>
                          {teamMate.username}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex gap-4 mt-4 md:mt-2 justify-between md:justify-end">
                <Button variant="outline" className="" onClick={clearFilters}>
                  Clear
                </Button>
                {/* <Button variant="outline" className="">
                  Cancel
                </Button> */}
                <Button className="" onClick={updateFilters}>
                  Apply
                </Button>
              </div>
            </PopoverContent>
          </Popover>
          <DataTableToolbar table={table} />
        </div>

        <div className="flex py-4 gap-2 items-center">
          {["All", "Online", "Manual", "Refunded"].map((option, index) => (
            <Button
              variant={`${bookingType === option ? "default" : "outline"}`}
              className={"rounded-md"}
              key={index}
              onClick={() => {
                setBookingType(option);
              }}
            >
              {option}
            </Button>
          ))}
        </div>

        <div className="md:h-full hidden md:flex flex-col md:space-y-8">
          {bookings && (
            <DataTable data={bookings} columns={columns} table={table} />
          )}
        </div>
        <div className="flex md:hidden flex-col">
          {bookingsFetching &&
            !loadedMoreRef.current &&
            Array(10)
              .fill({})
              .map((task, index) => {
                return (
                  <div key={index}>
                    <div className="flex flex-col gap-1">
                      <Card className="py-2 relative rounded-none border-t-0 border-l-0 shadow-none border-r-0 border-b-[1px]">
                        <div className="flex justify-between items-start">
                          <div className="flex items-start gap-2">
                            <div className="flex items-start gap-2">
                              <div className="flex flex-col gap-1">
                                <Skeleton className="w-12 h-12 rounded-md" />
                                <Skeleton className={"w-12 h-4"} />
                              </div>
                            </div>
                            <div className="flex flex-col gap-1">
                              <Skeleton className="w-32 h-4" />
                              <Skeleton className="w-24 h-4" />
                              <Skeleton className="w-16 h-4" />
                            </div>
                          </div>
                          <div className="flex h-16 flex-col justify-between">
                            <Skeleton className="w-16 h-4" />
                            <Skeleton className="w-16 h-4" />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                );
              })}
          {!bookingsFetching && bookings?.length == 0 && (
            <h1 className="text-center mt-4">
              No bookings available for the selected filters
            </h1>
          )}
          {bookings?.map((task, index) => {
            return <BookingCard key={index} booking={task} />;
          })}
          {hasMore ? (
            <Button className="my-4 w-28 self-center" onClick={handleLoadMore}>
              {bookingsFetching ? <div className="dots"></div> : "Load More"}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
