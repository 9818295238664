"use client";

import * as React from "react";
import { Button } from "@/components/ui/button";
// import { tasks } from "@/components/bookings/data";
import AllLeadsFilters from "../Custom/AllLeadsFilters";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import { Search } from "lucide-react";
import { Input } from "../ui/input";
import { Badge } from "../ui/badge";
import { Label } from "../ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ListFilter } from "lucide-react";
import { CalendarDatePickerWithPresets } from "../Custom/DateRangeWithPresets";
import { ownerOptions } from "@/lib/data";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Drawer } from "vaul";
import BookingDetails from "../bookings/bookingsDetails";
import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import LeadDetails from "../leads/lead-details";
import { Separator } from "./separator";
import { createPortal } from "react-dom";
import useLeadsStore from "@/store/leadsStore";
import { redirect } from "react-router-dom";
// import BookingDetails from "../bookingsDetails";

export function DataTable({table, heading, columns, data, filterOptions,hidePagination }) {
 
  const { updateEnquiryField} = useLeadsStore();
  const [id, setId] = React.useState();
  const [selected, setSelected] = React.useState();
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);

  React.useEffect(() => {
    const sel = data.find((b) => b.id === id);
    setSelected(sel);
  }, [id]);

 

  const bgMapping = {
    warm: "5px solid rgba(246,194,62,1)",
    hot: "5px solid rgba(227,74,39,1)",
    cold: "5px solid rgba(54,185,204,1)",
  };

  return (
    <div className="space-y-4">
      {/* {heading == "leads" ? (
        <div className="flex items-center gap-2">
          <div className="section_heading_image">
            <img
              src="/leads_photo.jpg"
              alt="leads"
              className="w-full object-cover h-full absolute top-0 left-0"
            />
          </div>
          <h2 className="mb-0 section_heading">Channel Leads</h2>
        </div>
      ) : heading=="bookings" ? (
        <div className="flex items-center gap-2">
            <div className="section_heading_image">
            <img
              src="/booking_photo.jpg"
              alt="leads"
              className="w-full object-cover h-full absolute top-0 left-0"
            />
          </div>
        <h2 className="mb-0 section_heading">Bookings</h2>
        </div>
      ):''} */}

      {/* <Separator /> */}
      
    
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="cursor-pointer" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        minWidth: header.column.columnDef.size,
                        maxWidth: header.column.columnDef.size,
                      }}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  style={{background: `${row.original.is_expecting_call_back?"linear-gradient(90deg, rgba(246,194,62,1) 0%, rgba(255,255,255,1) 100%)":""}`}}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      style={{
                        minWidth: cell.column.columnDef.size,
                        maxWidth: cell.column.columnDef.size,
                        // borderLeft: (cell.column.columnDef.accessorKey == 'details' ),
                        // background: cell.column.columnDef.accessorKey === "id" && " rgb(246,194,62)",
                        borderLeft:
                          (cell.column.columnDef.accessorKey == "details" &&
                          (updateEnquiryField?.id == row.original.id ? bgMapping[updateEnquiryField.status] : row.original.status ? bgMapping[row.original.status] : '')),
                      }}
                      className={`${
                        cell.column.columnDef.accessorKey === "id"
                      }`}
                      key={cell.id}
                      onClick={() => {
                        if (cell.column.columnDef.accessorKey === "id") {
                          setIsDetailsOpen(true);
                          setId(row.original.id);
                        }
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      
      <DataTablePagination hidePagination={hidePagination}table={table} />
    </div>
  );
}

export function DetailsDrawer({heading,isDetailsOpen, setIsDetailsOpen, selected}) {
  return createPortal(
  <Drawer.Root
        open={isDetailsOpen}
        onOpenChange={setIsDetailsOpen}
        direction="right"
      >
        <Drawer.Portal>
          <Drawer.Overlay className="fixed z-10 inset-0 bg-black/40" />
          <Drawer.Content className="right-0 top-0 bottom-0 fixed z-20 outline-none w-[32vw] flex">
            <div className="bg-background h-full w-full grow flex flex-col rounded-none">
              <div className="px-2">
                {heading === "leads" ? (
                  <LeadDetails lead={selected} />
                ) : (
                  <BookingDetails booking={selected} />
                )}
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>,
      document.body 
  )
}
