import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { DateRangePicker } from "@/components/Custom/DateRangePicker";
import { useToast } from "@/hooks/use-toast";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import { useCompanyStore } from "@/store/companyStore";
import { format } from "date-fns";

const DownloadLeadsModal = ({ isDownloadLeadOpen, setisDownloadLeadOpen }) => {
  const salesTeamData =
    useCompanyStore.getState().companyData.teamMembers.data.sales_team_mates;
  const allevents = useCompanyStore().companyData.events.data;
  const { toast } = useToast();
  const { companyslug } = useAuthStore();
  const today = new Date(); // Today's date
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const getQueryStringFromObject = function(queryObject) {
    var queryStringList = [];
    for (const [key, value] of Object.entries(queryObject)) {
      if (value) {
        queryStringList.push(key + "=" + value);
      }
    }
    return queryStringList.join("&");
  };

  const formReducer = (state, action) => ({
    ...state,
    [action.name]: action.value,
  });

  const [formData, dispatch] = React.useReducer(formReducer, {});

  const handleChange = (field, value) => {
    if (field == "created_at") {
        dispatch({ name: "created_at_from_date", value: format(value.from,'dd/mm/yyyy') });
        dispatch({ name: "created_at_to_date", value: format(value.to,'dd/mm/yyyy') });
      } else if (field == "modified_at") {
        dispatch({ name: "modified_at_from_date", value: format(value.from,'dd/mm/yyyy') });
        dispatch({ name: "modified_at_to_date", value: format(value.to,'dd/mm/yyyy') });
      } else {
        dispatch({ name: field, value: value });
      }
   
  };

  const downloadLeads = async function (e) {
    e.preventDefault();

    const querystring = getQueryStringFromObject(formData)
    const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/export-company-enquiries-csv/${companyslug}?${querystring}`,
        {
          responseType: "blob", // Ensure response is treated as a file
        }
      );
    // Create a blob from the response
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a temporary URL
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = `Enquiry_Report_${new Date().toISOString()}.csv`; // Custom filename
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  return (
    <div>
      <Dialog open={isDownloadLeadOpen} onOpenChange={setisDownloadLeadOpen}>
        <DialogContent
          className={"lg:max-w-md overflow-x-auto overflow-y-auto  rounded-md lg:max-h-[100vh] max-h-[70vh]"}
        >
          <DialogHeader>
            <DialogTitle>Download Enquiries</DialogTitle>
          </DialogHeader>
          <form className="flex flex-col gap-4 justify-center align-middle" onSubmit={downloadLeads}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-4">
              <div className="space-y-2 align-middle justify-center">
                <Label>Stage</Label>
                <Select required={true}>
                  <SelectTrigger className="max-w-48">
                    <SelectValue placeholder="Select stage" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="new">New</SelectItem>
                    <SelectItem value="won">Won</SelectItem>
                    <SelectItem value="contacted">Contacted</SelectItem>
                    <SelectItem value="lost">Lost</SelectItem>
                    <SelectItem value="quote_sent">Quote Sent</SelectItem>
                    <SelectItem value="payment_link_sent">
                      Payment Link Sent
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 align-middle justify-center">
                <Label>Source</Label>
                <Select onValueChange={(value)=>handleChange("funnel_stage",value)}>
                  <SelectTrigger className="max-w-48">
                    <SelectValue placeholder="All" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="instagram">Instagram</SelectItem>
                    <SelectItem value="facebook">Facebook</SelectItem>
                    <SelectItem value="whatsapp">WhatsApp</SelectItem>
                    <SelectItem value="website">Website</SelectItem>
                    <SelectItem value="google">Google</SelectItem>
                    <SelectItem value="twitter">Twitter</SelectItem>
                    <SelectItem value="ChatWithIO">Chat With IO</SelectItem>
                    <SelectItem value="MyOperator">My Operator</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 align-middle justify-center">
                <Label>Owner</Label>
                <Select onValueChange={(value)=>handleChange("owner",value)}>
                  <SelectTrigger className="max-w-48">
                    <SelectValue placeholder="Select Owner" />
                  </SelectTrigger>
                  <SelectContent>
                    {salesTeamData?.map((sale) => (
                      <SelectItem key={sale.peopleid} value={sale.peopleid}>
                        {sale.firstName || sale.username}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 align-middle justify-center">
                <Label>Contact</Label>
                <Select required={true} onValueChange={(value)=>handleChange("is_mobile",value)}>
                  <SelectTrigger className="max-w-48">
                    <SelectValue placeholder="Select Contact" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="available">With Contact</SelectItem>
                    <SelectItem value="not_available">
                      Without Contact
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 align-middle justify-center">
                <Label>Event</Label>
                <Select required={true} onValueChange={(value)=>handleChange("event_slug",value)}>
                  <SelectTrigger className="max-w-48">
                    <SelectValue placeholder="Select Event" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="null">All Events</SelectItem>
                    {allevents.map((event) => (
                      // <span>{event.slug}</span>
                      <SelectItem key={event.slug} value={event.slug || "-"}>
                        {event.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 align-middle justify-center">
                <Label>Status</Label>
                <Select required={true} onValueChange={(value)=>handleChange("status",value)}>
                  <SelectTrigger className="max-w-48">
                    <SelectValue placeholder="Select Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="hot">Hot</SelectItem>
                    <SelectItem value="wark">Warm</SelectItem>
                    <SelectItem value="cold">Cold</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col gap-1 max-w-40">
              <Label>Created-at Timeline</Label>

              <DateRangePicker
                defaultValue={{ from: "", to: "" }}
                onChange={(value) => handleChange("create_at", value)}
              />
            </div>
          </div>
          <div className="grid gap-x-4 gap-y-2 grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <Label>Modified-at Timeline</Label>
              <DateRangePicker
                className="max-w-2"
                defaultValue={{ from: "", to: "" }}
                onChange={(value) => handleChange("modified_at", value)}
              />
            </div>
          </div>
          <Button className="">
            Download
          </Button>
        </form> 
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DownloadLeadsModal;
