import {create} from 'zustand';
import { format } from 'date-fns';

const useEventStore = create((set) => ({
  eventBatches: [], // Default state for event batches
  selectedBatch: {},
  selectedEvent: {},
  batchMonth: format(new Date(), 'yyyy-MM'),

  // Function to set event batches
  setEventBatches: (batches) => set({ eventBatches: batches }),
  setSelectedBatch: (batch) => set({ selectedBatch: batch }),
  setSelectedEvent: (event) => set({ selectedEvent: event }),
  setBatchMonth: (date) => set({ batchMonth: date }),

}));

export default useEventStore;
