import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Type, PlusCircle, Send, PhoneCall, SendHorizonal, Clock, Check, CheckCheck, Info } from "lucide-react";
import axios from "axios";
import { useAuthStore } from "@/store/store";
import { useUserStore } from "@/store/userStore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css"; // Import the emoji styles
import "quill-emoji/dist/quill-emoji.js";
import moment from "moment";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Skeleton } from "./skeleton";
import TemplateMessageDialog from "./TemplateMessageDialog";

export default function ChatComponent({ number }) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true)
  const [conversation, setConversation] = useState();
  const [isLatestMessageOld, setIsLatestMessageOld] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const chatEndRef = useRef(null);
  let lastDate = "";
  const { companyslug } = useAuthStore();
  const { userData } = useUserStore();

  // React Quill Config
  const [quillToolbar, setQuillToolbar] = useState({
    container: [
      ["bold", "italic", "underline", "strike"],
      // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ["emoji"],
      // ['clean'],
    ],
  });
  const [quillFormat,setQuillFormat] = useState([
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "emoji",
  ]);
  const [quillModules, setQuillModules] = useState({
    toolbar: quillToolbar,
    "emoji-toolbar": true, // Enable emoji toolbar
    "emoji-shortname": true,
  });
  const [showEventModal, setShowEventModal] = useState(false);
  const [socket, setSocket] = useState(false);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth",block: 'nearest' });
  }, [messages]);

  const formatMessageText = (text) => {
    text = text.replace(/<pic>(.*?)<\/pic>/g, (match, url) => {
      return `<img src="${url}" alt="Picture" class="w-72 h-60 aspect-square rounded-md mb-2"/>`;
    });
  
    // Replace URLs with anchor tags
    text = text.replace(
      /(?<!")\b(https?:\/\/[^\s"]+)(?!")\b/g,
      '<a href="$1" class="max-w-60 block break-words" target="_blank">$1</a>'
    );
  
    // Replace emails with mailto links
    text = text.replace(
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g,
      '<a href="mailto:$&">$&</a>'
    );
  
    text = text.replace(
      /[\u{1D400}-\u{1D7FF}]/gu, // Bold mathematical alphanumeric block
      (match) => `<b>${match}</b>`
    );
  
    // Replace bold, italic, and strikethrough enclosed in *, _, and ~ respectively
    text = text.replace(/\*([^*]+)\*(?=\s|$)/g, "<b>$1</b>");
    text = text.replace(/_([^_]+)_(?=\s|$)/g, "<i>$1</i>");
    text = text.replace(/~([^~]+)~(?=\s|$)/g, "<s>$1</s>");
  
    // Replace newline characters with <br> tags
    text = text.replace(/\n/g, "<br>");
  
    return text;
  };

  const checkAndUpdateLastDate = (timestamp) => {
    if (lastDate != timestamp) {
      lastDate = timestamp;
      return true;
    }
    return false;
  };

  const timeAgoOrDate = (timestamp) => {
    const messageTime = moment(timestamp);
    const currentTime = moment();
    const messageDate = messageTime.format("YYYY-MM-DD");
    const currentDate = currentTime.format("YYYY-MM-DD");

    if (messageDate === currentDate) {
      return "Today";
    } else if (
      messageTime.isSame(currentTime.clone().subtract(1, "days"), "day")
    ) {
      return "Yesterday";
    } else {
      return messageTime.format("DD/MM/YY");
    }
  };

  const formatTime = (timestamp) => {
    const messageTime = moment(timestamp);

    return messageTime.format("HH:mm");
  };

  function getTickIcon(msg) {
    if (msg.status === "failed") {
      return <span class="failed_icon">
                <i class="bi bi-x-circle text-danger"></i>
                <span class="tooltip">${msg.failure_reason || "Failed to send"}</span>
              </span>;
    }
    switch (msg.status) {
      case "enqueued":
      case "submitted":
        return <Clock size={14} />;
      case "sent":
        return <Check size={14} />;
      case "delivered":
        return <CheckCheck size={14} />;
      case "read":
        return <CheckCheck size={14} className="bg-blue-500"/>;
      default:
        return "";
    }
  }

  const toolbarUpdate = () => {
    const element = document.querySelector(".ql-toolbar");
    if (element && element?.style?.display != "none") {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }
  };

  const handleEventModalShow = () => setShowEventModal(true);

  async function fetchConversation() {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/api/conversations/${companyslug}/${number}/`
      );
      return response.data[0];
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  }

  const fetchConversationMessages = async (companySlug, conversationId) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/api/get-conversation/${companySlug}/${conversationId}/`
      );
      return response.data.data.messages; // Assuming API returns messages list
    } catch (error) {
      console.error("Error fetching conversation messages:", error);
      return [];
    }
  };

  useEffect(() => {
    const loc = window.location;
    const wsStart = loc.protocol === 'https:' ? 'wss://' : 'ws://';
    const host = new URL(import.meta.env.VITE_APP_API_URL).host;
    const endpoint = `${wsStart}${host}/ws/oneinbox/${companyslug}`;
    const newSocket = new WebSocket(endpoint);
    
    newSocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    setSocket(newSocket);

    newSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const receivedMessage = data.message;
      const senderUser = data.sent_by_user;
      const sentByCustomer = data.sent_by_customer;
      const receivedThreadId = data.thread_id;
      const timestamp = data.timestamp;
      const status = data.status;
      const message_id = data.message_id;
      const failure_reason = data.failure_reason;

      // Call a function to handle the new message
      // onMessageReceive("Hello, this is a dummy message5!", true, 1, '2024-03-06T13:10:38.778Z', undefined)
      onMessageReceive(
        receivedMessage, 
        sentByCustomer, 
        receivedThreadId, 
        timestamp, 
        senderUser,
        status,
        message_id,
        failure_reason
      );
    };

    // Cleanup on component unmount
    return () => {
      newSocket.close();
    };
  }, [])

  const onMessageReceive = (
    receivedMessage, 
    sentByCustomer, 
    receivedThreadId, 
    timestamp, 
    senderUser,
    status,
    message_id,
    failure_reason
  ) => {
    const newMessage = {
      conversation: receivedThreadId,
      sent_by_customer: sentByCustomer,
      sender_user: senderUser,
      message: receivedMessage,
      time: timestamp,
      status: status,
      message_id: message_id,
      failure_reason: failure_reason
    };
    // if(sentByCustomer){
    //   const sound = new Howl({
    //     src: toAbsoluteUrl("/media/message_tune.mp3"),
    //     onend: () => {
    //       sound.stop();
    //     },
    //   });
    //   sound.play();
    // }
    setMessages((prevMessages) => {
      const messageIndex = prevMessages.findIndex(
        (msg) => msg.message_id === newMessage.message_id
      );
      if (messageIndex !== -1) {
        const updatedMessages = [...prevMessages];
        updatedMessages[messageIndex] = newMessage;
        return updatedMessages;
      } else {
        return [...prevMessages, newMessage];
      }
    });
  }


  useEffect(() => {
    const getData = async () => {
      const conversationData = await fetchConversation();
      setConversation(conversationData);
      if (conversationData?.id) {
        const messagesData = await fetchConversationMessages(
          companyslug,
          conversationData.id
        );
        setMessages(messagesData.reverse());
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    getData();
  }, [number]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastCustomerMessage = messages.filter((msg) => msg.sent_by_customer).pop();

      if (lastCustomerMessage) {
        const lastMessageTime = new Date(lastCustomerMessage.timestamp);
        const currentTime = new Date();
        const hoursDiff = (currentTime - lastMessageTime) / (1000 * 60 * 60); // Convert ms to hours

        setIsLatestMessageOld(hoursDiff > 24);

      }
    }
  }, [messages]);

  const sendMessage = (conversations = null) => {
    if (!isMessageEmpty()) {
      const unicodeStr = formatContent(message);
      const newMessage = {
        user: 5,
        sent_by_customer: false,
        sender_user: null,
        message: unicodeStr,
        time: "Just now",
      };
      let data = {
        message: unicodeStr,
        sent_by: userData?.user?.user?.user_id,
        send_to: conversation?.customer_profile?.id,
        thread_id: conversation?.id,
        param_list: [],
        template_id: "",
        is_template: false,
      };
      data = JSON.stringify(data);
      socket.send(data);
      setMessage("");
    }
  };

  const isMessageEmpty = () => {
    const cleanedMessage = message.replace(/<[^>]+>/g, ""); // Remove HTML tags
    return cleanedMessage.trim() === "";
  };

  function formatContent(content) {
    let pCount = 0; // Variable to track the occurrence of </p> tags
    const newContent = content.replace(
      /<\/?p>|<strong>|<\/strong>|<em>|<\/em>|<s>|<\/s>|<br>/g,
      (match) => {
        switch (match) {
          case "<p>":
            pCount++;
            return "";
          case "</p>":
            if (pCount > 0) {
              return "\n";
            }
            return "";
          case "<strong>":
            return "*";
          case "</strong>":
            return "*";
          case "<em>":
            return "_";
          case "</em>":
            return "_";
          case "<s>":
            return "~";
          case "</s>":
            return "~";
          case "<br>":
            return "\n";
          default:
            return match; // If no match found, return the original match
        }
      }
    );
    const contentWithoutSpan = newContent
      .replace(/<span[^>]*>/g, "")
      .replace(/<\/span>/g, "");

    return contentWithoutSpan;
  }

  const formatDateTime = (datetimeString) => {
    const dateTime = moment(datetimeString);
    const timeFormat = dateTime.format("HH:mm");
    if (timeFormat == "00:00") {
      return dateTime.format("ddd DD-MMM");
    } else {
      return dateTime.format("ddd DD-MMM hh:mm A");
    }
  };

  return (
    <div className="pt-4 px-2 flex flex-col h-full flex-grow bg-white rounded-lg shadow-lg">
      {/* Header Section */}
      <div className="flex items-center justify-between border-b pb-4 mb-4 px-2">
        <div>
          <h2 className="text-lg font-semibold text-gray-800">
            {conversation?.customer_profile?.fullname}
          </h2>
        </div>
        <Button className="bg-green-500 text-white flex items-center gap-2 px-3 py-2 rounded-lg shadow-md hover:bg-green-600 transition">
          <PhoneCall size={16} /> Call
        </Button>
      </div>

      {/* Chat Window */}
      <ScrollArea className="flex-1 lg:max-h-[52vh] max-h-[48vh] space-y-2 p-3 border rounded-lg flex flex-col">
        {
          isLoading && 
          <div className="flex flex-col gap-4">
            <div className="flex w-full justify-start">
              <Skeleton className={'h-10 w-32'} />
            </div>
            <div className="flex w-full justify-end">
              <Skeleton className={'h-10 w-32'} />
            </div>
            <div className="flex w-full justify-start">
              <Skeleton className={'h-10 w-32'} />
            </div>
            <div className="flex w-full justify-end">
              <Skeleton className={'h-10 w-32'} />
            </div>
            <div className="flex w-full justify-start">
              <Skeleton className={'h-10 w-32'} />
            </div>
            <div className="flex w-full justify-end">
              <Skeleton className={'h-10 w-32'} />
            </div>
          </div>
        }
        {messages?.map((msg, index) => {
          const isSentByCustomer = msg.sent_by_customer;
          const messageBg = isSentByCustomer ? "bg-primary text-white" : "bg-muted shadow-sm";
          const justifyClass = isSentByCustomer ? "justify-start" : "justify-end";

          return (
            <div ref={index == messages?.length-1 ? chatEndRef : null} key={msg.id} className="flex flex-col gap-2">
              {/* Date Separator */}
              {checkAndUpdateLastDate(timeAgoOrDate(msg.timestamp)) && (
                <div className="text-center text-xs text-gray-500 sticky p-2 border-[1px] justify-center items-center mx-auto flex top-0 bg-white my-2 rounded-lg">
                  {timeAgoOrDate(msg.timestamp)}
                </div>
              )}

              {/* Chat Message */}
              <div className={`flex items-start ${justifyClass} mb-4`}>
                {/* Avatar for Customer */}
                {/* {isSentByCustomer && (
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-300 text-black font-bold border border-black">
                    {customer_profile?.fullname?.charAt(0) || "U"}
                  </div>
                )} */}

                {/* Message Bubble */}
                <div className={`p-3 rounded-xl text-sm max-w-[75%] ${messageBg}`}>
                  <div className="max-w-60" dangerouslySetInnerHTML={{ __html: formatMessageText(msg.message) }} />
                  <div className="text-xs text-gray-400 flex justify-end items-center mt-1">
                    {formatTime(msg.timestamp)}
                    <span className="ml-2">{getTickIcon(msg)}</span>
                  </div>
                </div>

                {/* Avatar for Bot/Company */}
                {!isSentByCustomer && (
                  <Avatar className="ml-2">
                    {/* <AvatarImage src={`${import.meta.env.VITE_APP_API_URL}${user.company.cover_pic}`} alt="Bot" /> */}
                    <AvatarImage src="" alt="Bot" />
                    <AvatarFallback>🤖</AvatarFallback>
                  </Avatar>
                )}
              </div>
            </div>
          );
        })}
        {/* <div ref={chatEndRef} /> */}
      </ScrollArea>


      {/* Message Input with ReactQuill */}
      {!isLatestMessageOld && <div className="border border-gray-300 rounded-xl overflow-hidden mt-2 bg-white max-h-[25%]">
        <ReactQuill
          className="w-full p-0 rounded-xl border-none "
          theme="snow"
          modules={quillModules}
          formats={quillFormat}
          value={message}
          // onKeyDown={onEnterPress}
          onChange={(
            content,
            delta,
            source,
            editor
          ) => setMessage(content)}
          // onKeyDown={onEnterPress}
          placeholder="Type your message..."
        />
        <div className="flex items-center justify-between bg-white p-3 border-t rounded-b-lg">
          {/* Left Side - Formatting & Attachments */}
          <div className="flex items-center space-x-2">
            {/* Formatting Button */}
            <button
              className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition"
              title="Formatting"
              onClick={toolbarUpdate}
            >
              <Type className="w-5 h-5 text-gray-700" />
            </button>

            {/* Dropdown for Attachments */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button className="relative p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition">
                  <PlusCircle className="w-5 h-5 text-gray-700" />
                  <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full animate-ping"></span>
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-48 shadow-md rounded-md">
                <DropdownMenuItem onClick={handleEventModalShow}>
                  Event Itineraries
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {/* Send Button */}
          <Button
            className="p-2 rounded-md transition"
            onClick={sendMessage}
            disabled={isMessageEmpty()}
          >
            <SendHorizonal className="w-5 h-5" />
          </Button>
        </div>
      </div>}
      {isLatestMessageOld && conversation?.id && (
        <div className="mx-auto mt-4">
          {/* Alert Component */}
          <Alert variant="default">
            <Info className="h-5 w-5 text-blue-500" />
            <div className="flex flex-col gap-2">
              <AlertTitle>No Reply From Customer in Last 24hrs</AlertTitle>
              <AlertDescription>
                To send a normal message to the user, the customer should have replied in the last 24hrs. 
                You can still send a template message.
              </AlertDescription>

              {/* Button to Open Dialog */}
              <Button variant="outline" onClick={() => setIsDialogOpen(true)}>
                Send Template Message
              </Button>
            </div>
          </Alert>

          {/* Dialog Component */}
          <TemplateMessageDialog open={isDialogOpen} setOpen={setIsDialogOpen} company={companyslug} mobile={number} conversation_id={conversation?.id} />
        </div>
      )}
    </div>
  );
}
