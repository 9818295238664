import React, { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AudioWaveform,
  BookOpen,
  BookUser,
  Bot,
  CheckCheck,
  Command,
  Frame,
  GalleryVerticalEnd,
  Map,
  PieChart,
  Repeat,
  Settings2,
  ShieldCheck,
  SquareTerminal,
  Ticket,
  Undo2,
  ChevronRight,
} from "lucide-react";
import {
  Calendar,
  Compass,
  UsersRound,
  TicketPercent,
  Send,
  Home,
  Inbox,
  MessageSquare,
  Headset,
  TicketCheck,
  Zap,
  ShoppingCart,
  Tent,
  Smile,
  Instagram,
  Cog,
  MapPinned,
  Star,
  ChartBar,
  WalletCards,
  ScrollText,
  ReceiptText,
  Rss,
  Users,
  UserPen,
} from "lucide-react";
import { setISODay } from "date-fns";
import { FaWhatsapp } from "react-icons/fa";
import { useAuthStore } from "@/store/store";
const companyslug = "logoutworld";
const accessToken = "logoutworld";
const navMain = [
  {
    title: "My Events",
    url: "allplans",
    icon: Map,
    id: 'my_events'
  },
  // {
  //   title: "Stays",
  //   url: "stay",
  //   icon: Home,
  // },
  {
    title: "Leads",
    url: "#",
    icon: Headset,
    id: 'leads',

    items: [
      {
        title: "Missed Checkouts",
        url: "missed-checkouts",
        id: 'missed_checkout'
      },
      // {
      //   title: "Channel Leads Admin",
      //   url: "enquiry",
      // },
      {
        title: "Channel Leads",
        url: "enquiry",
        id: 'channel_leads'
      },
      // {
      //   title: "Leads Profile",
      //   url: "#",
      // },
    ],
  },
  {
    title: "Bookings",
    url: "",
    icon: Zap,
    id: 'all_bookings',

    items: [
      {
        title: "All Bookings",
        url: "allbookings",
        id: 'bookings'
      },
      {
        title: "Transactions",
        url: "alltransactions",
        id: 'transactions'
      },
      {
        title: "Settlements",
        url: "settlement",
        id: 'settlements'
      },
      {
        title: "Customers",
        url: "customers",
        id: 'customers'
      },
    ],
  },
  {
    title: "Calendar",
    url: `/calendar/${companyslug}`,
    icon: Calendar,
    id: 'calendar'
  },

  {
    title: "Coupons",
    url: "companyCoupon",
    icon: TicketPercent,
    id: 'coupons'
  },
  // {
  //   title: "Oneinbox",
  //   url: "#",
  //   icon: MessageSquare,
  // },
  {
    title: "Oneinbox",
    url: `/app/auth/${accessToken}`,
    icon: MessageSquare,
    id: 'oneinbox'
  },

  {
    title: "Operations",
    url: "operationTab",
    icon: UsersRound,
    id: 'operations'
  },
  {
    title: "Membership & Agents",
    url: "#",
    icon: Smile,
    id: 'membership_agents',

    items: [
      {
        title: "Membership Plans",
        url: "membershipPlan",
        id: 'membership_plans'
      },
      {
        title: "Members",
        url: "members",
        id: 'members'
      },
      {
        title: "Agents",
        url: "agents",
        id: 'agent'
      },
    ],
  },
  {
    title: "Airlink",
    url: `https://myair.link/auth/${accessToken}/${companyslug}`,
    icon: Send,
    id: 'airlink'
  },
  {
    title: "Instagram",
    url: "#",
    icon: Instagram,
    id: 'instagram',

    items: [
      {
        title: "DM Bot Status",
        url: `/dashboard/${companyslug}/update-bot-status/`,
        id: 'dm_bot_status'
      },
      {
        title: "Bot Q & A",
        url: `/dashboard/${companyslug}/list-bot-question-answer/`,
        id: 'bot_qna'
      },
      {
        title: "Custom Bot Replies",
        url: `/dashboard/${companyslug}/update-bot-reply/`,
        id: 'custom_bot_replies'
      },
      {
        title: "Auto Commentor",
        url: "instacomment",
        id: 'auto_commentor'
      },
      {
        title: "AI Prompts",
        url: `/dashboard/${companyslug}/company-prompts/`,
        id: 'ai_prompts'
      },
    ],
  },
  {
    title: "WhatsApp",
    url: "#",
    icon: FaWhatsapp,
    id: 'whatsapp',

    items: [
      {
        title: "WhatsApp Q & A",
        url: `/dashboard/${companyslug}/list-whatsapp-question-answer/`,
        id: 'whatsapp_qna'
      },
    ],
  },
  // {
  //   title: "App Integrations",
  //   url: "#",
  //   icon: Cog,
  //   items: [
  //     {
  //       title: "All Bookings",
  //       url: "#",
  //     },
  //     {
  //       title: "Online",
  //       url: "#",
  //     },
  //     {
  //       title: "Manual",
  //       url: "#",
  //     },
  //     {
  //       title: "Pending Collections",
  //       url: "#",
  //     },
  //   ],
  // },
  {
    title: "Pickup Points",
    url: "pickuppoints",
    icon: MapPinned,
    id: 'pickup_points'
  },
  // {
  //   title: "Reviews",
  //   url: "review",
  //   icon: Star,
  // },
  {
    title: "Analytics",
    url: "analytics",
    icon: ChartBar,
    id: 'analytics'
  },
  {
    title: "Manage Team",
    url: "team",
    icon: Users,
    id: 'manage_team'
  },
];

const ManageTeam = ({ isOpen, setIsOpen, role }) => {
  const [openCollapsible, setOpenCollapsible] = React.useState([]);
  const [visibleTabs, setVisibleTabs] = useState([])
  const {companyslug} = useAuthStore();
  const [loading, setLoading] = useState(false)
  console.log(role)
 
  const fetchTabs = async() => {
    const resp = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/access-list/${companyslug}/?role=${role.toLowerCase()}`)
    setVisibleTabs(resp.data.tabs)
  }

  const changeTabStatus = (tab, value) => {
    console.log(tab.id, value)
      if(value==true && visibleTabs.includes(tab.id)) return;
      if(value==false && !visibleTabs.includes(tab.id)) return;
      if(value==true && !visibleTabs.includes(tab.id)){
        setVisibleTabs([...visibleTabs, tab.id])
        if(tab.id == 'missed_checkout' || tab.id == "channel_leads"){
          if(!visibleTabs.includes('leads'))
          setVisibleTabs([...visibleTabs, 'leads'])
        }
        if(tab.id == 'all_bookings' || tab.id == 'transactions' || tab.id == 'settlements' || tab.id == 'customers'){
          if(!visibleTabs.includes('bookings'))
          setVisibleTabs([...visibleTabs, 'bookings'])
        }
        if(tab.id == 'membership_plans' || tab.id == 'members' || tab.id == 'agent'){
          if(!visibleTabs.includes('membership_agents'))
          setVisibleTabs([...visibleTabs, 'membership_agents'])
        }
        if(tab.id == 'dm_bot_status' || tab.id == 'bot_qna' || tab.id == 'custom_bot_replies' || tab.id == 'auto_commentor' || tab.id == 'ai_prompts'){
          if(!visibleTabs.includes('instagram'))
          setVisibleTabs([...visibleTabs, 'instagram'])
        }
        if(tab.id == 'whatsapp_qna'){
          if(!visibleTabs.includes('whatsapp'))
          setVisibleTabs([...visibleTabs, 'whatsapp'])
        }
        console.log(visibleTabs)
      }
      if(value==false && visibleTabs.includes(tab.id)){
        let filteredTabs = visibleTabs.filter((o)=> o != tab.id)
        console.log(filteredTabs)
        setVisibleTabs(filteredTabs)
      }
  }

  const updateTabsVisibility = async () => {
    setLoading(true)
    const data = {
      company: companyslug,
      role: role,
      tabs: visibleTabs
    }
    const res = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/access-control/${companyslug}/`, data)
    setLoading(false)
    setIsOpen(false)
  }

  useEffect(()=>{
    fetchTabs()
  },[])


  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          className={
            "lg:max-w-lg overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Manage Access</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-2">
            {navMain.map((item) =>
              item?.items ? (
                <Collapsible
                  key={item.title}
                  asChild
                  //   defaultOpen={item.isActive}
                  className="group/collapsible"
                  open={true}
                >
                  <div className="group">
                    <SidebarMenuButton
                      className="justify-between"
                      tooltip={item.title}
                    >
                      <div className="flex items-center gap-2">
                        {item.icon && <item.icon size={16} />}
                        <span>{item.title}</span>
                      </div>
                      {/* <Switch
                        onCheckedChange={(value) => {
                          if (value == true) {
                            if (openCollapsible.includes(item.title)) return;

                            setOpenCollapsible((prev) => [...prev, item.title]);
                          } else {
                            var array = openCollapsible.filter(
                              (e) => e !== item.title
                            );
                            setOpenCollapsible(array);
                          }
                        }}
                      /> */}
                    </SidebarMenuButton>
                    <CollapsibleContent>
                      <SidebarMenuSub className="mr-0">
                        {item.items?.map((subItem) => (
                          <SidebarMenuSubItem
                            // onClick={() => updateRoute(subItem.url)}
                            key={subItem.title}
                            className="mr-0"
                          >
                            <SidebarMenuSubButton asChild>
                              <div className="flex pr-0 items-center justify-between gap-4">
                                <span className="cursor-pointer">
                                  {subItem.title}
                                </span>
                                <Switch className="ml-4" checked={visibleTabs?.includes(subItem.id)} 
                                onCheckedChange={(value)=>changeTabStatus(subItem, value)}/>
                              </div>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </div>
                </Collapsible>
              ) : (
                <div key={item.title}>
                  <SidebarMenuButton
                    className="justify-between"
                    tooltip={item.title}
                  >
                    <div className="flex items-center gap-2">
                    {item.icon && <item.icon size={16}/>}
                    <span className="cursor-pointer">{item.title}</span>
                    </div>
                    <Switch onCheckedChange={(value)=>changeTabStatus(item, value)} checked={visibleTabs?.includes(item.id)}/>
                  </SidebarMenuButton>
                </div>
              )
            )}
          </div>
          <div className="flex w-full justify-end">
            <Button className="w-24" onClick={updateTabsVisibility}>{loading ? <div className="dots"></div> : 'Update'}</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageTeam;
