export const stageOptions = [
    {
      value: "Lead Stage",
      label: "Lead Stage",
    },
    {
      value: "new",
      label: "New",
    },
    {
      value: "contacted",
      label: "Contacted",
    },
    {
      value: "quote",
      label: "Quote Sent",
    },
    {
      value: "payment",
      label: "Payment Link Sent",
    },
    {
      value: "won",
      label: "Won",
    },
    {
      value: "lost",
      label: "Lost",
    },
  ]

  export const statusOptions = [
      {
        value: "Lead Status",
        label: "Lead Status",
      },
      {
        value: "hot",
        label: "Hot",
      },
      {
        value: "warm",
        label: "Warm",
      },
      {
        value: "cold",
        label: "Cold",
      },
    ];

export const ownerOptions = [
  {
    value: "1",
    label: "Owner 1",
    image: "/thumbnail.png",
  },
  {
    value: "2",
    label: "Owner 2",
    image: "/thumbnail.png",
  },
];

export const contactOptions = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "available",
    label: "With Contact",
  },
  {
    value: "not_available",
    label: "Without Contact",
  },
]

export const callbackOptions = [
  {
    value: "--",
    label: "--",
  },
  {
    value: "true",
    label: "Callback Yes",
  },
  {
    value: "false",
    label: "Callback No",
  },
];

export const sourceOptions = [
  {
    "value": "source",
    "label": "Source"
  },
  {
    "value": "instagram",
    "label": "Instagram"
  },
  {
    "value": "facebook",
    "label": "Facebook"
  },
  {
    "value": "twitter",
    "label": "Twitter"
  },
  {
    "value": "google",
    "label": "Google"
  },
  {
    "value": "whatsapp",
    "label": "Whatsapp"
  },
  {
    "value": "website",
    "label": "Website"
  },
  {
    "value": "tripoto",
    "label": "Tripoto"
  },
  {
    "value": "insta-holidays",
    "label": "Insta Holidays"
  },
  {
    "value": "call",
    "label": "Call"
  },
  {
    "value": "linkedin",
    "label": "Linkedin"
  },
  {
    "value": "reference",
    "label": "Reference"
  },
  {
    "value": "chatbot",
    "label": "Instagram DM"
  },
  {
    "value": "meetup",
    "label": "Meetup"
  },
  {
    "value": "MyOperator",
    "label": "MyOperator"
  },
  {
    "value": "ChatWithIO",
    "label": "ChatWithIO"
  },
  {
    "value": "other",
    "label": "Other"
  }
]


export const sortOptions = [
  {
    value: "",
    label: "Sort By",
  },
  {
    value: "old-new",
    label: "Created Time: Oldest to Newest",
  },
  {
    value: "new-old",
    label: "Created Time: Newest to Oldest",
  },
  {
    value: "asc",
    label: "Event Name: A-Z",
  },
  {
    value: "desc",
    label: "Event Name: Z-A",
  }
]

export const timelineData = [
	{
		id: 1,
		title: "Updated",
		date: "2022-01-01",
    field: 'status',
    oldValue: 'None',
    newValue: 'hot',
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Odio euismod lacinia at quis risus sed vulputate odio ut. Quam viverra orci sagittis eu volutpat odio facilisis mauris.",
	},
	{
		id: 2,
		title: "Updated",
		date: "2022-02-01",
    field: 'remark',
    oldValue: 'None',
    newValue: 'First remark',
		description:
			"Aut eius excepturi ex recusandae eius est minima molestiae. Nam dolores iusto ad fugit reprehenderit hic dolorem quisquam et quia omnis non suscipit nihil sit libero distinctio. Ad dolorem tempora sit nostrum voluptatem qui tempora unde? Sit rerum magnam nam ipsam nesciunt aut rerum necessitatibus est quia esse non magni quae.",
	},
	{
		id: 3,
		title: "Created",
		date: "2022-03-01",
    field: 'is_pdf_downloaded',
    oldValue: 'No',
    newValue: 'Yes',
		description:
			"Sit culpa quas ex nulla animi qui deleniti minus rem placeat mollitia. Et enim doloremque et quia sequi ea dolores voluptatem ea rerum vitae. Aut itaque incidunt est aperiam vero sit explicabo fuga id optio quis et molestiae nulla ex quae quam. Ab eius dolores ab tempora dolorum eos beatae soluta At ullam placeat est incidunt cumque.",
	},
];

export const bookingTimelineData = [
  
  {
    step: 'Booking Received',
    transactions: {
    paid_on: 'Sep 11, 2024 11:00 PM',
    amount: '₹1.04',
    payment_id: 'pay_OvrDVGGIbRbkOR'
    }
  },
  {
    step: 'Payment Received',
    transactions: {
    paid_on: 'Sep 11, 2024 11:05 PM',
    amount: '₹1.04',
    payment_id: 'pay_OvrDVGGIbRbkORW'
    }
  },
  {
    step: 'Refund Generated',
    transactions: {
     create_on: 'Sep 11, 2024 11:10 PM',
     amount: '₹1.04',
    }
  },
  {
    step: 'Amount Settled For Refund',
    transactions: {
    settled_on: 'Sep 11, 2024 11:00 PM',
    amount: '₹1.04',
    settlement_id: 'OvrDVGGIbRbkOR'
    }
  },
  {
    step: 'Amount Settled For Payment Link',
    transactions: {
    settled_on: 'Sep 11, 2024 11:00 PM',
    amount: '₹1.04',
    settlement_id: 'OvrDVGGIbRbkOR'
    }
  }
]
export const eventFilterData = [
  {
    value: "all",
    label: "All Events",
  },
  {
    value: 'upcoming',
    label: 'With Upcoming Batches'
  },
  {
    value: 'notupcoming',
    label: 'Without Upcoming Batches'
  }
]

export const categories = [
  {
    value: "all",
    label: "All Categories",
  },
  {
    value: 'category1',
    label: 'Category 1'
  },
  {
    value: 'category2',
    label: 'Category 2'
  }
]

export const liveStatus = [
  {
    value: "all",
    label: "All",
  },
  {
    value: 'live',
    label: 'Live'
  },
  {
    value: 'draft',
    label: 'Draft'
  }
]

export const coupons = [
  {
    code: 'COUPON1',
    discount: '10%',
    validFrom: 'Fri. 01 Jan, 2023',
    validTill: 'Fri. 01 Jan, 2024',
    discountType: 'Percentage',
    couponType: 'Public',
    used: 1,
    total: 10,
    couponLevel: 'company'
  },
  {
    code: 'COUPON2',
    discount: '15%',
    validFrom: 'Fri. 01 Jan, 2024',
    validTill: 'Fri. 01 Jan, 2025',
    discountType: 'Percentage',
    couponType: 'Public',
    used: 1,
    total: 10,
    couponLevel: 'company'
  },
  {
    code: 'COUPON3',
    discount: '200',
    validFrom: 'Fri. 01 Jan, 2023',
    validTill: 'Fri. 01 Jan, 2023',
    discountType: 'Amount',
    couponType: 'Public',
    used: 1,
    total: 10,
    couponLevel: 'event'
  },
  {
    code: 'COUPON3',
    discount: '200',
    validFrom: 'Fri. 01 Jan, 2023',
    validTill: 'Fri. 01 Jan, 2023',
    discountType: 'Amount',
    couponType: 'Public',
    used: 1,
    total: 10,
    couponLevel: 'batch'
  },

]
