import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'


const authStore = (set, get) => ({
  accessToken: null,
  companyslug: null,
  accessLevel: "admin",
  setUser: (token) => {
    set((state) => (
        {
            ...state,
            accessToken: token,
        }
    ));
  },
  setCompanySlug: (company) => {
    set((state) => (
        {
            ...state,
            companyslug: company,
        }
    ));
  },
  setAccessLevel: (access) => {
    set((state) => (
        {
            ...state,
            accessLevel: access,
        }
    ));
  },
  reset: () => {
    set({
      accessToken: null,
    });
  },
});


const useAuthStore = create(persist(authStore, {
    name: "auth",
    storage: createJSONStorage(() => localStorage),
  }));


export {useAuthStore};