import { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

export default function WidgetPreview({ styles }) {
  const [selectedDate, setSelectedDate] = useState("Fri, 21 Mar");

  return (
    <div>
      <div
        style={{
          color: styles.textColor,
          scrollbarWidth: "none",
        }}
        className="h-auto overscroll-none overflow-y-auto preview_container w-[85vw] p-4 pb-0 md:w-[55vw] lg:w-[310px] shadow-lg border-4 mt-4 rounded-3xl"
      >
        <h2 className="text-base font-semibold">Personal Details</h2>
        <div className="flex items-start relative justify-between space-x-2 mt-2">
          <div className="absolute left-[12%] top-[16%] z-0 h-[1px] w-[81%] bg-gray-300" />
          <div className="flex flex-col items-center">
            <div
              style={{
                background: styles.buttonColor,
                borderColor: styles.buttonColor,
                "--tw-ring-color": styles.buttonColor,
              }}
              className="w-3 h-3 ring-[1px] ring-offset-2 rounded-full"
            />
            <span className="text-xs mt-2">Details</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-3 h-3 bg-gray-300 rounded-full" />
            <span className="text-xs text-gray-400 mt-2">Book & Pay</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-3 h-3 bg-gray-300 rounded-full" />
            <span className="text-xs text-gray-400 mt-2">Confirm</span>
          </div>
        </div>
        <Card
          style={{
            color: styles.textColor,
            borderRadius: styles.borderRadius == "rounded" ? "6px" : "0px",
            borderColor: styles.borderColor,
          }}
          className="mt-4 pb-0 border-none"
        >
          <CardContent className="pt-4 pb-0 p-0 relative">
            <hr style={{
              borderColor: styles.borderColor
            }} className="my-4 -mx-4" />
            <div className="flex items-center space-x-4">
              <div className="w-20 h-14 relative overflow-hidden bg-gray-200 rounded-md">
                <img
                  src="/thumbnail.png"
                  className="absolute w-full h-full object-cover"
                />
              </div>
              <div>
                <h3 className="text-base font-medium">
                  THE COAST - GETAWAY AT GOKARNA
                </h3>
                <p className="text-sm">₹1,200 / person</p>
              </div>
            </div>
            {/* <hr className="my-4 -mx-4" /> */}
            <div className="mt-4">
              <Label className="text-sm">Select Date</Label>
              <div
                style={{
                  scrollbarWidth: "none",
                  borderRadius:
                    styles.borderRadius == "rounded" ? "6px" : "0px",
                  borderColor: styles.borderColor,
                }}
                className="flex space-x-2 max-w-sm overflow-scroll border-[1px] p-3 rounded-md mt-2"
              >
                <Button
                  // className="py-5 rounded-[10px] border-none text-foreground text-sm px-3"
                  className={`rounded-[10px] hover:bg-accent bg-accent text-foreground text-[13px] font-normal min-w-[90px] px-2 py-5 `}
                  // variant={selectedDate === "Fri, 21 Mar" ? "" : ""}
                  style={{
                    background:
                      selectedDate == "Fri, 21 Mar"
                        ? `${styles.buttonColor}15`
                        : "",
                    border:
                      selectedDate == "Fri, 21 Mar"
                        ? `1px solid ${styles.buttonColor}`
                        : "",
                    color:
                      selectedDate == "Fri, 21 Mar"
                        ? `${styles.buttonColor}`
                        : `${styles.textColor}`,
                    borderRadius:
                      styles.borderRadius == "rounded" ? "8px" : "0px",
                  }}
                  onClick={() => setSelectedDate("Fri, 21 Mar")}
                >
                  Fri, 21 Mar
                </Button>
                <Button
                  // variant={selectedDate === "Sat, 22 Mar" ? "" : ""}
                  onClick={() => setSelectedDate("Sat, 22 Mar")}
                  className={
                    "rounded-[10px] bg-accent text-foreground hover:bg-accent text-[13px] font-normal min-w-[90px] px-2 py-5"
                  }
                  style={{
                    background:
                      selectedDate == "Sat, 22 Mar"
                        ? `${styles.buttonColor}15`
                        : "",
                    border:
                      selectedDate == "Sat, 22 Mar"
                        ? `1px solid ${styles.buttonColor}`
                        : "",
                    color:
                      selectedDate == "Sat, 22 Mar"
                        ? `${styles.buttonColor}`
                        : `${styles.textColor}`,
                    borderRadius:
                      styles.borderRadius == "rounded" ? "8px" : "0px",
                  }}
                >
                  Sat, 22 Mar
                </Button>
                <Button
                  // variant={selectedDate === "Sun, 23 Mar" ? "" : ""}
                  onClick={() => setSelectedDate("Sun, 23 Mar")}
                  className={
                    "rounded-[10px] bg-accent text-foreground hover:bg-accent text-[13px] font-normal min-w-[90px] px-2 py-5"
                  }
                  style={{
                    background:
                      selectedDate == "Sun, 23 Mar"
                        ? `${styles.buttonColor}15`
                        : "",
                    border:
                      selectedDate == "Sun, 23 Mar"
                        ? `1px solid ${styles.buttonColor}`
                        : "",
                    color:
                      selectedDate == "Sun, 23 Mar"
                        ? `${styles.buttonColor}`
                        : `${styles.textColor}`,
                    borderRadius:
                      styles.borderRadius == "rounded" ? "8px" : "0px",
                  }}
                >
                  Sun, 23 Mar
                </Button>
                {/* <Button variant="outline">
                <ChevronRight size={16} />
              </Button> */}
              </div>
            </div>
            <div className="relative mt-4">
              <Input
                type="text"
                id="floating_outlined"
                className="peer h-10 focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0"
                placeholder=" " // This helps peer-placeholder-shown work
                //   value={buyerData.name}
                name="name"
                required
                style={{
                  borderRadius:
                    styles.borderRadius == "rounded" ? "6px" : "0px",
                  borderColor: styles.borderColor,
                }}
                //   onChange={(e) => updateBuyerData("name", e.target.value)}
              />
              <Label
                for="floating_outlined"
                class="absolute text-sm left-2 text-muted-foreground duration-300 transform -translate-y-4 scale-75 top-[0.35rem] peer-focus:left-2 peer-placeholder-shown:left-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primray peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[0.35rem]  peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Name
              </Label>
            </div>
            <div className="mt-4">
              <div className="flex items-center space-x-2 mt-1">
                <span
                  style={{
                    borderRadius:
                      styles.borderRadius == "rounded" ? "6px" : "0px",
                    borderColor: styles.borderColor,
                  }}
                  className="px-4 py-2 border-[1px] rounded-md"
                >
                  +91
                </span>
                <div className="relative w-full">
                  <Input
                    type="tel"
                    id="phone"
                    className="peer h-10 focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0"
                    placeholder=" " // This helps peer-placeholder-shown work
                    //   value={buyerData.name}
                    name="name"
                    required
                    style={{
                      borderRadius:
                        styles.borderRadius == "rounded" ? "6px" : "0px",
                      borderColor: styles.borderColor,
                    }}
                    //   onChange={(e) => updateBuyerData("name", e.target.value)}
                  />
                  <Label
                    for="phone"
                    class="absolute text-sm left-2 text-muted-foreground duration-300 transform -translate-y-4 scale-75 top-[0.35rem] peer-focus:left-2 peer-placeholder-shown:left-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primray peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[0.35rem]  peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Mobile Number
                  </Label>
                </div>
              </div>
            </div>
            <div className="relative mt-4">
              <Input
                type="email"
                id="email"
                className="peer h-10 focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0"
                placeholder=" " // This helps peer-placeholder-shown work
                //   value={buyerData.name}
                style={{
                  borderRadius:
                    styles.borderRadius == "rounded" ? "6px" : "0px",
                  borderColor: styles.borderColor,
                }}
                name="email"
                required
                //   onChange={(e) => updateBuyerData("name", e.target.value)}
              />
              <Label
                for="email"
                class="absolute text-sm left-2 text-muted-foreground duration-300 transform -translate-y-4 scale-75 top-[0.35rem] peer-focus:left-2 peer-placeholder-shown:left-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primray peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[0.35rem]  peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Email
              </Label>
            </div>
            <div className="relative mt-4">
              <Input
                type="email"
                id="email"
                className="peer h-10 focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0"
                placeholder=" " // This helps peer-placeholder-shown work
                //   value={buyerData.name}
                style={{
                  borderRadius:
                    styles.borderRadius == "rounded" ? "6px" : "0px",
                  borderColor: styles.borderColor,
                }}
                name="email"
                required
                //   onChange={(e) => updateBuyerData("name", e.target.value)}
              />
              <Label
                for="email"
                class="absolute text-sm left-2 text-muted-foreground duration-300 transform -translate-y-4 scale-75 top-[0.35rem] peer-focus:left-2 peer-placeholder-shown:left-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primray peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[0.35rem]  peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Source
              </Label>
            </div>
            <div className="sticky bg-white z-20 pb-3 bottom-0 w-full">
              <Button
                style={{
                  backgroundColor: styles.buttonColor,
                  color: styles.buttonTextColor,
                  borderRadius:
                    styles.borderRadius == "rounded" ? "6px" : "0px",
                  borderColor: styles.borderColor,
                }}
                className="w-full mt-6 text-sm py-4"
              >
                Proceed
              </Button>
              {/* <p className="text-center text-xs text-gray-500 my-2">
                secured by: <span className="text-blue-600">logout.studio</span>
              </p> */}
            </div>
          </CardContent>
        </Card>
      </div>
     
    </div>
  );
}
