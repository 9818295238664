"use client";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTableColumnHeader } from "./ui/data-table-column-header";
import useFetch from "@/hooks/useFetchLSD";
import {
  ArrowUpDown,
  ChevronDown,
  MoreHorizontal,
  SquarePen,
} from "lucide-react";
import { DateRangePicker } from "@/components/Custom/DateRangePicker";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Search } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ListFilter } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAuthStore } from "@/store/store";
import { format } from "date-fns";
import { DatePicker } from "./Custom/DatePicker";
import { DataTablePagination } from "./ui/data-table-pagination";

const columns = [
  {
    accessorKey: "username",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => <div>{row.getValue("username")}</div>,
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location" />
    ),
    cell: ({ row }) => <div>{row.getValue("location") || "N/A"}</div>,
    enableSorting: false,
  },
  {
    accessorKey: "active",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => (
      <div>{row.getValue("active") == true ? "Active" : "N/A"}</div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: "booking_limit",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Limit" />
    ),
    cell: ({ row }) => <div>{row.getValue("booking_limit") || "N/A"}</div>,
    enableSorting: false,
  },
  {
    accessorKey: "vendor_bookings",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Bookings" />
    ),
    cell: ({ row }) => <div>{row.original.vendor_bookings}</div>,
    enableSorting: false,
  },
  {
    accessorKey: "commission",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Commission" />
    ),
    cell: ({ row }) => (
      <div>
        &#8377;
        {row.original.vendor_commission
          ? (row.original.vendor_commission / 100).toFixed(2)
          : 0}
      </div>
    ),
    enableSorting: true,
  },
];

export default function AgentsTab() {
  const [sorting, setSorting] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const { companyslug } = useAuthStore();
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    booking_from_date: format(new Date(), "yyyy-MM-dd"),
    booking_to_date: format(new Date(), "yyyy-MM-dd"),
    batch_from_date: format(new Date(), "yyyy-MM-dd"),
    batch_to_date: format(new Date(), "yyyy-MM-dd"),
  });

  const [pageFilters, setPageFilters] = useState({
    page: "",
    size: "",
  });

  useEffect(() => {
    setPageFilters((prev) => ({
      ...prev,
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
    }));
  }, [pagination]);

  const {
    data: agents,
    error,
    isFetching,
    refetch,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/agents-stats-list/${companyslug}`,
    filters,
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: false,
        keepPreviousData: true,
      },
    },
    "agents"
  );

  useEffect(() => {
    setVendors(agents?.vendors);
  }, [agents]);

  useEffect(() => {
    refetch();
  }, []);

  const handleExport = async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/export-agents-stats-list/${companyslug}/?booking_from_date=${
          filters.booking_from_date
        }&booking_to_date=${filters.booking_to_date}&batch_from_date=${
          filters.batch_from_date
        }&batch_to_date=${filters.batch_to_date}`
      );

      // Create a blob from the response
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a temporary URL
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger a download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${companyslug}-agents-booking_date_from-${filters.booking_from_date}-booking_date_to-${filters.booking_to_date}-event_date_from-${filters.batch_from_date}-event_date_to-${filters.batch_to_date}.csv`; // Custom filename
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const handleApplyFilters = () => {
    refetch();
  };

  const table = useReactTable({
    data: vendors?.length > 0 ? vendors : [],
    columns,
    manualPagination: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: pagination || { pageIndex: 0, pageSize: 10 },
      globalFilter,
    },
    customParams: {
      hideSelectionInfo: true,
    },
  });

  return (
    <div className="w-full">
      <div>
        <h1 className="section_heading">Agent Summary</h1>
      </div>

      <div className="flex gap-2 items-center py-4">
        <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <form>
            <div className="relative">
              <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
              <Input
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search"
                className="pl-8 h-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            </div>
          </form>
        </div>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="outline">Actions</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={handleExport}>
                Download Records
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Popover align="end">
          <PopoverTrigger>
            <Button className="h-8" variant="outline">
              <ListFilter size={14} />
              <span className="hidden md:flex">Filters</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto">
            <div className="grid pb-4 gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
              <div className="flex w-48 flex-col gap-1">
                <Label>Bookings Start Date</Label>
                <DatePicker
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      booking_from_date: format(value, "yyyy-MM-dd"),
                    });
                  }}
                />
              </div>
              <div className="flex w-48 flex-col gap-1">
                <Label>Bookings End Date</Label>
                <DatePicker
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      booking_to_date: format(value, "yyyy-MM-dd"),
                    });
                  }}
                />
              </div>
              <div className="flex w-48 flex-col gap-1">
                <Label>Event Start Date</Label>
                <DatePicker
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      batch_from_date: format(value, "yyyy-MM-dd"),
                    });
                  }}
                />
              </div>
              <div className="flex w-48 flex-col gap-1">
                <Label>Event End date</Label>
                <DatePicker
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      batch_to_date: format(value, "yyyy-MM-dd"),
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full mt-4 md:mt-2">
              <Button className="w-full flex" onClick={handleApplyFilters}>
                Apply Filters
              </Button>
            </div>
          </PopoverContent>
        </Popover>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown size={16} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            className="flex flex-col gap-2 p-2"
          >
            <DropdownMenuSeparator />
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="rounded-md border mt-2">
        <Table>
          <TableHeader className="bg-neutral-50 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead className="py-2" key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-4">
        <DataTablePagination table={table} />
      </div>
      {/* <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of {" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div> */}
    </div>
  );
}
