import React, { useState, useEffect } from "react";
import { CircleX, GripVertical } from "lucide-react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

const SortableItem = ({ item, items, setItems, data, updateStageForm }) => {
  const handleRemoveItem = (id) => {
    console.log(id);
    const updatedItems = items.filter((cat) => cat.id != id);
    console.log(updatedItems)
    setItems(updatedItems);
    const updated = [...data.forms]
    const text = updated.find((node)=>node.type=='quick_reply')
    text.value = updatedItems
    updateStageForm(data.id, updated)
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
  } = useSortable({ id: item?.id });
  const [isSortingActive, setIsSortingActive] = useState(false);

  useEffect(() => {
    if (isSorting) setIsSortingActive(true);
    else setTimeout(() => setIsSortingActive(false), 100); // Delay to prevent flicker
  }, [isSorting]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isSortingActive ? "none" : transition,
    opacity: isDragging ? 0.6 : 1,
    // willChange: !data?.meta_data?.editable? "transform":undefined,
  };

  return (
    <div>
      <div
        ref={setNodeRef}
        style={style}
        className="flex border-b-[1px] py-2 items-center justify-between"
      >
        <div className="flex w-full flex-col gap-2">
          <div className="text-sm">
            {item?.name}
            {/* {categories.find((value) => value.slug == category)?.name} */}
          </div>
          {/* <CircleX size={14} /> */}
          {/* <Separator /> */}
        </div>
        <div className="flex items-center gap-2">
          <CircleX onClick={() => handleRemoveItem(item.id)} size={16} />
          <div {...attributes} {...listeners}>
            <GripVertical size={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableItem;
