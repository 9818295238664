import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import axios from "axios";
const DuplicateEventModal = function ({ isModalOpen, setisModalOpen, event,companyslug, refetchEvent }) {
  const [newEventName, setNewEventName] = React.useState();
  const { toast } = useToast();

  const createNewEvent = function (e) {
    e.preventDefault();
    if (!newEventName) {
      toast({
        title: "Enter an event name",
        variant: "loading",
        duration: 2000,
      });
    }
    const data = {'newName':newEventName}
    toast({
        title: "Creating event...",
        variant: "loading",
        duration: 5000,
      });
    try {
        axios.post(
          `${
            import.meta.env.VITE_APP_API_URL
          }/v3/events/${companyslug}/${event.id}/duplicate/`,
          data,
        ).then((response)=>{
            toast({
                title: "Event Created",
                variant: "success",
                duration: 2000,
              });
            refetchEvent();
            setisModalOpen(false);
          return { data: response.data, isError: false, error: null }; 
        }
        );
      } catch (err) {
        return { data: null, isError: true, error: err };
      }
  };

  return (
    <div>
      <Dialog open={isModalOpen} onOpenChange={setisModalOpen}>
        <DialogContent
          className={
            "lg:max-w-md overflow-x-auto overflow-y-auto  rounded-md lg:max-h-[100vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Duplicate Event</DialogTitle>
          </DialogHeader>
          <form className="flex flex-col gap-4 justify-center align-middle">
            <span>Create Duplicate Itinerary for: </span>
            <div className="space-y-2 align-middle justify-center">
              <Label>{event.name}</Label>
            </div>
            <div className="space-y-2 align-middle justify-center">
              <Label>New Event Name</Label>
              <Input
                type="text"
                onChange={(e) => {
                  setNewEventName(e.target.value);
                }}
              />
            </div>
            <Button onClick={(e)=>{createNewEvent(e)}}>Create</Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DuplicateEventModal;
