import { useState, useEffect } from "react";
import axios from "axios";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

export default function TemplateMessageDialog ({ open, setOpen, company, conversation_id, mobile }) {
    const EVENTS_LIST_API = `${import.meta.env.VITE_APP_API_URL}/dashboard/${company}/events-name/`;
    const SEND_TEMPLATE_MESSAGE_API = `${import.meta.env.VITE_APP_API_URL}/api/send-template-message/`;
    const GET_EVENT_DETAILS_API = `${import.meta.env.VITE_APP_API_URL}/api/get-event-details`;
    const TEMPLATE_LIST_API_URL = `${import.meta.env.VITE_APP_API_URL}/api/get-template-list/${company}/${conversation_id}/`;

    const [eventList, setEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState({ slug: "", name: "" });
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedEventDetails, setSelectedEventDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasSendItinerary, setHasSendItinerary] = useState(null);
    const [messagePreview, setMessagePreview] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(EVENTS_LIST_API);
                const templateApiResponse = await axios.get(TEMPLATE_LIST_API_URL);
                if (templateApiResponse?.data) {
                    setHasSendItinerary(templateApiResponse.data.templates.find((obj) => obj.category === "send_itinerary")?.template_id);
                }
                if (response?.data) {
                    setEventList(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [company, conversation_id]);

    const fetchEventDetails = async (slug) => {
        try {
            const response = await axios.get(`${GET_EVENT_DETAILS_API}/${slug}/`);
            setSelectedEventDetails(response.data);
            setMessagePreview(`Hello, here is the itinerary for ${response.data?.name}: ${response.data?.min_price}`);
        } catch (error) {
            console.error("Error fetching event details:", error);
        }
    };

    const sendTemplateMessage = async () => {
        setLoading(true);
        if (!selectedEvent.slug) {
            setErrorMessage("Please select an event.");
            setLoading(false);
            return;
        }
        if (!hasSendItinerary) {
            setErrorMessage("Please contact Logout Support to get the template message approved.");
            setLoading(false);
            return;
        }
        try {
            await axios.post(SEND_TEMPLATE_MESSAGE_API, {
                event_details: { ...selectedEventDetails, slug: selectedEvent.slug, company },
                mobile: mobile,
                template_id: hasSendItinerary,
            });
            setOpen(false);
        } catch (error) {
            console.error("Error sending message:", error);
            setErrorMessage("Failed to send the message. Please try again.");
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Select Itinerary</DialogTitle>
                </DialogHeader>
                <div>
                    <Select 
                        className="w-full"
                        onValueChange={(value) => {
                            const event = eventList.find((e) => e.slug === value);
                            setSelectedEvent(event);
                            setErrorMessage("");
                            fetchEventDetails(value);
                    }}>
                        <SelectTrigger className="w-full max-w-lg">
                            <SelectValue className="w-full" placeholder="Select Event" />
                        </SelectTrigger>
                        <SelectContent>
                            {eventList.map((event, idx) => (
                                <SelectItem key={idx} value={event.slug}>{event.name}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {errorMessage && (
                        <Alert variant="destructive" className="mt-4">
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{errorMessage}</AlertDescription>
                        </Alert>
                    )}
                    {messagePreview && (
                        <Card className="mt-4">
                            <CardHeader>
                                <CardTitle>Message Preview</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p>{messagePreview}</p>
                            </CardContent>
                        </Card>
                    )}
                </div>
                <DialogFooter>
                    {hasSendItinerary && (
                        <Button onClick={sendTemplateMessage} disabled={loading}>
                            {loading ? "Sending..." : "Send Message"}
                        </Button>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
