"use client";

import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";

import { SquareArrowOutUpRight } from "lucide-react";
import { DataTableColumnHeader } from "./data-table-column-header";
import { format } from "date-fns";
import { useCompanyStore } from "@/store/companyStore";

export const transactionColumns = [
  {
    accessorKey: "id",
    size: 60,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="S.No." />
    ),
    cell: ({ row }) => (
      <div className="text-xs items-center flex flex-col gap-1">
        <span className="">{row.index + 1}</span>
        {/* <Badge variant="outline" className={"text-[0.6rem] rounded-md py-0 "}>
          {row.original.mode}
        </Badge> */}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "eventDetails",
    size: 150,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Event Details" />
    ),
    cell: ({ row }) => {
      const eventDetails = useCompanyStore.getState().companyData.events;
      const findEventImage = function (name) {
        const event = eventDetails?.data.find((event) => event.name === name);
        if(event){
          return `${import.meta.env.VITE_APP_API_URL}${event.image}`;
        }
        return null
      };
      var name = "";
      if (row.original.categories === "RAZORPAY_CUSTOM_LINK") {
        name = row.original?.custom_link?.event_name;
      } else {
        name = row.original?.booking?.event_name;
      }
      var transaction_timestamp = format(
        new Date(row.original.created_at),
        "d/MM/yyyy"
      );

      const event_pic = findEventImage(name);
      return (
        <div className="flex cursor-pointer gap-2 text-xs">
          {event_pic && <div>
            <img src={event_pic} className="w-8 h-8 rounded-md" />
          </div>}
          <div className="flex flex-col">
            <span className="max-w-36">{name}</span>
            <span className="flex items-center gap-1">
              {transaction_timestamp}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "bookingDetails",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Booking Details" />
    ),
    size: 140,
    enableSorting: false,
    cell: ({ row }) => {
      var bookedBy = "";
      var numberofpeople = "";
      var date = "";
      var contact = "";

      if (row.original.categories === "RAZORPAY_CUSTOM_LINK") {
        bookedBy = row.original.custom_link.customer_name;
        numberofpeople = "-";
      } else {
        bookedBy = row.original?.booking?.name;
        numberofpeople = row.original?.booking?.numberofpeople;
        contact = row.original?.booking?.contact;
        date = row?.original?.booking?.timestamp? format(new Date(row.original?.booking?.timestamp), "dd/MM/yyyy") : "-";
      }

      return (
        <div className="text-xs">
          <h2 className="block font-semibold">
            {bookedBy} | <span>{numberofpeople} Bookings</span>
          </h2>
          <div>
            <span>{date}</span>
          </div>
          <div>
            <span>{contact}</span>
          </div>
          {/* <h2>{email}</h2> */}
          {/* <Badge variant="outline" className={'text-[0.6rem] rounded-md mr-1 py-0'}>Bookings: 2</Badge> */}
          {/* <Badge variant="outline" className={'text-[0.6rem] rounded-md py-0'}>Source: Instagram</Badge> */}
          {/* <h2>For: 2 bookings, Source: Instagram</h2> */}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  // {
  //   accessorKey: "discountDetails",
  //   size: 80,
  //   enableSorting: false,
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Discount" />
  //   ),
  //   cell: ({ row }) => {
  //     const code = row.original.couponCode;
  //     const discount = row.original.discount;

  //     return (
  //       <div className=" text-xs">
  //         <h2 className="font-medium">{code}</h2>
  //         <h2>&#8377;{discount}</h2>
  //       </div>
  //     );
  //   },

  // },
  {
    accessorKey: "transactionAmount",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payment" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" text-xs">
          <span
            className={`${
              row.original.categories == "REFUND"
                ? "text-red-500"
                : "text-[#25d366]"
            } font-medium`}
          >
            {row.original.categories == "REFUND" ? "-" : "+"}{" "}
            {(row.original.amount / 100).toFixed(2)}
          </span>
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "type",
    size: 100,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    cell: ({ row }) => {
      var category = row.original.categories;
      if (category === "ONLINE_BOOKING") category = "Online Booking";
      else if (category === "MANUAL_BOOKING") category = "Manual Booking";
      else if (category === "REFUND") category = "Refund";
      else if (category === "RAZORPAY_PAYMENT_LINK") category = "Payment Link";
      else if (category === "RAZORPAY_CUSTOM_LINK") category = "Custom Link";
      else category = "Manual";

      return <div className="capitalize text-xs">{category}</div>;
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  // {
  //   accessorKey: "fees",
  //   size: 80,
  //   enableSorting: false,
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Fees" />
  //   ),
  //   cell: ({ row }) => {
  //     return (
  //       <div className=" text-xs">
  //         <span>&#8377;{row.getValue('fees')}</span>
  //       </div>
  //     )
  //   },

  // },
  // {
  //   accessorKey: "refunded",
  //   size: 80,
  //   enableSorting: false,
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Refund" />
  //   ),
  //   cell: ({ row }) => {
  //     return (
  //       <div className=" text-xs">
  //         <span>&#8377;{(row.original.booking?.refund/100 || 0).toFixed(2)}</span>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   accessorKey: "pending",
  //   size: 80,
  //   enableSorting: false,
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Pending" />
  //   ),
  //   cell: ({ row }) => {
  //     return (
  //       <div className=" text-xs">
  //         <span>&#8377;{row.getValue("pending")}</span>
  //       </div>
  //     );
  //   },
  //   // filterFn: (row, id, value) => {
  //   //   return value.includes(row.getValue(id))
  //   // },
  // },
  {
    accessorKey: "settlement",
    enableSorting: false,
    size: 80,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Settlement" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" text-xs">
          <span>&#8377;{row.getValue("settlement")}</span>
        </div>
      );
    },
  },
  // {
  //   accessorKey: "actionsButtons",
  //   size: 80,
  //   enableSorting: false,
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Actions" />
  //   ),
  //   cell: ({ row }) => {
  //     return (
  //       <div className=" text-xs">
  //         <DropdownMenu>
  //           <DropdownMenuTrigger>
  //             <Button>Actions</Button>
  //           </DropdownMenuTrigger>
  //           <DropdownMenuContent align="end">
  //             <DropdownMenuItem>Edit Details</DropdownMenuItem>
  //             <DropdownMenuItem>Preview Invoice</DropdownMenuItem>
  //             <DropdownMenuItem>Download Invoice</DropdownMenuItem>
  //             <DropdownMenuItem>
  //               Send Booking Confirmation Mail
  //             </DropdownMenuItem>
  //             <DropdownMenuItem>Send WhatsApp Confirmation</DropdownMenuItem>
  //             <DropdownMenuItem>Initiate Refund</DropdownMenuItem>
  //           </DropdownMenuContent>
  //         </DropdownMenu>
  //       </div>
  //     );
  //   },

  // },
];
