import React from "react";
import { Button } from "../ui/button";
import { ArrowDown } from "lucide-react";

const BannerPreview = ({ bannerStyles }) => {

  return (
    <div>
      <div
        className="h-auto relative overscroll-none overflow-y-auto preview_container w-[85vw] p-4 pb-0 md:w-[55vw] lg:w-[315px] mt-4 shadow-lg border-4 rounded-3xl"
      >
        
        <div className="absolute w-full left-0 shadow-[0px_-1px_5px_0px_rgba(0,0,0,0.25)] border-t-[1px] bottom-0 py-3 px-3 ">
        {!bannerStyles.showEverything && <div className="absolute w-full flex text-center items-center justify-center text-lg h-full text-white left-0 top-0 bg-black/60">
        Banner off.<br></br> You can use your own custom buttons.</div>}
          <div className="flex flex-col w-full gap-3">
            <div className="w-full flex items-center justify-between">
              <span
                className={`font-medium ${
                  bannerStyles.showOnlyButtons == 'True' ? "opacity-0" : "opacity-1"
                }`}
              >
                From &#8377;4,499
              </span>
              {bannerStyles.itineraryButtonStyling.visible && (
                <Button
                  style={{
                    background: bannerStyles.itineraryButtonStyling.bgColor,
                    color: bannerStyles.itineraryButtonStyling.textColor,
                  }}
                  className="w-[48%]"
                >
                  <ArrowDown size={16} />
                  {bannerStyles.itineraryButtonStyling.text}
                </Button>
              )}
              {bannerStyles.enquiryButtonStyling.visible &&
                !bannerStyles.itineraryButtonStyling.visible && (
                  <Button
                    style={{
                      background: bannerStyles.enquiryButtonStyling.bgColor,
                      color: bannerStyles.enquiryButtonStyling.textColor,
                    }}
                    className="w-[48%]"
                  >
                    {bannerStyles.enquiryButtonStyling.text}
                  </Button>
                )}
            </div>
            <div className="w-full flex items-center gap-2">
              {bannerStyles.enquiryButtonStyling.visible &&
                bannerStyles.itineraryButtonStyling.visible && (
                  <Button
                    style={{
                      background: bannerStyles.enquiryButtonStyling.bgColor,
                      color: bannerStyles.enquiryButtonStyling.textColor,
                    }}
                    className="w-full"
                  >
                    {bannerStyles.enquiryButtonStyling.text}
                  </Button>
                )}
              <Button
                style={{
                  background: bannerStyles.bookNowButtonStyling.bgColor,
                  color: bannerStyles.bookNowButtonStyling.textColor,
                }}
                className="w-full"
              >
                {bannerStyles.bookNowButtonStyling.text}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPreview;
