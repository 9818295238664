
export const getStagebyID = function(flowStage,freeStage,stage_id){
    try{
        var found_stage = flowStage.find((stage)=>stage.id.toString()=== stage_id.toString());
        if(!found_stage){
            found_stage = freeStage.find((stage)=>stage.id.toString()=== stage_id.toString());
        }
        if(found_stage)
            return found_stage
        else
            return null
    }
    catch(error){
        console.log(error)
        return null;
    }
}

