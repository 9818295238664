import * as React from "react";
import { editInstagramPost } from "@/apiService/auto-commentor";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "../ui/separator";
import { Switch } from "../ui/switch";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import { Badge } from "../ui/badge";
import { set } from "lodash";
import { format } from "date-fns";
import { useMutation } from "react-query";


const AutoCommentorList = ({post, eventList}) => {
const [events,setEvents] = React.useState([]);
const [queryParam,setQueryParam] = React.useState('')
const [postEditData,setPostEditData] = React.useState({})
const [postSettings,setPostSettings] = React.useState({})
const [selectedEvent,setSelectedEvent] = React.useState('')
const [buttonText,setButtonText] = React.useState('Save')

const mutation = useMutation(({ queryParam, editData }) => {
  return editInstagramPost(editData, queryParam);
});

const handleChange = async(value) => {
  console.log(value)
  setQueryParam(value)
  var editData = {}
  if(value=='postactive'){
    editData = {id: postSettings.id, postactive: !postSettings.is_active}
  }
  if(value=="postdetail"){
    editData = {id: postSettings.id, comment_reply_text: postSettings.comment_reply, dm_message: postSettings.dm_message, keyword: postSettings.keyword, comment_reply_active: postSettings.comment_reply_active}
  }
  
  mutation.mutate({queryParam:value,editData:editData})
  // await editInstagramPost(editData,value)
}

const handleEventChange = (value,id,selectedEvent) => {
  var formData = new FormData()
  if(value=="eventchange"){
    formData.append('id', id)
    formData.append('selectedEvent', selectedEvent)
    // editData = {id: id, selectedEvent: selectedEvent}
  }
  if(selectedEvent){
  mutation.mutate({queryParam:value,editData:formData})
  }
}

const handleSubmit = () => {
  handleChange("postdetail")
  setTimeout(() => {
    setIsFlipped(false)
  },1000)
}
 
  React.useEffect(() => {
    var options = eventList?.map((event) => {
      return {
        value: event.id.toString(),
        label: event.name,
      };
    })
    options.unshift({value: 'Select Event', label: 'Select Event'})
    setEvents(options)
  }, [eventList]);

  const [isFlipped, setIsFlipped] = React.useState(false);

React.useEffect(() => {
  // setIsCommentReplyOn(post.comment_reply_active)
  setPostSettings(post)
  setSelectedEvent(post.event_id)
},[post])



React.useEffect(() => {
  if(mutation.isSuccess){
    setButtonText("Saved")
    setTimeout(() => {
      setButtonText("Save")
    }, 1000)
  }
  if(mutation.isLoading){
    setButtonText(<div className="dots"></div>)
  }
  if(mutation.isError){
    setButtonText('Save')
  }
},[mutation.isSuccess,mutation.isLoading,mutation.isError])

  return (
    <div className={`card_flip ${isFlipped ? "flipped" : ""}`}>
      <Card className="w-auto card__content relative transition-transform duration-1000">
        <div class="card__front pb-2">
          <CardHeader className="p-2 pb-4">
            <div className="w-auto aspect-square relative overflow-hidden rounded-md">
              {post?.thumbnail_url ? <img
                src={post.thumbnail_url}
                alt="thumbnail"
                className="absolute top-0 left-0 w-full h-full object-contain"
              /> : <img
              src={post.media_url}
              alt="thumbnail"
              className="absolute top-0 left-0 w-full h-full object-contain"
            />}
              {post.last_edited && <div>
                <Badge className="absolute top-2 right-2 " size="lg">
                    <span className="text-xs font-normal mr-1">Last Edit: </span> {format(new Date(post?.last_edited), "dd MMM, yyyy")}
                </Badge>
              </div>}
            </div>
          </CardHeader>
          <CardContent className="px-4 py-0 flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="flex flex-col items-center">
                <span className="font-semibold">{post.comments_count}</span>
                <span className="text-xs">Comments</span>
              </div>
              <Separator orientation="vertical" className="my-2 h-6" />
              <div className="flex flex-col items-center">
                <span className="font-semibold">{post.dm_count}</span>
                <span className="text-xs">Replied</span>
              </div>
              <Separator orientation="vertical" className="my-2 h-6" />
              <div className="flex flex-col items-center">
                <Switch onCheckedChange={(value)=>{setPostSettings({...postSettings, is_active: value});handleChange("postactive")}} checked={postSettings.is_active} id="auto-reply" className="h-5 mb-1" />
                <span className="text-xs">Auto Reply</span>
              </div>
            </div>
            <div>
              {events?.length>0 && <ComboboxDemo fontSize={'text-xs'} defaultValue={postSettings?.selectedevent ? postSettings?.selectedevent?.toString(): 'Select Event'} onChange={(value)=>{setPostSettings({...postSettings, selectedevent: value}); handleEventChange("eventchange",postSettings.id,value)}} options={events} />}
            </div>
            <div onClick={()=>{setIsFlipped(!isFlipped)}} className="max-w-64 truncate cursor-pointer">
              <span className="text-sm">
                Comment:{" "}
                <span className="font-semibold">{post.comment_reply}</span>
              </span>
            </div>
            <div onClick={() => setIsFlipped(!isFlipped)}>
              <Button className="w-full h-8">View Details</Button>
            </div>
          </CardContent>
        </div>
        <div class="card__back bg-white rounded-md absolute top-0 left-0 h-full w-full px-4 pt-4 text-primary flex flex-col gap-4">
          <div className="grid w-full gap-1.5">
            <Label htmlFor="message">DM Reply</Label>
            <Textarea onChange={(e)=>{setPostSettings({...postSettings,dm_message: e.target.value})}} defaultValue={post.dm_message}  placeholder="Send a short message to engage with your audience..." id="message" />
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">Reply to Comments</span>
            <Switch checked={postSettings.comment_reply_active} onCheckedChange={(value)=>{setPostSettings({...postSettings,comment_reply_active: value})}} id="reply-comments" className="h-5" />
          </div>
          {postSettings.comment_reply_active && <div className="grid w-full gap-1.5">
            <Label htmlFor="message">Comment Reply</Label>
            <Textarea onChange={(e)=>{setPostSettings({...postSettings,comment_reply: e.target.value})}} defaultValue={post.comment_reply}  placeholder="Write a thoughtful reply" id="message" />
          </div>}
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="keywords">Keywords</Label>
            <Input type="text" onChange={(e)=>{setPostSettings({...postSettings,keyword: e.target.value})}} defaultValue={post.keyword} id="keywords" placeholder="Comma separated keywords" />
          </div>
          <Button onClick={()=>{setIsFlipped(!isFlipped)}} variant="outline" className="w-full h-8">Cancel</Button>  
          <Button onClick={()=>{handleSubmit()}} className="w-full h-8">
            {buttonText}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export { AutoCommentorList };
