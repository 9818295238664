import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "../ui/label";
import { DateTimePicker } from "../Custom/DateTimePicker";
import { Separator } from "../ui/separator";
import { Input } from "../ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "../ui/checkbox";
import {
  CirclePlus,
  EllipsisVertical,
  Info,
  TicketPlus,
  Trash,
} from "lucide-react";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { Plus } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Ticket } from "lucide-react";
import { MultiSelect } from "../ui/multi-select";
import { Textarea } from "../ui/textarea";
import { DatePicker } from "../Custom/DatePicker";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import useEventStore from "@/store/eventStore";
import axios from "axios";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { add } from "lodash";
import { format } from "date-fns";
import { useToast } from "@/hooks/use-toast";

const options = [
  { value: "event1", label: "Event 1 Event 1 Event 1 Event 1 Event 1" },
  { value: "event2", label: "Event 2" },
  { value: "event3", label: "Event 3 Event 3 Event 3 Event 3 Event 3" },
  { value: "event4", label: "Event 4" },
];

const MultipleBatches = ({eventSlug, setIsOpen, handleBatchCreation}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddonDropdownOpen, setIsAddonDropdownOpen] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = React.useState("everyday");
  const [buttonText,setButtonText] = useState("Update")
  const [batches, setBatches] = useState({
    price: "",
    eventbatchprice: [],
    addonbatchprice: [],
  });
  const [loading, setLoading] = useState(false)

  const {toast} = useToast()

  const [selectedDays, setSelectedDays] = useState([
    "Mon",
    "Tues",
    "Wed",
    "Thur",
    "Fri",
    "Sat",
    "Sun",
  ]);

  
  function replaceEmptyStringsWithNull(data) {
    if (Array.isArray(data)) {
      return data.map(item => replaceEmptyStringsWithNull(item));
    } else if (data !== null && typeof data === "object") {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === "" ? null : replaceEmptyStringsWithNull(value)
        ])
      );
    }
    return data;
  }

  const days = ["Mon", "Tues", "Wed", "Thur", "Fri", "Sat", "Sun"];
  const weekdays = ["Mon", "Tues", "Wed", "Thur", "Fri"];
  const weekend = ["Sat", "Sun"];

  const [repeats, setRepeats] = React.useState(false);
  const repeatOptions = [
    { value: "everyday", label: "Everyday" },
    { value: "everyweek", label: "Every Week" },
  ];

  const handleSelection = (type) => {
    if (type === "all") {
      setSelectedDays([...days]);
    } else if (type === "weekdays") {
      setSelectedDays([...weekdays]);
    } else if (type === "weekend") {
      setSelectedDays([...weekend]);
    }
  };

  const toggleDay = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const updateBatchField = (field, value) => {
    setBatches((prevBatches) => {
      prevBatches = {
        ...prevBatches,
        [field]: value, // Dynamically update the specified field
      };
      return prevBatches;
    });
  };

  useEffect(() => {
    console.log(batches);
  }, [batches]);

  const addTicket = () => {
    console.log("Adding ticket");

    // Create a new copy of the batches object
    const updatedBatches = {
      ...batches,
      eventbatchprice: [
        ...batches.eventbatchprice,
        {
          attribute: "",
          price: "",
          inventory: "",
          is_eligible_for_coupon_discount: false,
          soldOut: false,
        },
      ],
    };

    // Update state with the new object
    setBatches(updatedBatches);
  };

  const addAddon = () => {
    const updatedBatches = {
      ...batches,
      addonbatchprice: [
        ...batches.addonbatchprice,
        {
          attribute: "",
          price: "",
        },
      ],
    };

    // Update state with the new object
    setBatches(updatedBatches);
  };

  const updateTicket = (ticketIndex, key, value) => {
    setBatches((prevBatches) => {
      // Make a shallow copy of the previous batches object
      const updatedBatches = { ...prevBatches };

      // Update the specific ticket in the batch
      updatedBatches.eventbatchprice[ticketIndex] = {
        ...updatedBatches.eventbatchprice[ticketIndex],
        [key]: value,
      };

      return updatedBatches;
    });
  };

  const updateAddon = (addonIndex, key, value) => {
    setBatches((prevBatches) => {
      // Make a shallow copy of the previous batches object
      const updatedBatches = { ...prevBatches };

      // Update the specific ticket in the batch
      updatedBatches.addonbatchprice[addonIndex] = {
        ...updatedBatches.addonbatchprice[addonIndex],
        [key]: value,
      };

      return updatedBatches;
    });
  };

  const removeTicket = (ticketIndex) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].eventbatchprice = updatedBatches[
        batchIndex
      ].eventbatchprice.filter((_, i) => i !== ticketIndex);
      return updatedBatches;
    });
  };

  const removeAddon = (addonIndex) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].addonbatchprice = updatedBatches[
        batchIndex
      ].addonbatchprice.filter((_, i) => i !== addonIndex);
      return updatedBatches;
    });
  };

  const addMultipleBatches = async () => {
    setButtonText(<div className="dots"></div>);
    const updated = replaceEmptyStringsWithNull(batches);
    if(repeats){

        updated.repeats = repeats;
        updated.repeatType = repeatFrequency;
        updated.repeatedDays = selectedDays;
        updated.changedEndTime = true;
        updated.numberOfDays = null;
    }
    // console.log("Batch Data:", tickets);
    setLoading(true)
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/add-bulk-batches/${eventSlug}/`,
        { batchData: updated }
      );
      console.log("Response:", response);
      if (response.status == 200) {
        toast({
          title: "Batches Created",
          variant: "success",
          duration: 2000,
        });
        await handleBatchCreation();
        setButtonText("Updated");
        setIsOpen(false)
        setTimeout(() => {
          setButtonText("Update");
        }, 3000);
      }
      // setLoading(false)
      console.log("API Response:", response.data);
    } catch (error) {
      toast({
        title: error?.message || "Error creating batch",
        variant: "error",
        duration: 2000,
      });
      console.error("Error updating item status:", error);
    }
    setLoading(false)
  };

  return (
    <div>
      <div className="py-4 flex flex-col gap-4">
        <div className="timing_section grid grid-cols-1 gap-y-2">
          <div className="flex justify-between">
            <Label htmlFor="startdate_multiple">Starts On</Label>
            <div className="max-w-sm">
              <DateTimePicker
                onUpdate={(value) => {
                  updateBatchField("startdate", format(value, "yyyy-MM-dd"));
                  updateBatchField("starttime", format(value, "HH:mm"));
                }}
                id="startdate_multiple"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <Label htmlFor="enddate_multiple">Ends On</Label>
            <div className="max-w-sm">
              <DateTimePicker
                onUpdate={(value) => {
                  updateBatchField("enddate", format(value, "yyyy-MM-dd"));
                  updateBatchField("endtime", format(value, "HH:mm"));
                }}
                id="enddate_multiple"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <Label htmlFor="nickname_multiple">Nickname</Label>
            <Input
              onChange={(e) => {
                updateBatchField("vehicle_type", e.target.value);
              }}
              className="max-w-[13.5rem]"
              id="nickname_multiple"
              type="text"
            />
          </div>
          <div className="flex justify-between">
            <Label htmlFor="nickname_multiple">Repeats</Label>
            <div className="w-[13.25rem]">
              <div className="flex items-center gap-1">
                <Switch
                  defaultValue={repeats}
                  onCheckedChange={setRepeats}
                  className="h-5"
                  id="repeats"
                />
                <Select
                  onValueChange={(value) => setRepeatFrequency(value)}
                  className={`${
                    repeats ? "opacity-1" : "opacity-0 pointer-events-none"
                  }`}
                >
                  <SelectTrigger className={`${
                    repeats ? "opacity-1" : "opacity-0 pointer-events-none"
                  }`}>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    {repeatOptions.map((option, index) => (
                      <SelectItem key={index} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {repeats && <div className="mt-2">
                <Label htmlFor="tilldate">Till Date</Label>
                <DatePicker
                  onChange={(value) => {
                    updateBatchField("tillDate", format(value, "yyyy-MM-dd"));
                  }}
                  id="tilldate"
                />
              </div>}

              {repeatFrequency == "everyweek" && (
                <div className="space-y-2 py-2">
                  <div className="flex gap-2 text-sm">
                    <span
                      onClick={() => handleSelection("all")}
                      className="underline cursor-pointer focus:outline-none"
                    >
                      All
                    </span>
                    <span
                      onClick={() => handleSelection("weekdays")}
                      className="underline cursor-pointer focus:outline-none"
                    >
                      Weekdays
                    </span>
                    <span
                      onClick={() => handleSelection("weekend")}
                      className="underline cursor-pointer focus:outline-none"
                    >
                      Weekend
                    </span>
                  </div>

                  <div className="flex gap-1">
                    {days.map((day) => (
                      <div
                        key={day}
                        className={`w-6 text-xs h-[1.5rem] flex items-center justify-center rounded-full cursor-pointer ${
                          selectedDays.includes(day)
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => toggleDay(day)}
                      >
                        {day[0]}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="tickets_section">
            <Accordion className="mb-4" type="single" collapsible>
              <AccordionItem value="tickets">
                <AccordionTrigger className="pb-1 pt-0">
                  <div className="flex items-center gap-1">
                    <Ticket size={16} />
                    Tickets
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  {batches.eventbatchprice.map((ticket, ticketIndex) => (
                    <>
                      <div className="flex pt-2 flex-col px-1 w-full gap-4">
                        <div className="grid grid-cols-12 items-end gap-x-3">
                          <div className="flex col-span-6 flex-col gap-1">
                            <Label
                              className="flex items-end justify-between w-full"
                              htmlFor="event_name"
                            >
                              Name
                            </Label>
                            <Input
                              onChange={(e) =>
                                updateTicket(
                                  ticketIndex,
                                  "attribute",
                                  e.target.value
                                )
                              }
                              id={`name-${ticket.id}`}
                              type="text"
                              value={ticket.attribute}
                            />
                          </div>
                          <div className="flex col-span-3 flex-col gap-1">
                            <Label htmlFor="event_value">Price</Label>
                            <Input
                              onChange={(e) =>
                                updateTicket(
                                  ticketIndex,
                                  "price",
                                  e.target.value
                                )
                              }
                              id={`price-${ticket.id}`}
                              type="number"
                              value={ticket.price}
                            />
                          </div>
                          <div className="flex col-span-2 flex-col gap-1">
                            <Label htmlFor="event_inventory">Qty</Label>
                            <Input
                              onChange={(e) =>
                                updateTicket(
                                  ticketIndex,
                                  "inventory",
                                  e.target.value
                                )
                              }
                              id={`inventory-${ticket.id}`}
                              type="number"
                              value={ticket.inventory}
                            />
                          </div>
                          {/* <DropdownMenu
                                        className="col-span-1"
                                        open={isDropdownOpen}
                                        onOpenChange={setIsDropdownOpen}
                                      >
                                        <DropdownMenuTrigger>
                                          <Button
                                            onClick={() => setIsDropdownOpen(true)}
                                            className="w-full px-1"
                                            variant="outline"
                                            
                                          >
                                            <EllipsisVertical size={16} />
                                          </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                          className="w-48"
                                          onInteractOutside={() =>
                                            setIsDropdownOpen(false)
                                          }
                                        >
                                          <DropdownMenuItem
                                            onSelect={() => setIsDropdownOpen(true)}
                                          >
                                            <div className="flex justify-between items-center w-full">
                                              <span className="text-xs">
                                                Coupon Applicable
                                              </span>
                                              <Switch className="h-5" id="Sale" />
                                            </div>
                                          </DropdownMenuItem>
                                          <DropdownMenuItem>
                                            <div className="flex justify-between items-center w-full">
                                              <span className="text-xs">
                                                Ticket Soldout
                                              </span>
                                              <Switch
                                                className="h-5"
                                                id="batchconfirm"
                                              />
                                            </div>
                                          </DropdownMenuItem>
                                        </DropdownMenuContent>
                                      </DropdownMenu> */}
                          <Popover
                            open={isDropdownOpen}
                            onOpenChange={setIsDropdownOpen}
                          >
                            <PopoverTrigger asChild>
                              <Button
                                onClick={() => setIsDropdownOpen(true)}
                                className="w-full px-2"
                                variant="outline"
                              >
                                <EllipsisVertical size={16} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-48"
                              align="end"
                              onInteractOutside={() => setIsDropdownOpen(false)}
                            >
                              <div className="flex flex-col space-y-2">
                                <div className="flex justify-between items-center w-full">
                                  <span className="text-xs">
                                    Coupon Applicable
                                  </span>
                                  <Switch
                                    defaultChecked={
                                      ticket.is_eligible_for_coupon_discount
                                    }
                                    onCheckedChange={(value) =>
                                      updateTicket(
                                        ticketIndex,
                                        "is_eligible_for_coupon_discount",
                                        value
                                      )
                                    }
                                    className="h-5"
                                    id="Sale"
                                  />
                                </div>
                                <div className="flex justify-between items-center w-full">
                                  <span className="text-xs">
                                    Ticket Soldout
                                  </span>
                                  <Switch
                                    defaultChecked={ticket.soldout}
                                    onCheckedChange={(value) =>
                                      updateTicket(
                                        ticketIndex,
                                        "soldout",
                                        value
                                      )
                                    }
                                    className="h-5"
                                    id="batchconfirm"
                                  />
                                </div>
                              </div>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </div>
                      <div className="flex gap-2 justify-end items-center">
                        {ticketIndex == batches.eventbatchprice.length - 1 && (
                          <div
                            onClick={() => addTicket()}
                            className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                          >
                            <CirclePlus size={16} />
                            <span className="underline">Add another</span>
                          </div>
                        )}
                        <div
                          onClick={() => {
                            removeTicket(ticketIndex)
                            //   handleRemoveTicket(index, ticketIndex);
                          }}
                          className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                        >
                          <Trash size={16} />
                          <span className="underline">Remove</span>
                        </div>
                      </div>
                    </>
                  ))}
                  {(batches.eventbatchprice == null ||
                    batches.eventbatchprice.length == 0) && (
                    <div className="flex gap-2 justify-end items-center">
                      <div
                        onClick={() => addTicket()}
                        className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                      >
                        <CirclePlus size={16} />
                        <span className="underline">Add Prices</span>
                      </div>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="addons_section">
            <Accordion className="mb-4" type="single" collapsible>
              <AccordionItem value="addons">
                <AccordionTrigger className="pb-1 pt-0">
                  <div className="flex items-center gap-1">
                    <TicketPlus size={16} />
                    Addons
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  {batches.addonbatchprice?.map((addon, addonIndex) => (
                    <>
                      <div className="flex pt-2 px-1 flex-col w-full gap-4">
                        <div className="grid grid-cols-12 items-end gap-x-3">
                          <div className="flex col-span-8 flex-col gap-1">
                            <Label
                              className="flex items-end justify-between w-full"
                              htmlFor="attribute"
                            >
                              Name
                            </Label>
                            <Input
                              id="attribute"
                              type="text"
                              value={addon.attribute}
                              onChange={(e) =>
                                updateAddon(
                                  addonIndex,
                                  "attribute",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="flex col-span-3 flex-col gap-1">
                            <Label htmlFor="price">Price</Label>
                            <Input
                              id="price"
                              type="number"
                              value={addon.price}
                              onChange={(e) =>
                                updateAddon(addonIndex, "price", e.target.value)
                              }
                            />
                          </div>

                          <DropdownMenu
                            className="col-span-1"
                            open={isAddonDropdownOpen}
                            onOpenChange={setIsAddonDropdownOpen}
                          >
                            <DropdownMenuTrigger>
                              <Button
                                onClick={() => setIsAddonDropdownOpen(true)}
                                className="w-full px-1"
                                variant="outline"
                              >
                                <EllipsisVertical size={16} />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                              onInteractOutside={() =>
                                setIsAddonDropdownOpen(false)
                              }
                            >
                              <DropdownMenuItem className="text-xs">
                                Remove
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </div>
                      <div className="flex gap-2 justify-end items-center">
                        {addonIndex == batches.addonbatchprice.length - 1 && (
                          <div
                            className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                            onClick={() => addAddon()}
                          >
                            <CirclePlus size={16} />
                            <span className="underline">Add another</span>
                          </div>
                        )}
                        <div
                          className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                          onClick={() => removeAddon(addonIndex)}
                          // onClick={() => handleRemoveAddon(index, addonIndex)}
                        >
                          <Trash size={16} />
                          <span className="underline">Remove</span>
                        </div>
                      </div>
                    </>
                  ))}
                  {(batches.addonbatchprice == null ||
                    batches.addonbatchprice.length == 0) && (
                    <div className="flex gap-2 justify-end items-center">
                      <div
                        onClick={() => addAddon()}
                        className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                      >
                        <CirclePlus size={16} />
                        <span className="underline">Add Addons</span>
                      </div>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="more_options">
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="pb-0 pt-0">
                  More Options
                </AccordionTrigger>
                <AccordionContent className="px-1">
                  {/* <div className="pt-4">
                    <div className="flex justify-between">
                      <Label className="">Who will go</Label>
                      <MultiSelect className="max-w-[13.5rem]" options={options} />
                    </div>
                  </div> */}
                  <div className="py-2">
                    <div className="py-2">
                      <div className="flex justify-between">
                        <Label className="flex" htmlFor="whatsapp_group_link">
                          WhatsApp Group Link
                        </Label>
                        <Input
                          className="max-w-[13.5rem]"
                          id="whatsapp_group_link"
                          type="text"
                          value={batches.whatsapp_group_link}
                          onChange={(e) => {
                            updateBatchField(
                              "whatsapp_group_link",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      <div className="flex justify-between">
                        <Label className="flex" htmlFor="google_drive_link">
                          Google Drive Link
                        </Label>
                        <Input
                          value={batches.google_drive_link}
                          className="max-w-[13.5rem]"
                          id="google_drive_link"
                          type="text"
                          onChange={(e) => {
                            updateBatchField(
                              "google_drive_link",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                   
                    <div className="flex flex-col gap-1">
                      <Label
                        className="flex items-end justify-between w-full"
                        htmlFor="comment"
                      >
                        Comment
                      </Label>
                      <Textarea
                        value={batches.comment}
                        id="comment"
                        onChange={(e) =>
                          updateBatchField("comment", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="flex justify-end mt-2">
            <Button className="w-24" onClick={addMultipleBatches}>{loading ? <div className="dots"></div> : 'Update'}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleBatches;
