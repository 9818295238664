// generate-paymnet-link.jsx/ui
import React, { useState, useReducer } from "react";
import { Link } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import AnimationPage from "./ui/AnimatioPage";
import { CreditCard } from "lucide-react";

import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import CopyLink from "./events/copy-button";

const GeneratePaymentLink = ({ open, setOpen }) => {
  const { companyslug } = useAuthStore();
  const [isLinkAvailable, setIsLinkAvailable] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [event, setEvent] = useState("");

  const [linkData, setLinkData] = useState(null);

  const postRequest = async (url, requestData) => {
    try {
      const response = await axios.post(url, requestData);
      return { linkdata: response.data, isError: false, error: null };
    } catch (err) {
      return { linkdata: null, isError: true, error: err };
    }
  };

  const formReducer = (state, action) => ({
    ...state,
    [action.name]: action.value,
  });

  const [formData, dispatch] = useReducer(formReducer, {
    bearer: "self",
  });

  const { data: eventsData } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/events/${companyslug}/get-all-events`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
      },
    }
  );

  const handleChange = (field, value) => {
    dispatch({ name: field, value: value });
  };

  const submitHandler = function (event) {
    event.preventDefault();
    const postdata = JSON.stringify(formData);
    (async () => {
      const { linkdata, isError, error } = await postRequest(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/generate-custom-razorpay-link/${companyslug}/`,
        postdata
      );
      console.log(linkdata);
      if (linkdata) {
        setIsLinkAvailable(true);
        setLinkData(linkdata);
      }
      console.log(linkdata); // { data: ..., isError: false, error: null }
    })();
  };

  return (
    <div className="flex flex-col w-full items-center relative">
      <Card className="w-full p-4 flex flex-col justify-center items-center">
        <Dialog
          open={open}
          onOpenChange={() => {
            setOpen(false);
          }}
        >
          {isLinkAvailable ? (
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Generate Payment Link</DialogTitle>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                <div></div>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-sm">Name</span>
                    <span>{customerName}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-sm">
                      Amount
                    </span>
                    <span>{linkData.amount}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-muted-foreground text-sm">Event</span>
                    <span>
                      {
                        eventsData.find((event) => event.id == formData.event)
                          .name
                      }
                    </span>
                  </div>
                </div>
                <div>
                  <Label>Generated Link</Label>
                  <div className="flex items-center gap-2">
                  <Input value={linkData.paymentlink} readOnly />
                  <CopyLink classes={'pb-0'} code={linkData.paymentlink} />
                  </div>
                </div>
              </div>
              {/* Generated Link
              Link: {linkData?.paymentlink}
              amount: {linkData?.amount} */}
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Generate Payment Link</DialogTitle>
              </DialogHeader>
              <form onSubmit={submitHandler}>
                <div className="grid gap-4 py-4">
                  <div className="space-y-2">
                    <Label htmlFor="event">Event</Label>
                    <Select
                      onValueChange={(value) => {
                        handleChange("event", value);
                      }}
                      required={true}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select an event" />
                      </SelectTrigger>
                      <SelectContent>
                        {eventsData?.map((event) => (
                          <SelectItem
                            key={event.id}
                            value={event.id.toString()}
                          >
                            {event.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <Label>Gateway Charges</Label>
                    <RadioGroup
                      defaultValue="self"
                      className="flex gap-4"
                      onValueChange={(value) => handleChange("bearer", value)}
                      required={true}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="customer"
                          id="collect_customer"
                        />
                        <Label htmlFor="collect_customer">
                          Collect from customer
                        </Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="self" id="pay_self" />
                        <Label htmlFor="pay_self">Pay self</Label>
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="customername">Customer Name</Label>
                    <Input
                      id="customer_name"
                      placeholder="Enter customer name"
                      onChange={(e) => {
                        handleChange("name", e.target.value);
                        setCustomerName(e.target.value);
                      }}
                      required={true}
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="amount">Amount</Label>
                    <Input
                      id="amount"
                      type="number"
                      placeholder="Enter amount"
                      onChange={(e) => handleChange("amount", e.target.value)}
                      required={true}
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="remark">Remark (Optional)</Label>
                    <Input
                      id="remark"
                      placeholder="Enter remark"
                      onChange={(e) => handleChange("remark", e.target.value)}
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  className="w-full text-white"
                >
                  Generate Link
                </Button>
              </form>
            </DialogContent>
          )}
        </Dialog>
      </Card>
    </div>
  );
};

export default GeneratePaymentLink;
