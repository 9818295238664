import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "@/components/ui/data-table";
import { leadColumns } from "@/components/leads/leadColumns";
import { MailList } from "@/components/leads/mail-list";
import { Card } from "@/components/ui/card";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";
import { DataTableToolbar } from "@/components/ui/data-table-toolbar";
import AllLeadsFilters from "@/components/Custom/AllLeadsFilters";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import useLeadsStore from "@/store/leadsStore";
import axios from "axios";
import { Skeleton } from "@/components/ui/skeleton";
import { useCallback } from "react";
import { debounce } from "lodash";
import LeadsFilter from "@/components/Custom/LeadsQuickFilter";
import DownloadLeadsModal from "@/components/leads/download-leads-modal";

const LeadPage = () => {
  const { companyslug } = useAuthStore();
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [isDownloadLeadOpen, setIsDownloadLeadOpen] = useState(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    setTeamMates,
    setEnquiries,
    enquiries,
    updateEnquiryField,
    setRefetchApi,
  } = useLeadsStore();
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [leads, setLeads] = React.useState([]);
  const [queryParams, setQueryParams] = React.useState({
    assigned_to: "",
    created_at_from_date: "",
    created_at_to_date: "",
    destination_location: "",
    event_slug: "",
    funnel_stage: "",
    is_expecting_call_back: "",
    is_mobile: "",
    modified_at_from_date: "",
    modified_at_to_date: "",
    ordering: "",
    source: "",
  });
  const [quickFilters, setQuickFilters] = useState([]);
  const [apiQuery, setApiQuery] = React.useState("");
  const loadedMoreRef = useRef(false);

  const { data, error, isLoading, isFetching, refetch } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/company-enquiry/${companyslug}/?limit=${
      pagination.pageSize
    }&offset=${offset}&q=${apiQuery} `,
    { ...quickFilters, ...queryParams },
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: false,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    console.log("mounted");
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [quickFilters]);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    console.log(status);
    setQuickFilters((prev) => {
      return { ...prev, status: status };
    });
  };

  const updateEnquiry = async (id, data) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/edit-enquiry-key-value/${id}/`,
        data
      );
      // refetch();
    } catch (error) {
      console.error(
        "Error Updating Enquiry:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const debouncedSearch = useCallback(
    debounce((newSearch) => {
      setQuickFilters((prev) => {
        return { ...prev, q: newSearch };
      });
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  // React.useEffect(() => {
  //   setPagination({
  //     pageIndex: 0,
  //     pageSize: pagination.pageSize,
  //   });
  //   setOffset(0);
  // }, [queryParams, apiQuery]);

  React.useEffect(() => {
    const { pageIndex, pageSize } = pagination;
    setOffset(pageIndex * pageSize);
    setTimeout(() => {
      refetch();
    }, 0);
  }, [pagination]);

  React.useEffect(() => {
    const lead_id = updateEnquiryField?.id;
    if (lead_id) updateEnquiry(lead_id, updateEnquiryField);
  }, [updateEnquiryField]);

  React.useEffect(() => {
    const getTeamMates = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/v3/company-team/${companyslug}/`
        );
        const apiResponse = [];
        response.data?.team_mates?.map((person) =>
          apiResponse.push({
            value: person.peopleid,
            label: person.username,
            image: person.photo,
          })
        );
        setTeamMates(apiResponse);
      } catch (error) {
        console.error("Error fetching team members:");
      }
    };
    getTeamMates();
  }, []);

  useEffect(() => {
    if (data?.results) {
      setLeads(data.results);
      setRefetchApi(refetch);
      setTotalRecords(data?.count);
    }
    if (data?.next) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [data]);

  const updateQueryParams = (key, value) => {
    setQueryParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleLoadMore = () => {
    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize + 20,
    });
    loadedMoreRef.current = true;
  };

  const tableData = React.useMemo(
    () => (isFetching ? Array(10).fill({}) : leads || []),
    [isFetching, leads]
  );

  const tableColumns = React.useMemo(
    () =>
      isFetching
        ? (leadColumns || []).map((column) => ({
            ...column,
            cell: <Skeleton className={"w-20 h-4"} />,
          }))
        : leadColumns || [],
    [isFetching, leadColumns]
  );

  const [columnVisibility, setColumnVisibility] = React.useState({
    id: true,
    eventDetails: true,
    bookingDetails: true,
    transactionAmount: true,
    refunded: true,
    pending: true,
    fees: false,
    settlement: false,
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    pageCount: Math.ceil(totalRecords / pagination.pageSize),
    manualPagination: true, // Enable server-side pagination
    onPaginationChange: setPagination,
    getRowId: (originalRow) => originalRow.id,
  });

  return (
    <div className="pb-4">
      <div className="flex items-center gap-2">
        {/* <div className="section_heading_image">
          <img
            src="/leads_photo.jpg"
            alt="leads"
            className="w-full object-cover h-full absolute top-0 left-0"
          />
        </div> */}
        <h2 className="mb-0 section_heading">Channel Leads</h2>
      </div>
      <div className="flex my-4 gap-2 items-center">
        <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
          {/* <form> */}
            <div className="relative">
              <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search"
                className="pl-8 h-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                onChange={(e) => handleSearchChange(e)}
              />
            </div>
          {/* </form> */}
        </div>
        <LeadsFilter
          selectedRows={rowSelection}
          setIsDownloadLeadOpen={setIsDownloadLeadOpen}
        />
        <AllLeadsFilters
          apiQuery={queryParams}
          setApiQuery={updateQueryParams}
          setQueryParams={setQueryParams}
          refetch={refetch}
          setPagination={setPagination}
        />

        <DataTableToolbar
          table={table}
          filterOptions={["Hot", "Warm", "Cold"]}
        />
      </div>
      <div className="flex pb-4 justify-between items-center w-full">
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => {
              setSelectedStatus("all");
              setQuickFilters((prev) => {
                return { ...prev, status: "" };
              });
            }}
            variant={`${selectedStatus == "all" ? "default" : "outline"}`}
            className={"rounded-md"}
          >
            All
          </Button>
          {["Hot", "Warm", "Cold"].map((option, idx) => (
            <Button
              onClick={() => {
                handleStatusChange(option.toLowerCase());
              }}
              key={idx}
              variant={`${
                selectedStatus == option.toLowerCase() ? "default" : "outline"
              }`}
              className={"rounded-md"}
            >
              {option}
            </Button>
          ))}
        </div>
      </div>

      <DownloadLeadsModal
        isDownloadLeadOpen={isDownloadLeadOpen}
        setisDownloadLeadOpen={setIsDownloadLeadOpen}
      />

      <div className="md:hidden block">
        {isFetching &&
          Array(10)
            .fill({})
            .map((task, index) => {
              return (
                <div key={index}>
                  <div className="flex flex-col gap-1">
                    <Card className="py-2 relative rounded-none border-t-0 border-l-0 shadow-none border-r-0 border-b-[1px]">
                      <div className="flex justify-between items-start">
                        <div className="flex items-start">
                          <div className="flex items-start">
                            {/* <div className="flex flex-col gap-1">
                                        <Skeleton className="w-12 h-12 rounded-md" />
                                        <Skeleton className={"w-12 h-4"} />
                                      </div> */}
                          </div>
                          <div className="flex flex-col gap-1">
                            <Skeleton className="w-32 h-4" />
                            <Skeleton className="w-24 h-4" />
                            <Skeleton className="w-16 h-4" />
                          </div>
                        </div>
                        <div className="flex h-16 flex-col justify-between">
                          <Skeleton className="w-16 h-4" />
                          <Skeleton className="w-16 h-4" />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              );
            })}
        {leads?.length>0 && (
          <MailList
            items={leads}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        )}
        <div className="flex justify-center">
          <Button className="my-4 w-28 mx-auto" onClick={handleLoadMore}>
            {isFetching && !isLoading ? <div className="dots"></div> : "Load More"}
          </Button>
        </div>
      </div>
      <div className="md:block hidden">
        <DataTable
          heading={"leads"}
          table={table}
          columns={leadColumns}
          data={leads}
        />
      </div>
    </div>
  );
};

export default LeadPage;
