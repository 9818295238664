import React, { useState } from "react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { ComboboxDemo } from "./ComboboxInput";
import { Button } from "../ui/button";
import { ListFilter } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  statusOptions,
  stageOptions,
  ownerOptions,
  contactOptions,
  callbackOptions,
  sourceOptions,
} from "@/lib/data";
import { DateRangePicker } from "./DateRangePicker";
import { Badge } from "../ui/badge";
import AddLeadModal from "../leads/add-lead";

import useLeadsStore from "@/store/leadsStore";
import AddLeadCSV from "../leads/add-lead-csv";
import AssignLeadsModal from "../leads/assign-leads";

const LeadsFilter = ({setIsDownloadLeadOpen, selectedRows}) => {
  const {refetchApi} = useLeadsStore()
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isAddLeadOpen, setIsAddLeadOpen] = useState(false);
  const [isAddCSVOpen, setIsAddCSVOpen] = useState(false)
  const [isBulkAssignOpen, setIsBulkAssignOpen] = useState(false)

  const handleRefetch = async () => {
    setIsRefreshing(true)
    await refetchApi()
    setIsRefreshing(false)
  }

  return (
    <div className="flex items-center gap-2">
      {/* <div className="flex items-center space-x-2">
        <Checkbox id="terms" />
        <label
          htmlFor="terms"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          All
        </label>
      </div> */}

      <div>
        <DropdownMenu>
          <DropdownMenuTrigger>
            {" "}
            <Button className="h-8" variant="outline">
              Actions
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onSelect={()=>setIsAddLeadOpen(true)}>Add Enquiry</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={()=>setIsBulkAssignOpen(true)}>Assign Leads</DropdownMenuItem>
            <DropdownMenuItem onSelect={()=>setIsAddCSVOpen(true)}>Upload Leads</DropdownMenuItem>
            <DropdownMenuItem onClick={()=>{setIsDownloadLeadOpen(true)}}>Download Leads</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

      </div>
        <Button onClick={handleRefetch} className="h-8 gap-2" variant="outline">
          {
            isRefreshing ? <div><div className="spinner"></div></div> : ''
          }
          Refresh
        </Button>
        {isAddLeadOpen && <AddLeadModal isOpen={isAddLeadOpen} setIsOpen={setIsAddLeadOpen} />}
        {isAddCSVOpen && <AddLeadCSV isOpen={isAddCSVOpen} setIsOpen={setIsAddCSVOpen} />}
        {isBulkAssignOpen && <AssignLeadsModal selectedRows={selectedRows} isOpen={isBulkAssignOpen} setIsOpen={setIsBulkAssignOpen} />}
      {/* <div className="flex items-center py-2 gap-1">
       
        <Badge className={"text-sm rounded-md py-0 "}>All</Badge>
        <Badge variant={"outline"} className={"text-sm rounded-md py-0 "}>
          Hot
        </Badge>
        <Badge variant={"outline"} className={"text-sm rounded-md py-0 "}>
          Warm
        </Badge>
        <Badge variant={"outline"} className={"text-sm rounded-md py-0 "}>
          Cold
        </Badge>
      </div> */}
    </div>
  );
};

export default LeadsFilter;
