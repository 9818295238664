import React from "react";
import animationData from '../../assets/payment_link.json';
import payment_photo from '../../assets/payment_photo.png';
import generate_link from '../../assets/generate_link.png';
const AnimationPage = () => {
  return (
    <div className="relative overflow-hidden w-60 h-48 rounded-md">
      {/* <Lottie 
        animationData={animationData} 
      
      
        autoplay={false}
        // style={{ width: 250, height: 250 }} 
      /> */}
      <img className="absolute top-0 left-0 w-full h-full object-cover" src={generate_link} alt="payment" />
    </div>
  );
};

export default AnimationPage;
