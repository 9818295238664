import React from "react";
import { coupons } from "@/lib/data";
import { Card } from "../ui/card";
import { Input } from "../ui/input";
import {
  Calendar,
  CalendarDays,
  EllipsisVertical,
  TicketPercent,
  Trash,
  Search,
  CirclePlus,
} from "lucide-react";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import BulkCouponsForm from "../Custom/BulkCouponsForm";
import { Badge } from "../ui/badge";
import { Switch } from "../ui/switch";
import { useAuthStore } from "@/store/store";
import useCouponStore from "@/store/couponStore";
import EditBulkCouponsForm from './edit-coupon';
import useFetch from "@/hooks/useFetchLSD";
import { DeleteAlert } from "@/components/delete-alert"
import axios from 'axios';
import { deleteCoupon } from "@/apiService/coupons";

const Coupon = () => {
  const { companyslug } = useAuthStore();
  const dialogRef = React.useRef(null);
  const [coupons, setCoupons] = React.useState([]);
  const [isCreateCouponsOpen, setIsCreateCouponsOpen] = React.useState(false);
  const [isEditCouponModalOpen, setIsEditCouponModalOpen] = React.useState(false);
  const [filteredCoupons, setFilteredCoupons] = React.useState([]);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false)
  const [deleteCouponId, setDeleteCouponId] = React.useState()

  const { selectedCoupon, setSelectedCoupon } = useCouponStore();

  const { data, error, isLoading, refetch } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/coupons/${companyslug}/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: true
      },
    }
  );

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refetch();
    setIsRefreshing(false);
  }

  React.useEffect(() => {
    if(data){
      setCoupons(data?.response);
      setFilteredCoupons(data?.response);
    }
  }, [data])

  const couponStatus = async (id, checked) => {
    const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/toggle-coupon-status/${companyslug}/${id}/?active=${checked}`)
    refetch();
  }

  const handleSubmit = async (formData) => {
    // Submit the form logic here
    await refetch(); // Assuming refetch is used to refresh data
    setIsCreateCouponsOpen(false);
  };

  const handleSearch = (e) => {
    if(e.target.value==""){
      setFilteredCoupons(coupons);
    }else{
      const filtered = coupons.filter((coupon) => {
        return (coupon.name.toLowerCase().includes(e.target.value.toLowerCase()) || (coupon.discount_type.toLowerCase().includes(e.target.value.toLowerCase())) || (coupon.discount.toString().includes(e.target.value.toLowerCase())));
      });
      setFilteredCoupons(filtered);
    }
  }

  const handleDeleteCoupon = async (id) => {
    const response = await axios.delete(`${import.meta.env.VITE_APP_API_URL}/v3/delete-coupon/${companyslug}/${id}/`,{})
    setIsAlertOpen(false)
    refetch();
  }

  const handleEditCoupon = (coupon_details) => {
    setSelectedCoupon(coupon_details);
    setIsEditCouponModalOpen(true);
  }

  const handleEditCouponSubmit = () => {
    setIsEditCouponModalOpen(false);
    setSelectedCoupon({});
  }
  
  return (
    <>
    <h1 className="section_heading">Coupons</h1>
      <div className="flex justify-end py-4 gap-2">
        <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <form>
            <div className="relative">
              <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
              <Input
              onChange={handleSearch}
                placeholder="Search"
                className="pl-8 h-8 focus-visible:outline-none  focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            </div>
          </form>
        </div>
        <Button variant="outline" onClick={handleRefresh}>
        {
            isRefreshing ? <div><div className="spinner"></div></div> : ''
          }
          Refresh
        </Button>
            <Button onClick={() => setIsCreateCouponsOpen(true)}>
              <CirclePlus size={16} />
              Create Coupons
            </Button>
        {isCreateCouponsOpen && <Dialog open={isCreateCouponsOpen} onOpenChange={setIsCreateCouponsOpen} className="top-20 ">
         
          <DialogContent className="lg:max-w-xl overflow-y-scroll rounded-md lg:max-h-[85vh] max-h-[70vh]">
            <DialogHeader>
              <DialogTitle>Create Bulk Coupons</DialogTitle>
              <DialogDescription>
                Discount will be applied per ticket
              </DialogDescription>
            </DialogHeader>
            <div>
              <Tabs defaultValue="company" className="w-full">
                <TabsList className="w-full">
                  <TabsTrigger className="w-full" value="company">
                    Company Level
                  </TabsTrigger>
                  <TabsTrigger className="w-full" value="event">
                    Event Level
                  </TabsTrigger>
                  <TabsTrigger className="w-full" value="batch">
                    Batch Level
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="company">
                  <div>
                    <BulkCouponsForm 
                      flow_type={"company_level"} 
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </TabsContent>
                <TabsContent value="event">
                  <div>
                    <BulkCouponsForm 
                      flow_type={"event_level"} 
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </TabsContent>
                <TabsContent value="batch">
                  <div>
                    <BulkCouponsForm 
                      flow_type={"batch_level"} 
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </DialogContent>
        </Dialog>}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-y-4 gap-x-4">
        {filteredCoupons?.map((coupon,index) => {
          return (
            <div key={index}>
              <Card disabled={coupon.active} className="shadow-md relative coupon-card" >
                {!coupon.active &&<div className="overlay absolute top-0 left-0 w-full h-full opacity-50 bg-[#ffffff] z-[1] rounded-md"></div>}
                <div className="cutouts">
                  <div></div>
                </div>
                <div className="cutouts-right">
                  <div></div>
                </div>

                <div className="flex p-4 pb-0 bg-[rgba(248,246,242,0.3)] justify-between items-start">
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 leading-4 items-center">
                      <TicketPercent size={24} />
                      <h1 className="font-bold">
                        {coupon.name}
                        {/* <Badge className="ml-2 text-[0.6rem] h-4">Company</Badge> */}
                      </h1>
                    </div>

                   
                  </div>

                  {/* <Button variant="outline">
                    <Trash />
                  </Button> */}
                  <div className="flex gap-1 relative z-10 items-center">
                    <Switch onCheckedChange={() => couponStatus(coupon.id, !coupon.active)} checked={coupon.active} className="h-5" />
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <EllipsisVertical size={16} />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => handleEditCoupon(coupon)}>Edit</DropdownMenuItem>
                        <DropdownMenuItem onClick={() => {setDeleteCouponId(coupon.id);setIsAlertOpen(true)}}>Delete</DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <div className="px-4 pt-2 pb-2">
                      <div className="font-semibold text-sm">
                        <div className="font-semibold text-sm flex gap-1">
                          {coupon.flow_type == "company_level" && (
                            <>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                {coupon.flow_type}
                              </Badge>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                All Events
                              </Badge>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                All Batches
                              </Badge>
                            </>
                          )}
                          {coupon.flow_type == "event_level" && (
                            <>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                {coupon.flow_type}
                              </Badge>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                {coupon.events} Events
                              </Badge>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                All Batches
                              </Badge>
                            </>
                          )}
                          {coupon.flow_type == "batch_level" && (
                            <>
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                {coupon.flow_type}
                              </Badge>
                              {/* <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                {coupon.events} Events
                              </Badge> */}
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                {coupon.batches} Batches
                              </Badge>
                            </>
                          )}
                          {coupon.visible == true ? (
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                Public
                              </Badge>
                          ):(
                              <Badge
                                variant="outline"
                                className="text-[0.6rem] h-4 text-center rounded-md"
                              >
                                Private
                              </Badge>
                          )
                          }
                        </div>
                      </div>
                    </div>

                <div className="pt-4 p-4 border-t border-dashed grid grid-cols-2 gap-x-4 gap-y-4">
                  <div className="flex flex-col gap-1 justify-between items-center">
                    <span className="text-gray-500 text-xs font-semibold">
                      Discount
                    </span>
                    <h1 className="font-bold flex items-center gap-1">
                      {coupon.discount_type == "Percentage"
                        ? `${coupon.discount}%`
                        : `₹${coupon.discount}`}
                      {coupon.discount_applicable!="flat" ? <span className="text-xs text-gray-500 font-normal">
                        / ticket
                      </span> : <span className="text-xs text-gray-500 font-normal">flat</span>}
                    </h1>
                  </div>
                  <div className="flex flex-col gap-1 justify-between items-center">
                    <span className="text-gray-500 text-xs font-semibold">
                      Stock
                    </span>
                    <span className="font-semibold text-sm">
                      {coupon.used_count}{coupon.quantity ? `/${coupon.quantity}` : ''}
                    </span>
                  </div>
                </div>
                <div className="px-4 py-4 flex gap-2 items-center  border-t border-dashed ">
                  <span className="text-xs flex items-center gap-2">
                    <CalendarDays size={16} />
                    <span className="font-semibold text-sm text-black">
                      {coupon.valid_from} - {coupon.valid_until}
                    </span>
                  </span>
                </div>
              </Card>
            </div>
          );
        })}
        {isEditCouponModalOpen && <EditBulkCouponsForm open={isEditCouponModalOpen} refetch={refetch} setIsOpen={setIsEditCouponModalOpen}/>}
      </div>
      <DeleteAlert
        open={isAlertOpen}
        setOpen={setIsAlertOpen}
        title="Delete Coupon?"
        description="This will permanently remove the Coupon."
        onConfirm={handleDeleteCoupon}
        confirmArgs={deleteCouponId}

      />
    </>
  );
};

export default Coupon;
