import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useCompanyStore } from "@/store/companyStore";
import CopyLink from "../events/copy-button";
import { useAuthStore } from "@/store/store";
import { Textarea } from "../ui/textarea";
import axios from "axios";

const BookNowCodeModal = ({ isOpen, setIsOpen }) => {
  const allevents = useCompanyStore().companyData.events.data;
  const [bookNowCode, setBookNowCode] = useState(null);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);  // Adding loading state
  const { companyslug } = useAuthStore();

  const getCode = async (eventId) => {
    setLoading(true); // Set loading state to true while waiting for the response
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/events/${companyslug}/${eventId}/booknow-button-code/`
      );
      setBookNowCode(res.data.data);
    } catch (error) {
      console.error("Failed to fetch the code:", error);
    } finally {
    }
    setLoading(false); // Set loading state to false once done
  };

  useEffect(() => {
    if (event) {
      getCode(event.id); // Fetch the code for the selected event
    } else {
      setBookNowCode(null); // Clear the code if no event is selected
    }
  }, [event]); // Dependency array to trigger the effect whenever the `event` changes

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="lg:max-w-md max-h-[70vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Checkout Code</DialogTitle>
          </DialogHeader>
          <div className="w-full">
            <div className="flex items-center gap-3">
              <Select
                onValueChange={(value) => {
                  const selectedEvent = allevents.find((event) => event.id === value);
                  setEvent(selectedEvent);
                }}
                className="w-full flex-grow"
              >
                <SelectTrigger className="w-full flex-grow max-w-sm">
                  <SelectValue placeholder="Select Event" />
                </SelectTrigger>
                <SelectContent side="bottom" className="w-full">
                  {allevents.map((event) => (
                    <SelectItem key={event.id} value={event.id}>
                      {event.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {event && <CopyLink code={bookNowCode} />}
            </div>
            <div>
             
            {bookNowCode && <Textarea value={bookNowCode} className="h-72 mt-4" readOnly></Textarea>}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BookNowCodeModal;
