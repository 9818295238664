import { useEffect, useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const options = [
  { value: "apple", label: "Apple" },
  { value: "banana", label: "Banana" },
  { value: "cherry", label: "Cherry" },
  { value: "date", label: "Date" },
  { value: "grape", label: "Grape" },
];

export default function MultiSelectCombobox({options, onUpdate, defaultValues}) {
  console.log(defaultValues)
  const [selectedValues, setSelectedValues] = useState(defaultValues);

  useEffect(()=>{
    setSelectedValues(defaultValues)
  },[defaultValues])

  const [open, setOpen] = useState(false);

  const toggleSelection = (value) => {
    setSelectedValues((prev = []) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  useEffect(()=>{
    if(JSON.stringify(selectedValues) != JSON.stringify(defaultValues) && selectedValues!=defaultValues && selectedValues && selectedValues.length>0)
    onUpdate(selectedValues)
  },[selectedValues])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="justify-between w-full">
          {/* {selectedValues.length > 0
            ? options
                .filter((opt) => selectedValues?.includes(opt.value))
                .map((opt) => opt.label)
                .join(", ")
            : "Select Options"} */}
            Select Options
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
            <CommandList>
          <CommandGroup>
            {options?.map((option) => (
              <CommandItem
                key={option.value}
                onSelect={() => toggleSelection(option.value)}
                className="flex items-center gap-2 cursor-pointer"
              >
                <div
                  className={cn(
                    "w-4 h-4 border rounded-sm flex items-center justify-center",
                    selectedValues?.includes(option.value)
                      ? "bg-primary border-primary"
                      : "border-gray-300"
                  )}
                >
                  {selectedValues?.includes(option.value) && (
                    <Check className="h-3 w-3 text-white" />
                  )}
                </div>
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
