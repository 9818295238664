import React, { useEffect, useState } from "react";
import { Textarea } from "../ui/textarea";
import { Label } from "../ui/label";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableMessage from "./GreetingReply";
import { arrayMove } from "@dnd-kit/sortable";
import { replyTypes } from "./Sidebar";
import { X } from "lucide-react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { update } from "lodash";

const NodeDetails = ({ selectedStage, updateStageForm, getUpdatedStage }) => {
  const [textNode, setTextNode] = useState(null);
  const [quickReplyNodes, setQuickReplyNodes] = useState(null);
  const [newQuickReplies, setNewQuickReplies] = useState([]);
  const [stage, setStage] = useState(selectedStage);

  useEffect(() => {
    if (stage?.forms?.length > 0) {
      console.log("here", stage);
      const text = stage.forms.find((o) => o.type == "text");
      setTextNode(text);
      const quickReplies = stage.forms.filter((o) => o.type == "quick_reply")[0]
        ?.value;
      const customQuickReplies = stage.forms.filter(
        (o) => o.type == "custom_quick_reply"
      )[0]?.value;
      var replies = [];
      if (quickReplies?.length > 0) {
        replies = [...replies, ...quickReplies];
      }
      if (customQuickReplies?.length > 0) {
        replies = [...replies, ...customQuickReplies];
      }

      console.log("new replies", replies);
      setQuickReplyNodes(replies);
    }
  }, [stage]);

  useEffect(() => {
    setStage(selectedStage);
  }, [selectedStage]);

  const handleExistingReplyChange = (text, e) => {
    const updated = [...stage.forms];
    var updateField = updated.find((item) => item.type == "quick_reply");
    const toBeEdited = updateField.value.find((o) => o.text == text);
    toBeEdited.text = e.target.value;
    updateStageForm(stage.id, updated);
    const newStage = getUpdatedStage(stage.id);
    setStage(newStage);
  };

  const handleQuickReplyChange = (field, text, e) => {
    const updated = [...stage.forms];
    var updateField = updated.find((item) => item.type == "custom_quick_reply");
    console.log(updateField);
    const toBeEdited = updateField.value.find((o) => o.text == text);
    toBeEdited[field] = e.target.value;
    updateStageForm(stage.id, updated);
    const newStage = getUpdatedStage(stage.id);
    setStage(newStage);
  };

  const handleGreetingChange = (e) => {
    const updated = [...stage.forms];
    console.log(updated);
    const text = updated.find((o) => o.type == "text");
    text.value = e.target.value;
    updateStageForm(stage.id, updated);
    const newStage = getUpdatedStage(stage.id);
    setStage(newStage);
  };

  const addQuickReply = () => {
    const newReply = replyTypes.CUSTOM_QUICK_REPLY.value[0];
    setNewQuickReplies([...newQuickReplies, newReply]);
  };

  const removeQuickReply = (index) => {
    if (index > -1) {
      const updated = [...newQuickReplies];
      updated.splice(index, 1);
      setNewQuickReplies(updated);
      const updatedForms = [...stage.forms];
      const quick = updatedForms.find((o) => o.type == "custom_quick_reply");
      quick.value = [...quick.value, ...newQuickReplies];
      updateStageForm(stage.id, updatedForms);
    }
  };

  const handleReplyChange = (index, field, value) => {
    setNewQuickReplies((prevItems) =>
      prevItems.map((item, ind) =>
        index === ind
          ? {
              ...item,
              [field]: value,
              payload: `CUSTOM_QUICK_REPLY,${value}`, // Ensures correct format
            }
          : item
      )
    );
  };

  const handleDeleteReply = (text) => {
    console.log(text)
    var updated = [...stage.forms];
    const quick = updated.find((o) => o.type == "custom_quick_reply");
    const index = quick.value.findIndex((o)=>o.text == text)
    console.log(index)
    quick.value.splice(index, 1)
    updateStageForm(stage.id, updated)
    const newStage = getUpdatedStage(stage.id);
    setStage(newStage);

  }

  const handleAddQuickReply = async (index) => {
    var updated = [...stage.forms];
    console.log(updated);
    const quick = updated.find((o) => o.type == "custom_quick_reply");
    if (quick) {
      quick.value = [...quick.value, ...newQuickReplies];
      console.log(quick);
      updateStageForm(stage.id, updated);
      const newStage = await getUpdatedStage(stage.id);
      console.log("new", newStage);
      setStage({ ...newStage });
      newQuickReplies.splice(index, 1);
      setNewQuickReplies(newQuickReplies);
    } else {
      console.log("here");
      var newCustomQuickReply = replyTypes.CUSTOM_QUICK_REPLY;
      newCustomQuickReply.value = [...newQuickReplies];
      console.log(newCustomQuickReply);
      updated.push(newCustomQuickReply);
      updateStageForm(stage.id, updated);
      const newStage = await getUpdatedStage(stage.id);
      console.log("new", newStage);
      setStage({ ...newStage });
      newQuickReplies.splice(index, 1);
      setNewQuickReplies(newQuickReplies);
    }
  };

  const reorderReplies = async (e) => {
    const updated = [...stage.forms];
    const quick = updated.find(
      (o) => o.type == "quick_reply" || o.type == "custom_quick_reply"
    );
    var newArr = [...quick.value];
    if (!e.over) {
      console.log("No over");
      return;
    }
    console.log(e.active, newArr);
    if (e.active.id !== e.over.id) {
      const oldIndex = newArr.findIndex(
        (item) => item.text == e.active.id.text.toString()
      );

      const newIndex = newArr.findIndex(
        (item) => item.text == e.over.id.text.toString()
      );

      newArr = arrayMove(quick.value, oldIndex, newIndex);
      quick.value = newArr;
      console.log(quick.value, newArr);
      updateStageForm(stage.id, updated);

      //   setSelectedCategories(newArr);
      const newStage = getUpdatedStage(stage.id);
      console.log("new", newStage);
      setStage(newStage);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {
        <div className="flex flex-col gap-2">
          <Label>{textNode?.description}</Label>
          <Textarea
            onChange={handleGreetingChange}
            defaultValue={textNode?.value}
          />
        </div>
      }

      {quickReplyNodes?.length > 0 && (
        <DndContext onDragEnd={reorderReplies}>
          <div className="flex px-2 flex-col gap-3">
            <SortableContext
              strategy={verticalListSortingStrategy}
              items={quickReplyNodes}
            >
              {quickReplyNodes?.map((value, index) => (
                <SortableMessage
                  key={index}
                  message={value}
                  handleExistingReplyChange={handleExistingReplyChange}
                  handleQuickReplyChange={handleQuickReplyChange}
                  handleDeleteReply={handleDeleteReply}
                  // handleGreetingChange={handleGreetingChange}
                  // flowStage={flowStage}
                  // setFlowStage={setFlowStage}
                />
              ))}
            </SortableContext>
          </div>
        </DndContext>
      )}
      <div className="flex flex-col gap-4">
        {newQuickReplies?.length > 0 &&
          newQuickReplies.map((reply, index) => (
            <div
              className="flex relative flex-col gap-2 border-[1px] rounded-md p-4"
              key={index}
            >
              <div
                onClick={() => removeQuickReply(index)}
                className="absolute top-2 right-2"
              >
                <X size={14} />
              </div>
              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="reply">Header</Label>
                <Input
                  onChange={(e) =>
                    handleReplyChange(index, "text", e.target.value)
                  }
                  type="text"
                  placeholder="Question"
                  value={reply.text}
                />
              </div>
              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="reply">Response</Label>
                <Textarea
                  onChange={(e) =>
                    handleReplyChange(index, "response", e.target.value)
                  }
                  // onChange={(e) => handleCustomNodeChange(e, "response")}
                  type="text"
                  id="reply"
                  placeholder="Reply"
                  value={reply.response}
                />
              </div>
              <Button
                className="mt-2"
                onClick={() => handleAddQuickReply(index)}
              >
                Update
              </Button>
            </div>
          ))}
      </div>
      <div>
        <Button className="w-full" onClick={addQuickReply}>
          Add Reply Node
        </Button>
      </div>
    </div>
  );
};

export default NodeDetails;
