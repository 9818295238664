import { useQuery } from 'react-query';
import axios from 'axios';

//options is an object which can have two objects
//axiosOptions: options for axios
//queryOptions: options for useQuery

//Just pass the url and options to this hook and it returns the data and other states

function updateQueryParams(url, newParams,loadedMore) {
  const urlObj = new URL(url); // Create a URL object to parse the URL
  const currentParams = new URLSearchParams(urlObj.search); // Get existing query parameters

  // Update or add new parameters
  Object.entries(newParams).forEach(([key, value]) => {
    
    if (value === null || value === undefined) {
      currentParams.delete(key); // Remove the parameter if the value is null or undefined
    } else {
      currentParams.set(key, value); // Update or add the parameter
    }
  });
  // if(!loadedMore){
  //   currentParams.delete("cursor");
  // }

  // Update the URL's search string
  urlObj.search = currentParams.toString();

  return urlObj.toString(); // Return the updated URL as a string
}

const useFetch = (url,params, options = {},key) => {

  const fullUrl = updateQueryParams(url,params,options.loadedMore)

  const fetcher = async () => {
    const response = await axios.get(fullUrl);
    return response.data;
  };

  const { data, error, isLoading, isFetching, refetch} = useQuery(
    [key || 'events',params,url],
    fetcher,
    {
      enabled: options.queryOptions?.enabled,
     refetchOnMount: options.queryOptions?.refetchOnMount,
     refetchOnWindowFocus: false,
     cacheTime: 0,
      ...options.queryOptions,
    }
  );

  return { data, error, isLoading,refetch, isFetching };
};

export default useFetch;
