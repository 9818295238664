import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { CalendarIcon, InfoIcon, Download, ChevronDown } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format } from "date-fns";
import { useAuthStore } from "@/store/store";
import useFetch from "@/hooks/useFetchLSD";
import { Search } from "lucide-react";
import { MetricCard } from "./card-info";
import RefundTimeline from "./refund-timeline";
import { debounce } from "lodash";

const MobileCard = ({ item }) => (
  <div className="p-3 border rounded-lg mb-3">
    <div className="flex justify-between items-start">
      <div>
        <div className="text-sm text-gray-600">
          {format(new Date(item?.created_at), "d MMM, yyyy | hh:mm a")}
        </div>
        <div className="font-medium">{item.id}</div>
      </div>
    </div>
    <div className="flex justify-between items-end">
      <div>
        <div className="text-sm text-gray-600">Booking ID:{item.booking}</div>
      </div>
    </div>
    <div className="space-y-2 mt-2">
      <div>
        <div className="text-sm text-gray-500">Type</div>
        <div className="text-gray-600">{item.type}</div>
      </div>
      <div className="flex justify-between">
        <div>
          <div className="text-sm text-gray-500">Status</div>
          <div className="text-gray-600">
            {item.status === "completed" ? (
              <span className="inline-flex items-center px-2 py-1 rounded-full bg-green-50 text-green-600 text-xs">
                <span className="w-1.5 h-1.5 rounded-full bg-green-600 text-xs mr-1.5"></span>
                Processed
              </span>
            ) : (
              <span className="inline-flex items-center px-2 py-1 rounded-full bg-yellow-50 text-white-600 text-xs">
                <span className="w-1.5 h-1.5 rounded-full bg-yellow-600 text-xs mr-1.5"></span>
                Pending
              </span>
            )}
          </div>
        </div>
        <div className="text-right">
          <div className="text-sm text-gray-500">Amount</div>
          <div className="font-medium">
            &#8377;
            {item.amount_in_rupees}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function RefundsPage() {
  const { companyslug } = useAuthStore();
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [refund, setRefund] = useState([]);
  const [refundSummary, setRefundSummary] = useState({});
  const [isTimelineOpen, setIsTimelineOpne] = useState(false);
  const [selectedRefund, setSelectedRefund] = useState("");
  const [filters, setFilters] = useState({});

  const {
    data: refundData,
    error: refundError,
    isLoading: isRefundLoading,
    refetch: refetchRefund,
    isFetching: isFetchingRefund,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/refunds/${companyslug}/`,
    filters,
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
      },
    }
  );

  const {
    data: refundSummaryData,
    error: refundSummaryError,
    isLoading: isRefundSummaryLoading,
    refetch: refetchRefundSummary,
    isFetching: isFetchingRefundSummary,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/refunds/${companyslug}/summary/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
      },
    }
  );

  useEffect(()=>{
    refetchRefund();
  },[filters])

  const debouncedSearch = useCallback(
    debounce((newSearch) => {
      setFilters((prev) => {
        return { ...prev, q: newSearch };
      });
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    setRefund(refundData?.results);
  }, [refundData]);

  useEffect(() => {
    setRefundSummary(refundSummaryData);
  }, [refundSummaryData]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const setTimeLine = function (refund) {
    setSelectedRefund(refund);
    setIsTimelineOpne(true);
  };

  const selectionYears = ["2025"];

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  return (
    <div className="w-full">
      <div className="mb-3">
        <div className="flex gap-6 justify-between">
          <h2 className="section_heading">Refunds</h2>
          {/* <div className="flex gap-2">
            <Button className="bg-black hover:bg-black text-white">Search</Button>
            <Button variant="outline">Clear</Button>
          </div> */}
        </div>
        <div className="flex items-center gap-4 pt-4 pb-2">
          <MetricCard
            title="Total Refunds"
            value={refundSummary?.total_refund_count || "-"}
          />
          <MetricCard
            title="Amount Refunded"
            value={`₹${((refundSummary?.total_refund_amount/100) || 0).toFixed(2)}`}
          />
          <MetricCard
            title="Pending Deductions"
            value={`₹${(refundSummary?.total_request_amount / 100 || 0).toFixed(
              2
            )}`}
          />
        </div>
        <div className="flex gap-4 items-center">
          <div className="bg-background/95 py-4 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <form>
              <div className="relative">
                <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
                <Input
                  onChange={(e)=>{handleSearchChange(e)}}
                  placeholder="Search"
                  className="pl-8 h-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                />
              </div>
            </form>
          </div>
          <div>
            <Button
              variant="outline"
              onClick={() => {
                refetchRefund();
              }}
            >
              {isFetchingRefund && !isRefundLoading ? <div className="spinner"></div> : ''}
              Refresh
            </Button>
          </div>
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button>Actions</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={()=>{window.location.href=`${import.meta.env.VITE_APP_API_URL}/dashboard/export-company-refunds/${companyslug}`}}>Download Report</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <Dialog open={isDownloadOpen} onOpenChange={setIsDownloadOpen}>
        <DialogContent
          className={"lg:max-w-md rounded-md lg:max-h-[80vh] max-h-[70vh]"}
        >
          <DialogHeader>
            <DialogTitle>Download Invoice</DialogTitle>
          </DialogHeader>
          <div className="flex items-center gap-2">
            <Select onValueChange={(value) => setSelectedMonth(value)}>
              <SelectTrigger className="focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Month" value={selectedMonth} />
              </SelectTrigger>
              <SelectContent side="bottom">
                {months.map((month, index) => (
                  <SelectItem
                    disabled={index > currentMonth}
                    key={month}
                    value={month.toLowerCase()}
                  >
                    {month}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select onValueChange={(value) => setSelectedYear(value)}>
              <SelectTrigger className="focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Year" value={selectedYear} />
              </SelectTrigger>
              <SelectContent side="bottom">
                {selectionYears.map((month) => (
                  <SelectItem key={month} value={month.toLowerCase()}>
                    {month}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button>
              <Download size={14} />
              Download
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="hidden text-xs md:block border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow className="bg-gray-50">
              <TableHead className="font-medium w-32">Booking ID</TableHead>
              <TableHead className="font-medium">Date</TableHead>
              <TableHead className="font-medium text-center">
                Refund Amount
              </TableHead>
              <TableHead className="font-medium w-40">Status</TableHead>
              <TableHead className="font-medium">Type</TableHead>
              <TableHead className="font-medium">Settlement Info</TableHead>
              <TableHead className="font-medium">Reference No.</TableHead>

              <TableHead className="font-medium w-32">Remarks</TableHead>
              <TableHead className="font-medium w-32">Timeline</TableHead>
              {/* <TableHead className="font-medium">Actions</TableHead> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {refund &&
              refund.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell className="w-32">{item.booking}</TableCell>
                  <TableCell className="">
                    {format(
                      new Date(item?.created_at),
                      "d MMM, yyyy | hh:mm a"
                    )}
                  </TableCell>
                  <TableCell className="text-center">
                    &#8377;{item.amount_in_rupees}
                  </TableCell>
                  <TableCell>
                    {item.status === "completed" ? (
                      <span className="inline-flex items-center px-2 py-1 rounded-full bg-green-50 text-green-600 text-xs">
                        <span className="w-1.5 h-1.5 rounded-full bg-green-600 text-xs mr-1.5"></span>
                        Processed
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2 py-1 rounded-full bg-yellow-50 text-white-600 text-xs">
                        <span className="w-1.5 h-1.5 rounded-full bg-yellow-600 text-xs mr-1.5"></span>
                        Pending
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="">{item.type}</TableCell>
                  <TableCell>
                    {item.settled ? (
                      <span className="inline-flex items-center px-2 py-1 rounded-full bg-green-50 text-green-600 text-xs">
                        <span className="w-1.5 h-1.5 rounded-full bg-green-600 text-xs mr-1.5"></span>
                        Processed
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2 py-1 rounded-full bg-yellow-50 text-white-600 text-xs">
                        <span className="w-1.5 h-1.5 rounded-full bg-yellow-600 text-xs mr-1.5"></span>
                        Pending
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="w-32">
                    {item.ref_number || "-"}
                  </TableCell>

                  <TableCell className="text-gray-600 w-32">
                    {item.remarks || "-"}
                  </TableCell>
                  <TableCell className="text-gray-600 w-32">
                    <Button
                      onClick={() => {
                        setTimeLine(item);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      {/* {isTimelineOpen && <RefundTimeline refund={selectedRefund}/>} */}

      <div className="md:hidden">
        {refund &&
          refund.map((item) => <MobileCard key={item.id} item={item} />)}
      </div>
    </div>
  );
}
