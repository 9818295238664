import React, {useEffect, useState} from 'react'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import {Button} from '../ui/button';
import {Label} from '../ui/label';
import {DateRangePicker} from './DateRangePicker';
import {ComboboxDemo} from './ComboboxInput';
import {ListFilter} from 'lucide-react';
import {ownerOptions, contactOptions, callbackOptions, sourceOptions} from '@/lib/data';
import useLeadsStore from "@/store/leadsStore";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {format, parse} from 'date-fns';
import axios, {all} from 'axios';
import {useCompanyStore} from '@/store/companyStore';

const AllLeadsFilters = ({apiQuery, setApiQuery, refetch, setQueryParams, setPagination}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [citiesOptions, setCitiesOptions] = React.useState([]);
    const allevents = useCompanyStore().companyData.events.data
    const fetchCities = async () => {
        const res = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/get-cities/`);
        const cityOptions = res.data.map((city) => ({
            value: city.slug,
            label: city.name,
        }))

        setCitiesOptions(cityOptions);
    }

    useEffect(() => {
        fetchCities()
    }, []);

    const handleApplyFilters = () => {
        setIsPopoverOpen(false);
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
        setTimeout(() => {
            refetch()
        }, 0);
    }

    const handleDateRangeChange = (key, value) => {
        console.log(key, value);
        if (key == "created_at") {
            setApiQuery("created_at_from_date", format(value.from, 'yyyy-MM-dd'));
            setApiQuery("created_at_to_date", format(value.to, 'yyyy-MM-dd'));
        }
        if (key == "modified_at") {
            setApiQuery("modified_at_from_date", format(value.from, 'yyyy-MM-dd'));
            setApiQuery("modified_at_to_date", format(value.to, 'yyyy-MM-dd'));
        }
        // setApiQuery(key, format(new Date(value), 'yyyy-MM-dd')); // Update specific date range
    };

    const handleClear = () => {
        setQueryParams({
            created_at_from_date: "",
            created_at_to_date: "",
            destination_location: "",
            event_slug: "",
            funnel_stage: "",
            is_expecting_call_back: "",
            is_mobile: "",
            modified_at_from_date: "",
            modified_at_to_date: "",
            ordering: "",
            source: "",
        });
        setIsPopoverOpen(false)
        setTimeout(() => {
            refetch()
        }, 0)
    }

    const handleSelectChange = (key, value) => {
        setApiQuery(key, value); // Update select field value
    };

    const {teamMates} = useLeadsStore();
    return (
        <div>
            <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} align="end">
                <PopoverTrigger onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                    <Button className="h-8" variant="outline">
                        <ListFilter size={14}/>
                        <span className="hidden md:flex">Filters</span>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto">
                    <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
                        {/* Created Date */}
                        <div className="flex flex-col gap-1">
                            <Label>Created date</Label>
                            <DateRangePicker
                                onChange={(range) => handleDateRangeChange("created_at", range)}
                                defaultValue={(apiQuery.created_at_from_date && apiQuery.created_at_to_date) && {
                                    from: new Date(apiQuery.created_at_from_date),
                                    to: new Date(apiQuery.created_at_to_date)
                                }}
                                value={apiQuery.created_at}
                            />
                        </div>

                        {/* Modified Date */}
                        <div className="flex flex-col gap-1">
                            <Label>Modified date</Label>
                            <DateRangePicker
                                onChange={(range) => handleDateRangeChange("modified_at", range)}
                                defaultValue={(apiQuery.modified_at_from_date && apiQuery.modified_at_to_date) && {
                                    from: new Date(apiQuery.modified_at_from_date),
                                    to: new Date(apiQuery.modified_at_to_date)
                                }}
                                value={apiQuery.modified_at}
                            />
                        </div>

                        {/* Owner */}
                        <div className="flex flex-col gap-1">
                            <Label>Owner</Label>
                            <Select
                                onValueChange={(value) => handleSelectChange("asigned_to", value)}
                                value={apiQuery.asigned_to}
                                // value={apiQuery.assigned_to}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Assignee"/>
                                </SelectTrigger>
                                <SelectContent>
                                    {teamMates?.map((team) => (
                                        <SelectItem key={team.value} value={team.value}>
                                            {team.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Contact */}
                        <div className="flex flex-col gap-1">
                            <Label>Contact</Label>
                            <Select
                                onValueChange={(value) => handleSelectChange("is_mobile", value)}
                                // value={apiQuery.source}
                                value={apiQuery.is_mobile}
                            >
                                <SelectTrigger className="">
                                    <SelectValue placeholder="Contact"/>
                                </SelectTrigger>
                                <SelectContent>
                                    {contactOptions?.map((option) => (
                                        <SelectItem key={option.value} value={option.value.toString()}>
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Expecting Callback */}
                        <div className="flex flex-col gap-1">
                            <Label>Expecting Callback</Label>
                            <Select
                                onValueChange={(value) => handleSelectChange("is_expecting_call_back", value)}
                                // value={apiQuery.is_expecting_call_back}
                                value={apiQuery.is_expecting_call_back}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Callback Option"/>
                                </SelectTrigger>
                                <SelectContent>
                                    {callbackOptions?.map((option) => (
                                        <SelectItem key={option.value} value={option.value.toString()}>
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Source */}
                        <div className="flex flex-col gap-1">
                            <Label>Source</Label>
                            <Select
                                onValueChange={(value) => handleSelectChange("source", value)}
                                value={apiQuery.source}

                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Source"/>
                                </SelectTrigger>
                                <SelectContent side="bottom">
                                    {sourceOptions?.map((source) => (
                                        <SelectItem key={source.value} value={source.value.toString()}>
                                            {source.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        {/* Destination Location */}
                        <div className="flex flex-col gap-1">
                            <Label>Location</Label>
                            <Select
                                onValueChange={(value) => handleSelectChange("destination_location", value)}
                                // value={apiQuery.source}
                                value={apiQuery.destination_location}

                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Location"/>
                                </SelectTrigger>
                                <SelectContent side="bottom">
                                    {citiesOptions?.length > 0 && citiesOptions?.map((city) => (
                                        <SelectItem key={city.value} value={city.value.toString()}>
                                            {city.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex flex-col gap-1">
                            <Label>Event</Label>
                            <Select
                                onValueChange={(value) => {
                                    handleSelectChange("event_slug", value)
                                }}
                                value={apiQuery.event_slug}
                                // value={apiQuery.source}

                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Event"/>
                                </SelectTrigger>
                                <SelectContent side="bottom">
                                    {allevents.map((event) => (
                                        // <span>{event.slug}</span>
                                        <SelectItem key={event.slug} value={event.slug || 'hey'}>
                                            {event.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <div className="flex gap-4 mt-4 md:mt-2 justify-between md:justify-end">
                        <Button onClick={handleClear} variant="outline">Clear</Button>
                        <Button onClick={() => {
                            handleApplyFilters()
                        }}>Apply</Button>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    )
}

export default AllLeadsFilters
