import React, { useEffect, useCallback, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { RotateCw, Search, Trash2 } from "lucide-react";
import { Card } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import { debounce } from "lodash";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import {
  changeAvailability,
  removeTeamMember,
  searchTeamMembers,
  addTeamMember,
} from "@/apiService/teams";
import ManageTeam from "./manage-team";
import { useUserStore } from "@/store/userStore";

const GROUPS = {
  // owner: "Owner",
  admin: "Admin",
  sales_admin: "Sales Admin",
  sales: "Sales",
  finance: "Finance",
  social: 'Social',
  operation: "Operations",
  volunteer: "Volunteer",
  vendor: "Agents",
};

export function TeamsTable() {
  const { companyslug } = useAuthStore();
  const [selectedTeam, setSelectedTeam] = React.useState("admin");
  const [members, setMembers] = React.useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [activeMembers, setActiveMembers] = React.useState(0);
  const [searchActive, setSearchActive] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const [accessModalOpen, setAccessModalOpen] = React.useState(false);
  const [isManageTeamVisible, setIsManageTeamVisible] = useState(null)

  const user = useUserStore();

  useEffect(()=>{
    console.log(user)
    const accessLevels = user.userData?.user?.access_level
    try{
      const companyAccess = accessLevels[companyslug]
      if(companyAccess?.ADMIN == true || user.userData.user?.is_superuser){
        setIsManageTeamVisible(true)
      }

    }catch(error){
      if(user.userData.user?.is_superuser){
        setIsManageTeamVisible(true);
      }
      console.log(error)
      alert("ONLY VISIBLE TO LOGOUT!! SOME ERROR OCCURED HERE. PLEASE CHECK LOGS")

    }
  
   
  },[user])

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const {
    data: teamdata,
    error: teamError,
    isLoading: isTeamLoading,
    refetch: refetchTeam,
    isFetching: isTeamFetching,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/team/${companyslug}/?team=${selectedTeam.toLowerCase()}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: false,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    refetchTeam();
    setSearchResults([]);
    setIsSearching();
  }, [selectedTeam]);

  useEffect(() => {
    setMembers(teamdata);
  }, [teamdata]);

  useEffect(() => {
    const totalActive =
      members && members.filter((user) => user.available).length;
    setActiveMembers(totalActive);
  }, [members]);

  const [loadingStates, setLoadingStates] = React.useState({});

  const handleToggleAvailability = async (id, checked) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));

    const response = await changeAvailability(id, companyslug);

    setLoadingStates((prev) => ({ ...prev, [id]: false }));

    if (!response.isError) {
      console.log("Availability updated successfully!");
      setMembers((prevData) =>
        prevData.map((item) =>
          item.peopleid === id ? { ...item, available: checked } : item
        )
      );
    } else {
      console.error("Error updating availability:", response.error);
    }
  };

  const handleMemberRemoval = async (id) => {
    const response = await removeTeamMember(id, selectedTeam, companyslug);

    refetchTeam();
  };

  const debouncedSearch = useCallback(
    debounce(async (newSearch) => {
      if (!newSearch.trim().includes("@")) return;
      console.log("selected team", selectedTeam);
      const result = await searchTeamMembers(
        newSearch,
        companyslug,
        selectedTeam
      );
      console.log("API Returned:", result);

      if (result?.data) {
        setIsSearching(true);
        setSearchResults(result.data.data);
        console.log("Updated Search Results:", result.data.data);
      }
    }, 500),
    [selectedTeam]
  );

  const addTeam = async function (id) {
    const { data, error } = await addTeamMember(id, selectedTeam, companyslug);
    if (!error) {
      refetchTeam();
      setIsSearching(false);
    }
  };

  const handleSearchChange = (e) => {
    if (!e.target.value) {
      setIsSearching(false);
    }
    debouncedSearch(e.target.value);
  };

  return (
    <div className="w-full   min-h-screen">
      {/* Header */}
      <div className="mb-6">
        <h2 className="section_heading">Teams</h2>

        {!isMobile && (
          <div className="w-full overflow-x-auto">
            <div className="flex py-4 gap-2 items-center">
              {Object.entries(GROUPS).map(([key, value]) => (
                <Button
                  variant={`${selectedTeam === key ? "default" : "outline"}`}
                  className="rounded-md whitespace-nowrap shrink-0"
                  key={key}
                  onClick={() => setSelectedTeam(key)}
                >
                  {value}
                </Button>
              ))}
            </div>
          </div>
        )}

        {isMobile && (
          <div className="md:hidden space-y-8 4">
            <div className="relative w-full">
              
              <DropdownMenu className={'w-full'}>
                <DropdownMenuTrigger className="w-full">
                <Button
              
                className="w-full mb-4"
                variant="outline"
              >
                {GROUPS[selectedTeam] || "Select Team"}
              </Button>
                </DropdownMenuTrigger >
                <DropdownMenuContent className="w-auto">
                  
                {Object.entries(GROUPS).map(([key, value]) => (
                    <DropdownMenuItem
                      key={key}
                      onClick={() => {
                        setSelectedTeam(key);
                        
                      }}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {value}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
              {/* {isDropdownOpen && (
                <div className="absolute z-10 w-full bg-white shadow-lg rounded-md border">
                  {Object.entries(GROUPS).map(([key, value]) => (
                    <div
                      key={key}
                      onClick={() => {
                        setSelectedTeam(key);
                        setIsDropdownOpen(false);
                      }}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {value}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </div>
        )}

        <div className="bg-white rounded-lg p-4 mb-6 shadow-sm border border-gray-200">
          <div className="text-sm text-blue-600 mb-1">ACTIVE TEAM MATES</div>
          {/* <div className="text-2xl font-semibold text-gray-900">10 active</div> */}
          <span className="inline-flex items-center px-2 py-1 rounded-full bg-green-50 text-green-600 text-sm">
            {/* <span className="w-1.5 h-1.5 rounded-full bg-green-600 mr-1.5"></span> */}
            {activeMembers} Active
          </span>
        </div>
      </div>

      {/* Search */}
      <div className="flex gap-3 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <Input
            placeholder="Search with email"
            className="pl-10 bg-white border-gray-200 text-gray-900 placeholder:text-gray-400"
            onChange={(value) => {
              handleSearchChange(value);
            }}
          />
        </div>
        <Button
          variant="outline"
          className="bg-white border-gray-200 text-gray-700 hover:bg-gray-50"
          onClick={refetchTeam}
        >
          { isTeamFetching && !isTeamLoading ? <div className="spinner"></div>: ''}
          Refresh
        </Button>
        {isManageTeamVisible && <Button
          variant="outline"
          className="bg-white border-gray-200 text-gray-700 hover:bg-gray-50"
          onClick={() => setAccessModalOpen(true)}
        >
          Manage Access
        </Button>}
      </div>

      {isSearching && (
        <div className="p-4 bg-white shadow-lg rounded-md border mb-4">
          <h3 className="text-xl font-semibold mb-4">Search Results</h3>
          {searchResults.length > 0 ? (
            <div className="space-y-4">
              {searchResults.map((member) => (
                <div
                  key={member.id}
                  className="flex items-center justify-between p-2 border-b"
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={member.photo_image_title || member.photo}
                      alt={`${member.firstname} ${member.lastname}`}
                      className="w-10 h-10 rounded-full"
                    />
                    <span className="font-medium">
                      {member.firstname
                        ? `${member.firstname} ${member.lastname}`
                        : member.username}
                    </span>
                    <span className="font-medium">{member.email}</span>
                  </div>
                  <Button
                    onClick={() =>
                      addTeam(member.peopleid ? member.peopleid : member.id)
                    }
                  >
                    Add
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <p>No team members found.</p>
          )}
        </div>
      )}

      {/* Desktop Table */}
      <div className="hidden md:block rounded-lg overflow-hidden border border-gray-200 bg-white shadow-sm">
        <Table>
          <TableHeader>
            <TableRow className="border-gray-200 bg-gray-50">
              <TableHead className="text-gray-600 font-medium">#</TableHead>
              <TableHead className="text-gray-600 font-medium">Photo</TableHead>
              <TableHead className="text-gray-600 font-medium">Name</TableHead>
              <TableHead className="text-gray-600 font-medium">
                Contact
              </TableHead>
              <TableHead className="text-gray-600 font-medium">Email</TableHead>
              <TableHead className="text-gray-600 font-medium text-right">
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isTeamFetching && (
              <TableRow className="border-gray-200">
                <TableCell className="text-gray-700">
                  <Skeleton className="w-32 h-4" />
                </TableCell>
                <TableCell>
                  <Skeleton className="w-32 h-4" />
                </TableCell>
                <TableCell className="text-gray-900 font-medium">
                  <Skeleton className="w-32 h-4" />
                </TableCell>
                <TableCell className="text-gray-700">
                  <Skeleton className="w-32 h-4" />
                </TableCell>
                <TableCell className="text-gray-700">
                  <Skeleton className="w-32 h-4" />
                </TableCell>
                <TableCell>
                  <div className="flex items-center justify-end gap-4">
                    <div className="flex items-center gap-2">
                      <Skeleton className="w-32 h-4" />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!isTeamFetching && members?.length == 0 && (
              <TableRow className="border-gray-200">
                <TableCell className="text-gray-700">
                  No Members Found
                </TableCell>
              </TableRow>
            )}
            {!isTeamFetching &&
              members &&
              members.map((item, index) => (
                <TableRow key={item.id} className="border-gray-200">
                  <TableCell className="text-gray-700">{index + 1}</TableCell>
                  <TableCell>
                    <div className="flex text-center mx-auto items-center gap-2 pb-4">
                      <img
                        src={item.photo_image_title || item.photo}
                        className="w-6 h-6 rounded-md"
                      />
                    </div>
                  </TableCell>
                  <TableCell className="text-gray-900 font-medium">
                    {item.firstname
                      ? `${item.firstname} ${item.lastname}`
                      : item.username}
                  </TableCell>
                  <TableCell className="text-gray-700">
                    {item.contact || "-"}
                  </TableCell>
                  <TableCell className="text-gray-700">{item.email}</TableCell>
                  <TableCell>
                    <div className="flex items-center justify-end gap-4">
                      {selectedTeam !== "vendor" && (
                        <div className="flex items-center gap-2">
                          <Switch
                            key={item.peopleid}
                            checked={item.available}
                            onCheckedChange={(checked) =>
                              handleToggleAvailability(item.peopleid, checked)
                            }
                            disabled={loadingStates[item.peopleid]} // Disable switch while loading
                          />
                          <span
                            className={`text-sm ${
                              item.available
                                ? "text-emerald-600"
                                : "text-rose-600"
                            }`}
                          >
                            {item.available ? "Available" : "Unavailable"}
                          </span>
                        </div>
                      )}
                      <Button
                        variant="destructive"
                        size="icon"
                        className="h-8 w-8"
                        onClick={() => {
                          handleMemberRemoval(item.peopleid);
                        }}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      {/* Mobile Cards */}
      <div className="md:hidden space-y-4">
        {members &&
          members.map((item, index) => (
            <div
              className="p-4 border border-gray-200 rounded-lg mb-3 bg-white shadow-sm"
              key={index}
            >
              <div className="flex items-center gap-3 mb-3">
                <img
                  src={item.photo_image_title || item.photo}
                  className="w-6 h-6 rounded-md"
                />
                <div>
                  <div className="font-medium text-gray-900">
                    {" "}
                    {item.firstname
                      ? `${item.firstname} ${item.lastname}`
                      : item.username}
                  </div>
                  <div className="text-sm text-gray-600">{item.email}</div>
                </div>
              </div>

              {item.contact && (
                <div className="mb-3">
                  <div className="text-sm text-gray-600">Contact</div>
                  <div className="text-gray-700">{item.contact || "-"}</div>
                </div>
              )}

              <div className="flex items-center justify-between mt-4">
                {selectedTeam !== "vendor" && (
                  <div className="flex items-center gap-2">
                    <Switch
                      key={item.peopleid}
                      checked={item.available}
                      onCheckedChange={(checked) =>
                        handleToggleAvailability(item.peopleid, checked)
                      }
                      disabled={loadingStates[item.peopleid]} // Disable switch while loading
                    />
                    <span
                      className={`text-sm ${
                        item.available ? "text-emerald-600" : "text-rose-600"
                      }`}
                    >
                      {item.available ? "Available" : "Unavailable"}
                    </span>
                  </div>
                )}
                <Button
                  variant="destructive"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => {
                    handleMemberRemoval(item.peopleid);
                  }}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
      </div>
      {accessModalOpen && <ManageTeam isOpen={accessModalOpen} role={selectedTeam} setIsOpen={setAccessModalOpen}/>}
    </div>
  );
}
