import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { useAuthStore } from "@/store/store";
import useFetch from "@/hooks/useFetchLSD";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";

const EditGuestDetails = ({
  booking,
  isEditDialogOpen,
  setIsEditDialogOpen,
  isEditSheetOpen,
  setIsEditSheetOpen,
  setMembersInfo
}) => {
  const {toast} = useToast();
  const { companyslug } = useAuthStore();
  const [singlememberInfo, setSingleMemberInfo] = React.useState(
    booking?.membersinfo_set
  );
  const [loadingStates, setLoadingStates] = React.useState({});


  const {
    data: pickupPoints,
    error: pickupPointsError,
    isLoading: pickupPointsLoading,
    refetch: pickupPointsRefetch,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/pickup-points/${companyslug}/`,
    {},
    {
      queryOptions: {
        refetchOnMount: false,
        retry: 1,
        enabled: true,
      },
    }
  );

  const handleChange = function (id, field, value) {
    setSingleMemberInfo((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const updateGuestInfo = function (guestID) {
    setLoadingStates((prev) => ({ ...prev, [guestID]: true }));
    const guestInfo = singlememberInfo.find((item) => item.id === guestID);
    (() => {
      try {
        axios

          .post(
            `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
              booking.id
            }/update-guest-detail/`,
            guestInfo
          )
          .then((response) => {
            setLoadingStates((prev) => ({ ...prev, [guestID]: false }));
            if(setMembersInfo){
              setMembersInfo(singlememberInfo);
            }
            toast({
              title: response.data.message,
              variant:"success",
              duration: 2000
            });
          });
      } catch (err) {
        toast({
          title: err.response.data.message,
          variant:"error",
          duration: 3000
        });
      }
    })();
  };

  return (
    <div>
      <Dialog
        className="hidden md:flex"
        open={isEditDialogOpen}
        onOpenChange={setIsEditDialogOpen}
      >
        <DialogContent>
          <DialogHeader className={"text-left"}>
            <DialogTitle>Edit Guest Details</DialogTitle>
          </DialogHeader>
          <div className="text-right">
            {singlememberInfo?.map((member, index) => {
              return (
                <>
                  <Accordion type="single" collapsible>
                    <AccordionItem value="item-1">
                      <AccordionTrigger>
                        {!member.fullname ? (
                          <span>Guest {index + 1}</span>
                        ) : (
                          <span>
                            {member.fullname} - {member.age}y,{" "}
                            {member?.gender?.slice(0, 1).toUpperCase()}
                          </span>
                        )}
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="grid text-left grid-cols-1 lg:grid-cols-2 gap-4">
                          <div>
                            <Label htmlFor="name">Full Name</Label>
                            <Input
                              type="text"
                              id="name"
                              placeholder="Full Name"
                              value={member.fullname}
                              onChange={(e) =>
                                handleChange(
                                  member.id,
                                  "fullname",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              type="email"
                              id="email"
                              placeholder="Email"
                              value={member.email}
                              onChange={(e) =>
                                handleChange(member.id, "email", e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor="contact">Contact</Label>
                            <Input
                              type="number"
                              id="contact"
                              placeholder="Contact"
                              value={member.number}
                              onChange={(e) =>
                                handleChange(
                                  member.id,
                                  "number",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor="age">Age</Label>
                            <Input
                              type="number"
                              id="age"
                              placeholder="Age"
                              value={member.age}
                              onChange={(e) =>
                                handleChange(member.id, "age", e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor="gender">Gender</Label>
                            <Select
                              id="gender"
                              defaultValue={member.gender}
                              onValueChange={(value) =>
                                handleChange(
                                  member.id,
                                  "gender",
                                  value
                                )
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="-" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Male">Male</SelectItem>
                                <SelectItem value="Female">Female</SelectItem>
                                <SelectItem value="Other">Other</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div>
                            <Label htmlFor="pickup">Pickup Points</Label>
                            <Select
                              id="pickup"
                              defaultValue={member.pickup_point}
                              onValueChange={(value) =>
                                handleChange(
                                  member.id,
                                  "pickup_point",
                                  value
                                )
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="--" />
                              </SelectTrigger>
                              <SelectContent>
                                {pickupPoints &&
                                  pickupPoints.map((item, index) => {
                                    return (
                                      <SelectItem
                                        value={item.attribute}
                                        key={index}
                                      >
                                        {item.attribute}
                                      </SelectItem>
                                    );
                                  })}
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                        <Button
                          className="mt-4 min-w-28 w-24"
                          onClick={() => {
                            updateGuestInfo(member.id);
                          }}
                        >
                           {loadingStates[member.id] ? <div className="dots"></div>  : "Update Value"}
                        </Button>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
      <div className="flex md:hidden">
        <Sheet open={isEditSheetOpen} onOpenChange={setIsEditSheetOpen}>
          <SheetContent className="w-full">
            <SheetHeader>
              <SheetTitle>Edit Guest Details</SheetTitle>
            </SheetHeader>
            <div className="flex flex-col gap-2">
              {singlememberInfo.map((member, index) => {
                return (
                  <>
                    <Accordion type="single" collapsible>
                      <AccordionItem value="item-1">
                        <AccordionTrigger>
                          {!member.fullname ? (
                            <span>Guest {index + 1}</span>
                          ) : (
                            <span>
                              {member.fullname} - {member.age}y,{" "}
                              {member?.gender?.slice(0, 1).toUpperCase()}
                            </span>
                          )}
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="grid text-left grid-cols-1 lg:grid-cols-2 gap-4">
                            <div>
                              <Label htmlFor="name">Full Name</Label>
                              <Input
                                type="text"
                                id="name"
                                placeholder="Full Name"
                                value={member.fullname}
                                onChange={(e) =>
                                  handleChange(
                                    member.id,
                                    "fullname",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div>
                              <Label htmlFor="email">Email</Label>
                              <Input
                                type="email"
                                id="email"
                                placeholder="Email"
                                value={member.email}
                                onChange={(e) =>
                                  handleChange(
                                    member.id,
                                    "email",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="flex-[0.9]">
                                <Label htmlFor="contact">Contact</Label>
                                <Input
                                  type="number"
                                  id="contact"
                                  placeholder="Contact"
                                  value={member.number}
                                  onChange={(e) =>
                                    handleChange(
                                      member.id,
                                      "number",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="flex-[0.8]">
                                <Label htmlFor="age">Age</Label>
                                <Input
                                  type="number"
                                  id="age"
                                  placeholder="Age"
                                  value={member.age}
                                  onChange={(e) =>
                                    handleChange(
                                      member.id,
                                      "age",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="flex-[0.8]">
                                <Label htmlFor="gender">Gender</Label>
                                <Select
                                  id="gender"
                                  className="h-8"
                                  value={member.gender}
                                  onValueChange={(value) =>
                                    handleChange(
                                      member.id,
                                      "gender",
                                      value
                                    )
                                  }
                                >
                                  <SelectTrigger>
                                    <SelectValue placeholder="--" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="Male">Male</SelectItem>
                                    <SelectItem value="Female">
                                      Female
                                    </SelectItem>
                                    <SelectItem value="Other">Other</SelectItem>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div>
                              <Label htmlFor="pickup">Pickup Points</Label>
                              <Select
                                id="pickup"
                                value={member.pickup_point}
                                onValueChange={(value) =>
                                  handleChange(
                                    member.id,
                                    "pickup_point",
                                    value
                                  )
                                }
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="--" />
                                </SelectTrigger>
                                <SelectContent>
                                {pickupPoints &&
                                  pickupPoints.map((item, index) => {
                                    return (
                                      <SelectItem
                                        value={item.attribute}
                                        key={index}
                                      >
                                        {item.attribute}
                                      </SelectItem>
                                    );
                                  })}
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </>
                );
              })}
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};

export default EditGuestDetails;
