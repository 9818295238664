import { useAuthStore } from "@/store/store"

export default async function setupAxios(axios) {
  const accessToken = useAuthStore.getState().accessToken
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    (err) => Promise.reject(err)
  )
}
