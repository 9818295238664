import React, { useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { MultiSelect } from "../ui/multi-select";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import { DatePicker } from "../Custom/DatePicker";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import useFetch from "@/hooks/useFetchLSD";
import useCouponStore from "@/store/couponStore";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const EditBulkCouponsForm = ({ open, setIsOpen, refetch }) => {
  const { companyslug } = useAuthStore();
  const { selectedCoupon } = useCouponStore();
  const [options, setOptions] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedEvents, setSelectedEvents] = React.useState([]);
  const [selectedBatches, setSelectedBatches] = React.useState([]);
  const [selectedDiscountType, setSelectedDiscountType] = React.useState();
  const [couponValidityType, setCouponValidityType] = useState("fixed");
  const [formData, setFormData] = useState({
    flow_type: "",
    code: "",
    visible: "private",
    discount: "",
    discount_type: "",
    discount_applicable: "per_person",
    inventory: 0,
    minimum_number_required: null,
    affiliate_cc_email: "",
    valid_from: null,
    valid_until: null,
    days: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const discountOptions = [
    { value: "--", label: "Select Type" },
    { value: "Percentage", label: "Percentage(%)" },
    { value: "Amount", label: "Amount(₹)" },
  ];

  const discountApplicable = [
    { value: "--", label: "Select Per Person/Flat" },
    { value: "per_person", label: "Per Person" },
    { value: "flat", label: "Flat" },
  ];

  const getBatches = (event_list) => {
    const response = axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/coupon/batches/`,
      { events_list: event_list }
    );
    response
      .then((data) => {
        setBatches(data?.data?.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/v3/events-name/${companyslug}/`
        );
        setOptions(response.data);
        const idArray = response.data.map((item) => item.id);
        getBatches(idArray);
      } catch (err) {
        console.error("Error fetching event ads:", err);
      }
    };

    const fetchCouponDetails = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/v3/coupons/${companyslug}/${
            selectedCoupon.id
          }/details/`
        );
        const coupon_details_data = response?.data?.response[0];
        setFormData({
          flow_type: coupon_details_data.flow_type,
          code: coupon_details_data.name,
          discount: coupon_details_data.discount,
          visible: coupon_details_data?.visible,
          discount_type: coupon_details_data.discount_type,
          discount_applicable: coupon_details_data.discount_applicable,
          inventory: coupon_details_data.quantity,
          minimum_number_required: coupon_details_data.minimum_number_required,
          affiliate_cc_email: coupon_details_data.affiliate_cc_email,
          valid_from: coupon_details_data.valid_from
            ? new Date(coupon_details_data.valid_from)
            : "",
          valid_until: coupon_details_data.valid_until
            ? new Date(coupon_details_data.valid_until)
            : "",
          days: coupon_details_data?.days,
        });
        console.log("Coupon Details:", coupon_details_data);
        setSelectedDiscountType(coupon_details_data.discount_type);
        setSelectedEvents(coupon_details_data?.events);
        setSelectedBatches(coupon_details_data?.batches);
      } catch (err) {
        console.error("Error fetching coupon details:", err);
      }
    };

    fetchEvent();
    fetchCouponDetails();
  }, []);

  const selectEvents = (e) => {
    setSelectedEvents(e);
    if (flow_type == "batch_level") {
      getBatches(e);
    }
  };

  const selectBatches = (e) => {
    setSelectedBatches(e);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.code) formErrors.code = "Coupon Code is required";
    if (formData.discount_type === "--")
      formErrors.discount_type = "Please select a discount type";
    if (!formData.inventory || formData.inventory <= 0)
      formErrors.inventory = "Inventory must be greater than 0";
    // if (!formData.minimum_number_required || formData.minimum_number_required <= 0) formErrors.minimum_number_required = "Group size must be greater than 0";
    // if (
    //   !formData.affiliate_cc_email ||
    //   !/\S+@\S+\.\S+/.test(formData.affiliate_cc_email)
    // )
    //   formErrors.affiliate_cc_email = "Valid email is required";
    if (!formData.valid_from)
      formErrors.valid_from = "Valid From date is required";

    if (couponValidityType === "fixed" && !formData.valid_until) {
      formErrors.valid_until = "Valid Till date is required";
    } else if (couponValidityType === "relative" && !formData.days) {
      formErrors.days = "Days field is required for relative validity type";
    }

    return formErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setLoading(true)
      console.log("Form Data:", formData);
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/edit-coupons/${companyslug}/${
            selectedCoupon.id
          }/`,
          {
            coupon_details: formData,
            batch_list: selectedBatches,
            event_list: selectedEvents,
          }
        );
        setFormData({
          flow_type: formData?.flow_type,
          code: "",
          discount: "",
          discount_type: "",
          inventory: "",
          minimum_number_required: null,
          affiliate_cc_email: "",
          valid_from: null,
          valid_until: null,
          days: "",
        });
        setSelectedBatches([]);
        setSelectedEvents([]);
        setLoading(false)
        setIsOpen(false);
        refetch()
        // handleSubmit();
      } catch (error) {
        console.error("Error updating item status:", error);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DialogContent
        className={
          "lg:max-w-2xl overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
        }
      >
        <DialogHeader>
          <DialogTitle>Edit Coupon</DialogTitle>
        </DialogHeader>
        <form
          onSubmit={handleFormSubmit}
          className="grid grid-cols-2 gap-4 py-4"
        >
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="code">Coupon Code</Label>
            <Input
              className="h-8"
              type="text"
              id="code"
              placeholder="Code"
              value={formData.code}
              onChange={handleInputChange}
            />
            {errors.code && (
              <p className="text-red-500 text-sm">{errors.code}</p>
            )}
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="disc_type">Discount Type</Label>
            {/* <ComboboxDemo
                onChange={(value) => setFormData({ ...formData, discount_type: value })}
                id="discount_type"
                options={discountOptions}
                // defaultValue={selectedDiscountType}
                selectedOption={selectedDiscountType}
                /> */}
            <Select
              value={formData.discount_type}
              onValueChange={(value) =>
                setFormData({ ...formData, discount_type: value })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={`${selectedDiscountType}`} />
              </SelectTrigger>
              <SelectContent>
                {discountOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {errors.discount_type && (
              <p className="text-red-500 text-sm">{errors.discount_type}</p>
            )}
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="disc_type">Discount Applicable</Label>
            {/* <ComboboxDemo
              onChange={(value) =>
                setFormData({ ...formData, discount_applicable: value })
              }
              id="discount_applicable"
              options={discountApplicable}
              selectedValue={formData.discount_applicable}
            /> */}
             <Select
              value={formData.discount_applicable}
              onValueChange={(value) =>
                setFormData({ ...formData, discount_applicable: value })
              }
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {discountApplicable.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="code">Discount</Label>
            <Input
              className="h-8"
              type="text"
              id="discount"
              placeholder="Discount"
              value={formData.discount}
              onChange={handleInputChange}
            />
            {errors.discount && (
              <p className="text-red-500 text-sm">{errors.discount}</p>
            )}
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="inventory">Coupon Inventory</Label>
            <Input
              className="h-8"
              type="number"
              id="inventory"
              placeholder="Inventory"
              value={formData.inventory}
              onChange={handleInputChange}
            />
            {errors.inventory && (
              <p className="text-red-500 text-sm">{errors.inventory}</p>
            )}
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="group-size">Group Size for discount</Label>
            <Input
              className="h-8"
              type="number"
              id="minimum_number_required"
              placeholder="Group Size"
              value={formData.minimum_number_required}
              onChange={handleInputChange}
            />
            {errors.minimum_number_required && (
              <p className="text-red-500 text-sm">
                {errors.minimum_number_required}
              </p>
            )}
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="affiliate-email">Affiliate Email</Label>
            <Input
              className="h-8"
              type="email"
              id="affiliate_cc_email"
              placeholder="Email"
              value={formData.affiliate_cc_email}
              onChange={handleInputChange}
            />
            {errors.affiliate_cc_email && (
              <p className="text-red-500 text-sm">
                {errors.affiliate_cc_email}
              </p>
            )}
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="coupon-type">Coupon Type</Label>
            <RadioGroup
              onValueChange={(value)=>{setFormData({ ...formData, visible: value })}}
              className="grid-cols-2"
              id="date-type"
              defaultValue={formData.visible}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="private" id="private" />
                <Label htmlFor="private">Private</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="public" id="public" />
                <Label htmlFor="public">Public</Label>
              </div>
            </RadioGroup>
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="valid_from">Valid From</Label>
            <DatePicker
              id="valid_from"
              defaultDate={formData.valid_from}
              onChange={(date) => {
                console.log(date);
                setFormData({ ...formData, valid_from: date });
              }}
            />
            {errors.valid_from && (
              <p className="text-red-500 text-sm">{errors.valid_from}</p>
            )}
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="date-type">Validity Type</Label>
            <RadioGroup
              onValueChange={setCouponValidityType}
              className="grid-cols-2"
              id="date-type"
              defaultValue="fixed"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="fixed" id="fixed-date" />
                <Label htmlFor="fixed-date">Fixed Date</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="relative" id="relative-date" />
                <Label htmlFor="relative-date">Relative Date</Label>
              </div>
            </RadioGroup>
          </div>

          {couponValidityType === "fixed" ? (
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="valid_until">Valid Till</Label>
              <DatePicker
                id="valid_until"
                defaultDate={formData.valid_until}
                onChange={(date) =>
                  setFormData({ ...formData, valid_until: date })
                }
              />
              {errors.valid_until && (
                <p className="text-red-500 text-sm">{errors.valid_until}</p>
              )}
            </div>
          ) : (
            <div className="grid w-full col-span-2 items-center gap-1.5">
              <Label htmlFor="coupon-days">Days</Label>
              <Input
                className="h-8"
                type="number"
                id="days"
                placeholder="Days"
                value={formData.days}
                onChange={handleInputChange}
              />
              {errors.days && (
                <p className="text-red-500 text-sm">{errors.days}</p>
              )}
            </div>
          )}
          {(formData?.flow_type == "event_level" ||
            formData?.flow_type == "batch_level") && (
            <div className="grid w-full col-span-2 items-center gap-1.5">
              <Label htmlFor="events">Select Events</Label>
              <MultiSelect
                options={options?.map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
                className="w-full"
                placeholder="Select Events"
                defaultValue={selectedEvents}
                onValueChange={selectEvents}
              />
              {errors.days && (
                <p className="text-red-500 text-sm">{errors.days}</p>
              )}
            </div>
          )}
          {formData?.flow_type == "batch_level" && (
            <div className="grid w-full col-span-2 items-center gap-1.5">
              <Label htmlFor="events">Select Batches</Label>
              <MultiSelect
                options={batches?.map((batch) => ({
                  label: batch?.name,
                  value: batch?.id,
                }))}
                className="w-full"
                placeholder="Select Events"
                defaultValue={selectedBatches}
                onValueChange={selectBatches}
              />
              {errors.days && (
                <p className="text-red-500 text-sm">{errors.days}</p>
              )}
            </div>
          )}
          <div className="col-span-2 flex">
            <Button className="ml-auto" type="submit">Submit</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditBulkCouponsForm;
