import React from 'react';
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/ui/table";
import { PenSquare, Trash2, RotateCw, Plus } from "lucide-react";
import { Separator } from './ui/separator';
import { useAuthStore } from '@/store/store';


const reviews = [
  {
    id: 1,
    title: "Duplicate Trip 2",
    author: "Manoj Jain"
  },
  {
    id: 2,
    title: "Duplicate Trip",
    author: "Manoj Jain"
  },
  {
    id: 3,
    title: "None",
    author: "Premal Matalia"
  },
  {
    id: 4,
    title: "THE COAST - GETAWAY AT GOKARNA",
    author: "Manoj Jain"
  },
  {
    id: 5,
    title: "LADAKH UNVEILED: A HIGH-ALTITUDE ODYSSEY",
    author: "The Nomad"
  }
];

export function ReviewsTable() {
  const {companyslug} = useAuthStore()
  return (
    <div className="w-full">
      {/* Header */}
      <div className="lg:flex justify-between items-center mb-4">
        <h2 className="section_heading">Reviews</h2>
        <div className="flex gap-2">
          <Button 
            variant="outline" 
            className="flex items-center gap-2 bg-black hover:bg-black text-white"
          >
            
            Refresh
          </Button>
          <Button 
          onClick={() => {window.open(`https://logout.world/dashboard/${companyslug}/manually-add-review/`)}}
            className="flex items-center gap-1 bg-black hover:bg-black text-white"
          >
            <Plus className="h-4 w-4" />
            Create A Review
          </Button>
        </div>
      </div>

      {/* Table */}
      <div>
      {reviews.map((review) => (
        <div>
              <div className='flex justify-between items-center' key={review.id}>
                <div className="py-4">
                  <div className="text-sm ">
                    {review.title} | <span className="text-gray-500">{review.author}</span>
                  </div>
                </div>
                <div className="text-left">
                  <div className="flex justify-end gap-2">
                    <Button 
                      variant="secondary" 
                      className="flex items-center gap-2"
                    >
                      <PenSquare className="h-4 w-4" />
                      Edit
                    </Button>
                    <Button 
                      variant="secondary"
                      className="flex items-center gap-2 bg-red-100 hover:bg-red-200 text-red-600"
                    >
                      <Trash2 className="h-4 w-4" />
                      Delete
                    </Button>
                  </div>
                </div>
                
              </div>
              <Separator />
              </div>
            ))}
      </div>
    </div>
  );
}