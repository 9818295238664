import React, { useState, useEffect } from "react";
import { BsChatText } from "react-icons/bs";
import { Separator } from "@/components/ui/separator";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import Stage from "./Stage";
import SortableTaskItem from "./SortableTaskItem";

import "./styles.css";
import { Trash } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useAuthStore } from "@/store/store";
import axios from "axios";

export const Store = function ({ stages, setFreeStage }) {
  const {companyslug} = useAuthStore();
  const {toast} = useToast();
  const [freeStage, setFreeStages] = useState(null);

  useEffect(() => {
    setFreeStages(stages);
  }, [stages]);

  const { setNodeRef } = useDroppable({
    id: "store",
  });

  const deleteStage = function(stage_id){
    var formData = {'stage_id':stage_id}
    toast({
      title: "Deleting Stage",
      variant: "laoding",
      duration: 2000,
    });
    try {
      axios
        .post(
          `${import.meta.env.VITE_APP_API_URL}/v3/chatbot-flow/${companyslug}/${stage_id}/delete-stage/`,
          formData
        )
        .then((response) => {
          toast({
            title: "Stage Deleted",
            variant: "success",
            duration: 2000,
          });
          setFreeStage((prevStage) =>
            prevStage.filter((item) =>
              item.id.toString() !== stage_id.toString()
            )
          );
        });
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  }

  return (
    freeStage && (
      <div>
        <SortableContext
          id="store"
          items={freeStage}
          strategy={verticalListSortingStrategy}
        >
          <div ref={setNodeRef}>
            {freeStage.map((stage) => (
              <SortableTaskItem id={stage.id} key={stage.id}>
                <div className="mt-4 mb-4">
                  {/* <Stage data={stage}/> */}
                  {/* <div
                                  className="appnode"
                                //   onDragStart={(event) => onDragStart(event, name)}
                                  draggable
                                >
                                  <BsChatText style={{ fontSize: "15px", margin: "5px" }} />
                                  {stage.name}
                                </div> */}
                  <div
                    className={`relative p-4 bg-white rounded-xl shadow-md border px-auto items-center`}
                  >
                    {/* Header section */}
                    <div className="flex items-center justify-between">
                      <div className="text-center items-center px-auto">
                        <span className="font-semibold text-gray-800">
                          {stage.name}
                        </span>
                      </div>
                      {stage.master === "USER" && (
                        <button 
                        onPointerDown={(e) => e.stopPropagation()} 
                        onClick={()=>{deleteStage(stage.id)}}
                        className="text-red-500 hover:text-red-700 relative">
                          <Trash size={16} />
                        </button>
                      )}
                    </div>

                    {/* Message content */}
                    {/* <div className="mt-3 text-gray-600 text-sm">
                      {stage?.meta_data?.description || "This is a chat node."}
                    </div> */}
                  </div>
                </div>
              </SortableTaskItem>
            ))}
          </div>
        </SortableContext>
      </div>
    )
  );
};
