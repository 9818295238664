import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { CloudUpload, Paperclip } from "lucide-react";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";
import useLeadsStore from "@/store/leadsStore";

const AddLeadCSV = ({ isOpen, setIsOpen }) => {
  const [file, setFile] = useState();
  const fileRef = useRef();
  const {companyslug} = useAuthStore()
  const [loading, setLoading] = useState()
  const {refetchApi} = useLeadsStore()
  const {toast} = useToast()

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadLeadCSV = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('company_enquiry_list_csv', file)
    try{
    const res = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/update-company-enquiries-csv/${companyslug}/`,formData)
    if(res.status == 200){
        toast({
            title: "Updated Successfully",
            variant:"success",
            duration: 2000
          });
    }
    }
    catch(err){
        console.log(err)
        toast({
            title: err.response.data.message,
            variant:"error",
            duration: 2000
          });
    }
    setLoading(false)
    setIsOpen(false)
    refetchApi()
  };

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={"overflow-y-scroll max-h-[90vh]"}>
          <DialogHeader>
            <DialogTitle>Add Enquiries</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4 ">
            <div className="flex flex-col gap-2">
              <h2 className="font-normal text-base">
                Keep the exact column names (headers) in lower case
              </h2>
              <h2 className="font-medium text-base">
                Caution: All names are CASE SENSITIVE
              </h2>
              <Button
                className="self-start"
                variant="outline"
                onClick={() => {
                  window.open(
                    `${import.meta.env.VITE_APP_API_URL}/static/sample-csv/enquiry_sample_v2.csv`, '_blank'
                  );
                }}
              >
                Download Sample CSV
              </Button>
            </div>
            <input
              onChange={handleFileChange}
              type="file"
              accept=".csv"
              ref={fileRef}
              style={{ display: "none" }}
            />
            <div
              onClick={() => fileRef.current.click()}
              className="border-dashed rounded-md flex flex-col gap-1 justify-between items-center border-2 p-12"
            >
              <CloudUpload size={24} />
              <h2>Click to upload leads CSV</h2>
              <span className="text-xs text-muted-foreground">
                Supported Formats: .csv
              </span>
            </div>
            {file && (
              <div className="flex p-2 bg-gray-100 rounded-md gap-2 items-center">
                <Paperclip size={20} />
                {file.name}
              </div>
            )}
            <div className="flex justify-end">
              <Button className="w-24" disabled={!file} onClick={uploadLeadCSV}>
                {loading ? <div className="dots"></div> : 'Update' }
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddLeadCSV;
