import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { DatePicker } from "@/components/Custom/DatePicker";
import { Input } from "@/components/ui/input";
import { CirclePlus, Trash } from "lucide-react";
import { Button } from "@/components/ui/button";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import { useQueryClient } from "react-query";

const AddMember = ({isOpen,setIsOpen}) => {
  const [members, setMembers] = useState([
    {
      name: '',
      email: '',
      mobile: '',
      valid_from: '',
      valid_until: '',  
    }
  ])
  const queryClient = useQueryClient();
  const {companyslug} = useAuthStore();
  const { data:membership_plans, error, isFetching, refetch } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/get-membership-plans/?companyslug=${companyslug}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    },
    'memberships'
  );

  const handleAddMember = () => {
    setMembers([...members, {name: '', email: '', contact: '', plan: '', from: '', till: ''}])
  }

  const handleRemoveMember = (index) => {
    const values = [...members];
    values.splice(index, 1);
    setMembers(values);
  }

  const handleChange = (index, field, value) => {
    const values = [...members];
    values[index][field] = value;
    setMembers(values);
  }

  const addMembers = async () => {
    const res = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/add-member/${companyslug}/`, JSON.stringify({new_member_list:members}))
    console.log(res.data) 
    queryClient.invalidateQueries('members')
    setIsOpen(false)
  }

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={
            "lg:max-w-xl overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }>
          <DialogHeader>
            <DialogTitle>Add Member</DialogTitle>
          </DialogHeader>
         <div>
          {
            members.map((member, index) => (
              <NewMemberForm plans={membership_plans} handleChange={handleChange} handleAddMember={handleAddMember} handleRemoveMember={handleRemoveMember} index={index} maxlength={members.length}/>
            ))
          }
          <div className="flex justify-end pt-4">
            <Button onClick={addMembers}>Update</Button>
          </div>
         </div>
        </DialogContent>
      </Dialog>
      
    </div>
  );
};

export default AddMember;


const NewMemberForm = ({member,index,plans, maxlength, handleAddMember, handleRemoveMember, handleChange}) => {
    return (
        <div className="flex flex-col gap-4 py-4">
        <div className="flex justify-between">
          <Label>Full Name</Label>
          <Input onChange={(e)=>handleChange(index,"name",e.target.value)} className="max-w-48" type="text" placeholder="Full Name" />
        </div>
        <div className="flex justify-between">
          <Label>Email Address</Label>
          <Input onChange={(e)=>handleChange(index,"email",e.target.value)} className="max-w-48" type="email" placeholder="Email Address" />
        </div>
        <div className="flex justify-between">
          <Label>Contact No.</Label>
          <Input onChange={(e)=>handleChange(index,"mobile",e.target.value)} placeholder="Contact" className="max-w-48" type="text" />
        </div>
        <div className="flex justify-between">
          <Label>Associated Plan</Label>
          <Select onValueChange={(e)=>handleChange(index,"associatedPlan",e)} className="max-w-48">
            <SelectTrigger className="max-w-48">
              <SelectValue placeholder="Select a plan" />
            </SelectTrigger>
            <SelectContent>
              {plans?.map((plan) => (
                <SelectItem key={plan.id} value={plan}>
                  {plan.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex justify-between">
          <Label>From</Label>
          <div className="w-48">
          <DatePicker onChange={(value)=>handleChange(index,"valid_from",new Date(value))} />
          </div>
        </div>
        <div className="flex justify-between">
          <Label>To</Label>
          <div className="w-48">
          <DatePicker onChange={(value)=>handleChange(index,"valid_until",new Date(value))} />
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          {index==maxlength-1 && <span onClick={handleAddMember} className="flex cursor-pointer items-center gap-1 text-sm underline">
            <CirclePlus size={16} />
            Add another
          </span>}
          <span onClick={()=>handleRemoveMember(index)} className="flex items-center cursor-pointer gap-1 text-sm underline">
            <Trash size={16} />
            Remove
          </span>
        </div>
      </div>
    )
}
