import React, {  useEffect, useState } from "react";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { Chatbot } from "@/components/chatbot/Chatbot";
import { Progress } from "@/components/ui/progress";
import axios from "axios";

export function ProgressDemo() {
  const [progress, setProgress] = React.useState(13);

  React.useEffect(() => {
    const timer = setTimeout(() => setProgress(99), 500);
    return () => clearTimeout(timer);
  }, []);

  return <Progress value={progress} className="w-[60%] h-2" />;
}

const AIChatbotPage = () => {
  const { companyslug } = useAuthStore();
  const [chatFlow, setChatFlows] = useState([]);
  const [isFlowSelected, setIsFlowSelected] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedFlowID, setSelectedFlowID] = useState(null);
  const {
    data: flows,
    error: error,
    isLoading: loading,
    refetch: refetch,
    isFetching: flowFetching,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/chatbot-flow/${companyslug}/`,
    {
      // ...filters,
      // ...bookingDateFilters,
      // ...eventDateFilters,
    },
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    setChatFlows(flows);
  }, [flows]);

  const handleFlowSelection = (id) => {
    setIsFlowSelected(true);
    try {
      axios
        .get(
          `${
            import.meta.env.VITE_APP_API_URL
          }/v3/chatbot-flow/${companyslug}/${id}/`
        )
        .then((response) => {
          setSelectedFlow(response.data);
          return { data: response.data, isError: false, error: null };
        });
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  };

  return (
    <>
      {!isFlowSelected ? (
        <div className="flex gap-4">
          {chatFlow &&
            chatFlow.map((flow, index) => {
              return (
                <div className="flex " key={index}>
                  <div
                    className="cursor-pointer bg-white rounded-lg shadow-md p-6 transform transition duration-200 hover:scale-105 hover:shadow-xl"
                    onClick={() => handleFlowSelection(flow?.id)}
                  >
                    <h2 className="text-xl font-bold text-gray-800">
                      {flow.name}
                    </h2>
                    <p className="mt-2 text-gray-600">{flow.description}</p>
                    <div className="mt-4 flex items-center justify-between">
                      <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                        {/* {flow.status} */}
                      </span>
                      {/* Additional info or an icon can be added here */}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          className="appflow pt-0 "
          style={{ width: "80vw", height: "120vh" }}
        >
          {selectedFlow ? (
            <Chatbot
              flowData={selectedFlow}
              setIsFlowSelected={setIsFlowSelected}
              setSelectedFlow={setSelectedFlow}
            />
          ) : (
            <div className="flex items-center justify-center mb-48 w-full h-full">
              <ProgressDemo size={48}/>
            </div>
          )}
        </div>
      )}
    </>
    // <div className="appflow pt-0" style={{ width: "80vw", height: "120vh" }}>
    //   {flowData && (
    //     <ReactFlowProvider>
    //       <AiChatbot apiData={flowData[0]} refetch={refetchFlow} />
    //     </ReactFlowProvider>
    //   )}
    // </div>
  );
};

export default AIChatbotPage;
