"use client";

import * as React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { ArrowUpDown, ChevronDown, MoreHorizontal, SquarePen } from "lucide-react";
import {DateRangePicker} from "@/components/Custom/DateRangePicker"
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Search } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ListFilter } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { MetricCard } from "@/components/card-info";
import EditMemberships from "./membership-plans-edit";
import { useQueryClient } from "react-query";

const data = [
  {
    serialno: 1,
    id: "3u1reuv4",
    amount: 316,
    name: "Riya",
    email: "ken99@yahoo.com",
    status: "Success",
    paymentMethod: "Credit Card",
    date: "2025-01-01",
    location: "New York",
    contact: "1234567890",
    remarks:  <SquarePen />,
  },
  {
    serialno: 2,
    id: "5b23reuv4",
    amount: 242,
    name: "Neha",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks: <SquarePen />,
  },
  {
    serialno: 3,
    id: "5b23reuv4",
    amount: 242,
    name: "Ritu",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks: <SquarePen />,
  },
  {
    serialno: 4,
    id: "5b23reuv4",
    amount: 242,
    name: "Akshay",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks:  <SquarePen />,
  },
  {
    serialno: 5,
    bookingid: 5,
    id: "5b23reuv4",
    amount: 242,
    name: "SAURABH MISHRA",
   
  
  },
  // Add more rows as needed
];

const columns = [
  {
    accessorKey: "serialno",
    header: "#",
    cell: ({ row }) => <div>{row.index+1}</div>,
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
  },
//   {
//     accessorKey: "weight",
//     header: "Weight",
//     cell: ({ row }) => <div>{row.getValue("weight") || "N/A"}</div>,
//   },
{
    accessorKey: "selling",
    header: "Selling Price",
    cell: ({ row }) => <div>{row.getValue("selling") || "N/A"}</div>,
  },
  {
    accessorKey: "discount",
    header: "Discount",
    cell: ({ row }) => <div>
      {row.original.discount_type === "Amount" ? "₹" : ""}
      {row.getValue("discount") || "N/A"}
      {row.original.discount_type === "Percentage" ? "%" : ""}
      </div>,
  },

  {
    id: "actions",
    header: "Action",
    cell: ({ row }) => {
      const queryClient = useQueryClient();
    
      const removePlan = (id) => {
        axios
          .post(
            `${import.meta.env.VITE_APP_API_URL}/v3/delete-membership-plan/${id}/`,
          )
          .then((res) => {
            console.log(res);
            queryClient.invalidateQueries('memberships')
          });
      };

      return (
        <DropdownMenu>
        <DropdownMenuTrigger>
          <Button>Actions</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={()=>{
            window.open(`${import.meta.env.VITE_APP_API_URL}/tours/${row.original.id}/edit-membership-plan-email-message`)
          }}>Edit Email</DropdownMenuItem>
          <DropdownMenuItem onSelect={()=>{removePlan(row.original.id)}}>Delete</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      );
    },
  },
];


export default function Membership() {
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [isEditModalOpen,setIsModalOpen] = React.useState(false);

  const {
    companyslug
  } = useAuthStore()
  const { data:membership_plans, error, isFetching, refetch } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/get-membership-plans/?companyslug=${companyslug}`,
    {},
    {
      // loadedMore: loadedMore,
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: true,
        keepPreviousData: true,
      },
    },
    'memberships'
  );
  const table = useReactTable({
    data:membership_plans?.length>0 && membership_plans,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full">
      <div>
        <h1 className="section_heading">Membership Plans</h1>
      </div>
<div className="flex items-end py-4 justify-between">
  <div className="flex-grow max-w-xs">
      <MetricCard value={membership_plans?.length} title="Active Plans" />
      </div>
      <Button onClick={()=>{setIsModalOpen(true)}}>Add/Edit Plans</Button>
     </div>
<EditMemberships open={isEditModalOpen} setIsOpen={setIsModalOpen}/>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="bg-neutral-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead className="py-2" key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of {" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div> */}
    </div>
  );
}

