import React, { useEffect, useRef, useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Separator } from "../ui/separator";
import WidgetPreview from "./preview";
import { Button } from "../ui/button";
import { useAuthStore } from "@/store/store";
import { useActionState } from "react";
import axios from "axios";
import { CircleCheck, CircleCheckBig, Eye, RotateCcw, X } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import BannerStyling from "./banner-styling";
import BannerPreview from "./BannerPreview";
import BookNowCodeModal from "./bookNowCode";

const Checkout = () => {
  const [styles, setStyles] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bannerStyles, setBannerStyles] = useState({
    bookNowButtonStyling: {
      bgColor: "#005942",
      textColor: "#ffffff",
      text: "Book Now",
      visible: true,
      customId: "",
    },
    itineraryButtonStyling: {
      bgColor: "#000000",
      textColor: "#ffffff",
      text: "Itinerary",
      visible: true,
      customId: "",
    },
    enquiryButtonStyling: {
      bgColor: "#000000",
      textColor: "#ffffff",
      text: "Enquire",
      visible: true,
      customId: "",
    },
    showOnlyButtons: false,
    showEverything: true,
  });
  const [buttonColor, setButtonColor] = useState("#ffffff");
  const [textColor, setTextColor] = useState("#000000");
  const [buttonTextColor, setButtonTextColor] = useState("#000000");
  const [borderColor, setBorderColor] = useState("#e5e5e5");
  const [borderRadius, setBorderRadius] = useState("rounded");
  const [activeTab, setActiveTab] = useState("checkout");
  const buttonColorRef = useRef();
  const buttonTextColorRef = useRef();
  const textColorRef = useRef();
  const borderColorRef = useRef();
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [isSaved, setIsSaved] = useState(false);
  const { companyslug } = useAuthStore();

  const getWidgetStyling = async () => {
    const res = await axios.get(
      `${import.meta.env.VITE_APP_API_URL}/v3/customise-widget/${companyslug}`
    );
    // console.log(res.data)
    // setStyles(res.data)
    setButtonColor(res.data.ctaButtonColor);
    setTextColor(res.data.fontColor);
    setButtonTextColor(res.data.ctaButtonTextColor);
    setBorderColor(res.data.borderColor);
    setBorderRadius(res.data.borderRadius);
    setBannerStyles({
      ...bannerStyles,
      bookNowButtonStyling: {
        bgColor: res.data.bookNowButtonColor,
        text: res.data.booknowButtonName,
        customId: res.data.btnId,
        textColor: res.data.booknowTextColor,
      },
      itineraryButtonStyling: {
        bgColor: res.data.itineraryButtonColor,
        text: res.data.itineraryButtonName,
        customId: res.data.downloadBtnId,
        visible: !!res.data.showItineraryButton.toLowerCase(),
        textColor: res.data.itineraryTextColor,
      },
      enquiryButtonStyling: {
        bgColor: res.data.enquiryButtonColor,
        text: res.data.enquiryButtonName,
        customId: res.data.enquiryBtnId,
        visible: !!res.data.showEnquiryButton.toLowerCase(),
        textColor: res.data.enquiryTextColor,
      },
      showOnlyButtons: res.data.onlyButton.toLowerCase(),
      showEverything: res.data.showEverything.toLowerCase(),
    });
  };

  useEffect(() => {
    getWidgetStyling();
  }, []);

  const saveWidgetStyling = async () => {
    setIsSaving(true);

    const res = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/customise-booking-widget/${companyslug}/?q=checkout`,
      styles
    );
    if (res.status == 200) {
      if(isResetting){
        setIsReset(true)
      }
      else
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 1500);
    }
    setIsSaving(false);
    setIsResetting(false)
  };

  useEffect(()=>{
    console.log("reset",isReset)
  },[isReset])

  const saveBannerStyling = async (styles) => {
    setIsSaving(true);
    console.log(styles)
    var styling = {};
    if (styles) {
      styling = {
        bookNowButtonColor: styles.bookNowButtonStyling.bgColor,
        booknowButtonName: styles.bookNowButtonStyling.text,
        booknowTextColor: styles.bookNowButtonStyling.textColor,
        btnId: styles.bookNowButtonStyling.customId,
        itineraryButtonColor: styles.itineraryButtonStyling.bgColor,
        itineraryButtonName: styles.itineraryButtonStyling.text,
        itineraryTextColor: styles.itineraryButtonStyling.textColor,
        downloadBtnId: styles.itineraryButtonStyling.customId,
        showItineraryButton: styles.itineraryButtonStyling.visible,
        enquiryButtonName: styles.enquiryButtonStyling.text,
        enquiryBtnId: styles.enquiryButtonStyling.customId,
        enquiryButtonColor: styles.enquiryButtonStyling.bgColor,
        enquiryTextColor: styles.enquiryButtonStyling.textColor,
        showEnquiryButton: styles.enquiryButtonStyling.visible,
        onlyButton: styles.showOnlyButtons,
        showEverything: styles.showEverything,
      };
    } else {
      styling = {
        bookNowButtonColor: bannerStyles.bookNowButtonStyling.bgColor,
        booknowButtonName: bannerStyles.bookNowButtonStyling.text,
        booknowTextColor: bannerStyles.bookNowButtonStyling.textColor,
        btnId: bannerStyles.bookNowButtonStyling.customId,
        itineraryButtonColor: bannerStyles.itineraryButtonStyling.bgColor,
        itineraryButtonName: bannerStyles.itineraryButtonStyling.text,
        itineraryTextColor: bannerStyles.itineraryButtonStyling.textColor,
        downloadBtnId: bannerStyles.itineraryButtonStyling.customId,
        showItineraryButton: bannerStyles.itineraryButtonStyling.visible,
        enquiryButtonName: bannerStyles.enquiryButtonStyling.text,
        enquiryBtnId: bannerStyles.enquiryButtonStyling.customId,
        enquiryButtonColor: bannerStyles.enquiryButtonStyling.bgColor,
        enquiryTextColor: bannerStyles.enquiryButtonStyling.textColor,
        showEnquiryButton: bannerStyles.enquiryButtonStyling.visible,
        onlyButton: bannerStyles.showOnlyButtons,
        showEverything: bannerStyles.showEverything,
      };
    }
    const res = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/customise-booking-widget/${companyslug}/?q=banner`,
      styling
    );
    if (res.status == 200) {
      console.log(isResetting)
      if(isResetting){
        setIsReset(true)
      }
      else
      {
      setIsSaved(true);
      }
      setTimeout(() => {
        setIsSaved(false);
      }, 1500);
    }
    setIsSaving(false);
    setIsResetting(false)
  };

  useEffect(() => {
    setStyles({
      ...styles,
      buttonColor: buttonColor,
      textColor: textColor,
      buttonTextColor: buttonTextColor,
      borderRadius: borderRadius,
      borderColor: borderColor,
    });
  }, [buttonColor, textColor, buttonTextColor, borderRadius, borderColor]);

  const resetToDefault = () => {
    setIsResetting(true)
    if (activeTab == "checkout") {
      setButtonColor("#005942");
      setTextColor("#232323");
      setButtonTextColor("#ffffff");
      setBorderRadius("rounded");
      setBorderColor("#e5e5e5");
      saveWidgetStyling();
    } else {
      setBannerStyles({
        bookNowButtonStyling: {
          bgColor: "#1bb519",
          textColor: "#ffffff",
          text: "Book Now",
          visible: true,
          customId: "logout-bnb",
        },
        itineraryButtonStyling: {
          bgColor: "#ff6161",
          textColor: "#ffffff",
          text: "Itinerary",
          visible: true,
          customId: "logout-download-button",
        },
        enquiryButtonStyling: {
          bgColor: "#ff6161",
          textColor: "#ffffff",
          text: "Enquire",
          visible: true,
          customId: "logout-enquiry-button",
        },
        showEverything: true,
        showOnlyButtons: false,
      });
      const updated = {...bannerStyles}
      updated.bookNowButtonStyling = {
        bgColor: "#1bb519",
        textColor: "#ffffff",
        text: "Book Now",
        visible: true,
        customId: "logout-bnb",
      }
      updated.itineraryButtonStyling = {
        bgColor: "#ff6161",
        textColor: "#ffffff",
        text: "Itinerary",
        visible: true,
        customId: "logout-download-button",
      }
      updated.enquiryButtonStyling = {
        bgColor: "#ff6161",
        textColor: "#ffffff",
        text: "Enquire",
        visible: true,
        customId: "logout-enquiry-button",
      }
      updated.showEverything = true
      updated.showOnlyButtons = false
      saveBannerStyling(updated);
      
    }
  };

  


  return (
    <div>
      <div className="flex md:flex-row flex-col mb-2 md:items-center justify-between">
        <h2 className="section_heading">Checkout Styling</h2>
        <div className="flex items-center mt-4 md:mt-0 space-x-3 mr-8">
          <Button variant="outline" onClick={resetToDefault}>
            {isResetting ? <div className="spinner"></div> : <RotateCcw size={14} />}
          </Button>
          <Button
            variant="outline"
            onClick={
              ()=>{
              activeTab == "checkout" ? saveWidgetStyling() : saveBannerStyling()
              }
            }
            className="w-32"
          >
            {isSaving && !isResetting ? <div className="dots_black" /> : isSaved && !isReset ? <div className="flex items-center gap-1">
              <CircleCheckBig size={14} color="#000" />
              Saved
            </div> : 'Save Changes'}
          </Button>
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Generate Code
          </Button>
        </div>
      </div>
      <div className="flex items-start justify-between">
        <div className="flex-grow md:mr-8">
          <div className="flex flex-col gap-8 py-4">
            <Tabs
              onValueChange={(value) => {
                setActiveTab(value);
              }}
              defaultValue="checkout"
              className="w-full"
            >
              <TabsList className="w-full">
                <TabsTrigger className="w-full" value="checkout">
                  Checkout Styling
                </TabsTrigger>
                <TabsTrigger className="w-full" value="banner">
                  Banner Styling
                </TabsTrigger>
              </TabsList>
              <TabsContent value="checkout">
                <div className="md:pt-0 pt-4 relative flex flex-col gap-8">
                  <section className="button_customization pt-6">
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
                      <div className="card_custom_body flex items-center gap-2">
                        <div
                          onClick={() => {
                            buttonColorRef.current.click();
                          }}
                          style={{ background: buttonColor }}
                          className="color_preview w-16 h-12 rounded-lg relative border-2"
                        >
                          <div className="absolute top-1/2 left-1/2">
                            <input
                              ref={buttonColorRef}
                              //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                              onChange={(e) => {
                                setButtonColor(e.target.value);
                              }}
                              type="color"
                              className="opacity-0"
                            />
                          </div>
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="cardtextcolor">Button Color</Label>
                          <Input
                            type="text"
                            id="cardtextcolor"
                            placeholder="#ffffff"
                            value={buttonColor}
                            onChange={(e) => {
                              setButtonColor(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="card_custom_body flex items-center gap-2">
                        <div
                          onClick={() => {
                            buttonTextColorRef.current.click();
                          }}
                          style={{ background: buttonTextColor }}
                          className="color_preview w-16 h-12 rounded-lg relative border-2"
                        >
                          <div className="absolute top-1/2 left-1/2">
                            <input
                              ref={buttonTextColorRef}
                              //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                              onChange={(e) => {
                                setButtonTextColor(e.target.value);
                              }}
                              type="color"
                              className="opacity-0"
                            />
                          </div>
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="cardtextcolor">
                            Button Text Color
                          </Label>
                          <Input
                            type="text"
                            id="cardtextcolor"
                            placeholder="#ffffff"
                            value={buttonTextColor}
                            onChange={(e) => {
                              setButtonTextColor(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="font_customization">
                    {/* <h2 className="text-lg font-medium">Font Customizations</h2> */}
                    <Separator className="mt-1 mb-4" />
                    <div className="flex items-center gap-4">
                      <div className="card_custom_body flex items-center gap-2">
                        <div
                          onClick={() => {
                            textColorRef.current.click();
                          }}
                          style={{ background: textColor }}
                          className="color_preview w-16 h-12 rounded-lg relative border-2"
                        >
                          <div className="absolute top-1/2 left-1/2">
                            <input
                              ref={textColorRef}
                              //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                              onChange={(e) => {
                                setTextColor(e.target.value);
                              }}
                              type="color"
                              className="opacity-0"
                            />
                          </div>
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="cardtextcolor">Font Color</Label>
                          <Input
                            type="text"
                            id="cardtextcolor"
                            placeholder="#ffffff"
                            value={textColor}
                            onChange={(e) => {
                              setTextColor(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="border_customization">
                    {/* <h2 className="text-lg font-medium">Border Customizations</h2> */}
                    <Separator className="mt-1 mb-4" />
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
                      <div className="card_custom_body flex items-center gap-2">
                        <div
                          onClick={() => {
                            borderColorRef.current.click();
                          }}
                          style={{ background: borderColor }}
                          className="color_preview w-16 h-12 rounded-lg relative border-2"
                        >
                          <div className="absolute top-1/2 left-1/2">
                            <input
                              ref={borderColorRef}
                              //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                              onChange={(e) => {
                                setBorderColor(e.target.value);
                              }}
                              type="color"
                              className="opacity-0"
                            />
                          </div>
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="cardtextcolor">Border Color</Label>
                          <Input
                            type="text"
                            id="cardtextcolor"
                            placeholder="#ffffff"
                            value={borderColor}
                            onChange={(e) => {
                              setBorderColor(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="card_custom_body flex items-center gap-2">
                        <div className="color_preview overflow-hidden w-16 h-12 rounded-lg relative border-2">
                          <div
                            style={{
                              borderColor: borderColor,
                              borderRadius:
                                borderRadius == "rounded" ? "6px" : "0px",
                            }}
                            className="absolute border-2 w-40 h-40 top-1/2 left-1/2"
                          ></div>
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="cardtextcolor">Border Radius</Label>
                          <div className="flex items-center gap-2">
                            <Button
                              variant={
                                borderRadius == "rounded"
                                  ? "default"
                                  : "outline"
                              }
                              onClick={() => {
                                setBorderRadius("rounded");
                              }}
                              className="rounded-[6px]"
                            >
                              Rounded
                            </Button>
                            <Button
                              variant={
                                borderRadius == "sharp" ? "default" : "outline"
                              }
                              onClick={() => {
                                setBorderRadius("sharp");
                              }}
                              className="rounded-none"
                            >
                              Sharp
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </TabsContent>
              <TabsContent value="banner">
                <div className="relative">
                  <BannerStyling
                    setBannerStyles={setBannerStyles}
                    bannerStyles={bannerStyles}
                  />
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </div>
        <div>
          <div className="hidden md:flex items-center justify-center pl-16 pr-8 border-l-[1px]">
            {activeTab == "checkout" ? (
              <div>
                <WidgetPreview styles={styles} />
                {/* <div className="md:flex md:items-center top-2 flex items-center md:mt-4 justify-center gap-2">
                <Button
                  onClick={resetToDefault}
                  variant="outline"
                  className="w-24 rounded-lg"
                >
                  Reset
                </Button>
                <Button
                  onClick={saveWidgetStyling}
                  className="w-48 rounded-lg"
                  //className="mx-auto self-center rounded-full h-9 mt-4"
                >
                  {isSaving ? (
                    <div className="dots"></div>
                  ) : isSaved ? (
                    <div className="flex items-center gap-1">
                      <CircleCheckBig size={14} />
                      Saved
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div> */}
              </div>
            ) : (
              <div>
                <BannerPreview bannerStyles={bannerStyles} />
                {/* <div className="md:flex md:items-center flex items-center mt-6 md:mt-4 justify-center gap-2">
                  <Button
                    onClick={resetToDefault}
                    variant="outline"
                    className="w-24 rounded-lg"
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={saveBannerStyling}
                    className="w-48 rounded-lg"
                    //className="mx-auto self-center rounded-full h-9 mt-4"
                  >
                    {isSaving ? (
                      <div className="dots"></div>
                    ) : isSaved ? (
                      <div className="flex items-center gap-1">
                        <CircleCheckBig size={14} />
                        Saved
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div> */}
              </div>
            )}
          </div>
        </div>
        <div>
          <Drawer>
            <DrawerTrigger className="fixed flex md:hidden bottom-8 left-1/2 -translate-x-[50%]">
              <Button className="rounded-full text-black bg-white shadow-lg">
                <Eye size={14} />
                Preview
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <div className="flex items-center justify-center mt-4">
                {activeTab == "checkout" ? (
                  <WidgetPreview styles={styles} />
                ) : (
                  <BannerPreview bannerStyles={bannerStyles} />
                )}
              </div>
              <DrawerFooter>
                {/* <div className="flex items-center gap-2 mx-4">
                <Button
                  variant="outline"
                  className="w-full mx-auto rounded-full"
                >
                  Reset to default
                </Button>
                <Button className="w-full mx-auto rounded-full">
                  Save Changes
                </Button>
              </div> */}
                <DrawerClose>
                  <Button
                    variant="outline"
                    className="rounded-full bg-white shadow-lg px-2 py-2"
                  >
                    <X size={14} />
                  </Button>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
      {isModalOpen && (
        <BookNowCodeModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      )}
    </div>
  );
};

export default Checkout;
