import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React, { useState } from "react";
import { EllipsisVertical, Info, PlusCircle } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button.jsx";
import { Separator } from "@/components/ui/separator.jsx";
import { Label } from "@/components/ui/label.jsx";
import { Switch } from "@/components/ui/switch.jsx";
import axios from "axios";
import { Skeleton } from "../ui/skeleton";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FaRegClock } from "react-icons/fa";
import SetExecutionTimeDialog from "@/components/whatsapp-automation/time-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export const TemplateList = ({
  templates,
  setActiveTemplate,
  setTemplates,
  eventSlug,
  category,
  isLoading,
}) => {
  const [error, setError] = useState(false);
  const [templateErrorId, setTemplateErrorId] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [timeTab, setTimeTab] = useState(false);
  const [time, setTime] = useState(0);
  const [params, setParams] = useState({
    id: null,
    status: null,
  });
  const changeTemplateStatus = async (id, status) => {
    // let isAnyLive;
    // let res;
    // try {
    //   isAnyLive = templates.some((o) => o.active == true);

    //   if (status == "false" || !isAnyLive) {
    //     const data = {
    //       category: category,
    //       status: status,
    //       event: eventSlug,
    //     };

    //     res = await axios.post(
    //       `${
    //         import.meta.env.VITE_APP_API_URL
    //       }/v3/update-template-status/${id}/`,
    //       data
    //     );
    //   }

    //   if (!res && isAnyLive) {
    //     setError(true);
    //     setTimeout(() => {
    //       setError(false);
    //     }, 1500);
    //     setTemplateErrorId(id)
    //   }

    //   if (res && res.status == 200) {
    //     if (status == "true") {
    //       if (isAnyLive == true) {
    //         setError(true);
    //         setTimeout(() => {
    //           setError(false);
    //         }, 1500);
    //         return;
    //       }
    //     }

    //     const editIndex = templates.findIndex((o) => o.id == id);
    //     console.log(editIndex);
    //     const edit = templates.find((o) => o.id == id);
    //     edit["active"] = status == "true" ? true : false;
    //     const updated = [...templates];
    //     updated[editIndex] = edit;
    //     setTemplates(updated);
    //   }
    // } catch (error) {
    //   console.error("Error updating template status:", error);
    //   // Optionally, you can set an error state here if you want to show the user an error message
    //   setError(true);
    //   setTimeout(() => {
    //     setError(false);
    //   }, 1500);
    // }
    const isAnyLive = templates.some((o) => o.active == true);
    const edit = [...templates];
    console.log(templates);
    if (isAnyLive) {
      const liveTemplate = templates.find((o) => o.active == true);
      const data = {
        category: category,
        status: false,
        event: eventSlug,
      };

      const res = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/update-template-status/${
          liveTemplate.id
        }/`,
        data
      );
      const edited = edit.find((o) => o.id == liveTemplate.id);
      edited["active"] = false;
      console.log(edited);
    }

    const data = {
      category: category,
      status: status,
      event: eventSlug,
    };
    const resp = await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/update-template-status/${id}/`,
      data
    );
    console.log(resp, edit);
    if (resp.status == 200) {
      const edited = edit.find((o) => o.id == id);
      edited["active"] = status == "true" ? true : false;
    }
    setTemplates(edit);
  };

  const deleteTemplate = async (id) => {
    const res = await axios.delete(
      `${import.meta.env.VITE_APP_API_URL}/v3/delete-template/${id}/`
    );
    if (res.status == 200) {
      const deleted = res.data.whatsapp_automation_deleted;
      const del = templates.findIndex((o) => o.id == deleted);
      const updated = [...templates];
      updated.splice(del, 1);
      setTemplates(updated);
    }
  };

  return (
    <>
      {/* {
        <span className="text-sm text-muted-foreground flex items-center gap-1">
          <Info size={16} color="rgb(220 38 38)" />
          Only one template can be live at a particular time
        </span>
      } */}
      
      
      {isLoading && (
        <div className="flex flex-col gap-2">
          <Skeleton className={"w-full h-[30px]"}></Skeleton>
          <Skeleton className={"w-full h-[30px]"}></Skeleton>
        </div>
      )}
      {templates?.length > 0 && (
        <div className="pb-8 hidden md:block">
          <Table className={"hidden md:table"}>
            <TableHeader>
              <TableRow>
                <TableHead>Template Name</TableHead>
                <TableHead>Message Content</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell className="font-medium">
                    <Skeleton className={"w-[90px] h-[20px]"}></Skeleton>
                  </TableCell>
                  <TableCell className="font-medium">
                    <Skeleton className={"w-[90px] h-[20px]"}></Skeleton>
                  </TableCell>
                  <TableCell className="font-medium">
                    <Skeleton className={"w-[90px] h-[20px]"}></Skeleton>
                  </TableCell>
                  <TableCell className="font-medium">
                    <Skeleton className={"w-[90px] h-[20px]"}></Skeleton>
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                templates?.length > 0 &&
                templates.map((template) => (
                  <TableRow
                    key={template.id}
                    className={`py-2 ${!template.active ? "opacity-60" : ""}`}
                  >
                    <TableCell className="font-medium">
                      <span
                        className={`inline-flex mt-1 capitalize items-center py-1 rounded-full`}
                      >
                        {template.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={"line-clamp-2 max-w-48"}>
                        {template?.header}
                        {template?.header && <br />}
                        {template?.text}
                        <br />
                        {template?.footer}
                      </span>
                      {/* <span className={`inline-flex mt-1 line-clamp-2 max-w-48 capitalize items-center py-1`}>
                    {template?.header}<br></br>{template.text}<br></br>{template?.footer}</span> */}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <span
                        className={`inline-flex mt-1 capitalize items-center px-2 py-1 rounded-full ${
                          template.status == "approved"
                            ? "bg-green-50 text-green-600"
                            : template.status == "pending"
                            ? "bg-amber-50 text-amber-600"
                            : template.status == "rejected"
                            ? "bg-red-50 text-red-600"
                            : ""
                        } text-xs`}
                      >
                        <span
                          className={`w-[0.35rem] h-[0.35rem] rounded-full ${
                            template.status == "approved"
                              ? "bg-green-600"
                              : template.status == "pending"
                              ? "bg-amber-600"
                              : template.status == "rejected"
                              ? "bg-red-600"
                              : ""
                          } text-xs mr-1.5`}
                        ></span>
                        {template.status}
                      </span>
                    </TableCell>
                    <TableCell
                      className={"items-center flex justify-center pt-6"}
                    >
                      <Switch
                        checked={template.active}
                        className="h-5"
                        onCheckedChange={(value) => {
                          if(value == true){
                          var isAnyLive = templates.find(
                            (o) => o.active == true
                          );
                          if (isAnyLive) {
                            setIsConfirmationOpen(true);
                            setParams({
                              id: template.id,
                              status: value.toString(),
                            });
                          } else {
                            changeTemplateStatus(template.id, value.toString());
                          }
                        }
                        else{
                          changeTemplateStatus(template.id, value.toString());
                        }
                        }}
                      />
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          className={"items-center justify-center"}
                        >
                          <EllipsisVertical size={18} />{" "}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align={"end"}>
                          <DropdownMenuItem
                            onSelect={() => {
                              setActiveTemplate(template.id);
                            }}
                          >
                            Edit
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onSelect={() => {
                              deleteTemplate(template.id);
                            }}
                          >
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}
      {!isLoading && !templates && (
        <div className="my-3 hidden md:flex items-center gap-2">
          <Info size={16} />
          No templates to show for the selected category
        </div>
      )}
      <div className={"flex py-4 flex-col pb-8 gap-4 md:hidden "}>
        {isLoading && (
          <div className="flex flex-col gap-4">
            <Skeleton className={"w-full h-40"} />
            <Skeleton className={"w-full h-40"} />
          </div>
        )}
        <div className="flex flex-col gap-4">
          {!isLoading &&
            templates?.length > 0 &&
            templates.map((template) => (
              <div
                className={`p-4 flex relative rounded-md flex-col gap-2 border-[1px]`}
              >
                {!template.active && (
                  <div
                    className={
                      "absolute top-0 left-0 bg-gray-50/50 w-full h-full z-0"
                    }
                  ></div>
                )}
                <div className={"flex items-start justify-between"}>
                  <div className={"flex flex-col gap-0"}>
                    <span className={"text-muted-foreground text-xs"}>
                      Template Name
                    </span>
                    <span className={"text-sm"}>{template.name}</span>
                  </div>
                  <div
                    className={"flex items-center top-2 right-2 absolute gap-2"}
                  >
                    <span
                      className={`inline-flex mt-1 capitalize items-center px-2 py-1 rounded-full ${
                        template.status == "approved"
                          ? "bg-green-50 text-green-600"
                          : template.status == "pending"
                          ? "bg-amber-50 text-amber-600"
                          : template.status == "rejected"
                          ? "bg-red-50 text-red-600"
                          : ""
                      } text-xs`}
                    >
                      <span
                        className={`w-[0.35rem] h-[0.35rem] rounded-full ${
                          template.status == "approved"
                            ? "bg-green-600"
                            : template.status == "pending"
                            ? "bg-amber-600"
                            : template.status == "rejected"
                            ? "bg-red-600"
                            : ""
                        } text-xs mr-1.5`}
                      ></span>
                      {template.status}
                    </span>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <EllipsisVertical size={14} />{" "}
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align={"end"}>
                        <DropdownMenuItem
                          onSelect={() => {
                            setActiveTemplate(template.id);
                          }}
                        >
                          Edit
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onSelect={() => {
                            deleteTemplate(template.id);
                          }}
                        >
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <div className={"flex flex-col gap-0"}>
                  <span className={"text-muted-foreground text-xs"}>
                    Message Content
                  </span>
                  <span className={"line-clamp-2 text-sm"}>
                    {/* {template.message.header}
                <br />
                {template.message.content}
                <br />
                {template.message.footer} */}
                    {template.text}
                  </span>
                </div>
                <Separator className={"my-1"} />
                <div
                  className={"flex relative z-10 items-center justify-between"}
                >
                  <Label>Make it live</Label>
                  <Switch
                    checked={template.active}
                    className={"h-5"}
                    onCheckedChange={(value) => {
                      var isAnyLive;
                      changeTemplateStatus(template.id, value.toString());
                    }}
                  />
                </div>
                {error && templateErrorId == template.id && (
                  <span className="flex text-xs text-red-600 items-center gap-2">
                    <Info size={14} />
                    Only one template can be live at a particular time
                  </span>
                )}
              </div>
            ))}
        </div>
        {!isLoading && !templates && (
          <div className=" md:hidden flex items-center gap-2">
            <Info size={16} />
            No templates to show for the selected category
          </div>
        )}
      </div>
      <div className="sticky bottom-[-2%] w-full bg-white z-20 py-2">
        <Button
          onClick={() => {
            setActiveTemplate("new");
          }}
          variant="outline"
          className={"mt-2 w-full"}
        >
          <PlusCircle size={14} />
          Create new template
        </Button>
      </div>
      {isConfirmationOpen && (
        <Dialog open={isConfirmationOpen} onOpenChange={setIsConfirmationOpen}>
          <DialogContent className="lg:max-w-sm">
            <DialogHeader>
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogDescription>
                <span className="flex items-center gap-2">
                  <Info size={14} />
                Only one template can be live at a particular time
                </span><br />
                <span className="text-foreground font-medium">This action will put your live templates, into draft mode and make this one live.</span>
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <div className="flex justify-end items-center gap-2">
                <DialogClose><Button variant="outline">Cancel</Button></DialogClose>
                <DialogClose><Button onClick={()=>{
                  changeTemplateStatus(params.id, params.status)
                }}>Proceed</Button>
                </DialogClose>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      {timeTab && (
        <SetExecutionTimeDialog 
          open={timeTab} 
          onClose={() => setTimeTab(false)} 
          defaultTime={time}
          onSave={handleSave} // Fixed auto-execution issue
        />
      )}
    </>
  );
};
