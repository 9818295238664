import React from 'react';
import { Search, Edit2, Trash2 } from 'lucide-react';

const WhatsappQ = () => {
  const questions = [
    {
      id: 1,
      question: "Cost for Up in the clouds ?",
      answer: "Price is Rs 9999,"
    },
    {
      id: 2,
      question: "Hello Can I get more info about 200hrs YTT course?",
      answer: "Thank you for reaching out! Discover the details of our 200-hour Yoga Teacher Training course."
    }
  ];

  return (
    <div className=" mx-auto p-4">
      <h1 className="text-2xl font-medium mb-6">WhatsApp Questions & Answers : Out There Trips</h1>
      
      <div className="relative mb-4">
        <input 
          type="text" 
          placeholder="Search..." 
          className="w-full p-2 border rounded-lg pr-10 bg-white"
        />
        <Search className="absolute right-3 top-2.5 text-gray-400 h-5 w-5 " />
      </div>

      <button className="bg-black text-white px-4 py-2 rounded-lg mb-6 hover:bg-black">
        Create New Question Answer
      </button>

      <div className="space-y-4">
        {questions.map((qa) => (
          <div key={qa.id} className="border rounded-lg p-4 bg-white shadow-sm">
            <div className="mb-2">
              <span className="font-medium">Que : </span>
              <span>{qa.question}</span>
            </div>
            <div className="mb-3">
              <span className="font-medium">Ans : </span>
              <span>{qa.answer}</span>
            </div>
            <div className="flex gap-2">
              <button className="flex items-center gap-1 bg-black text-white px-3 py-1 rounded hover:bg-black">
                <Edit2 className="h-4 w-4" />
                Edit
              </button>
              <button className="flex items-center gap-1 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600">
                <Trash2 className="h-4 w-4" />
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatsappQ;