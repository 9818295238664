import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DatePicker } from "../Custom/DatePicker";
import useLeadsStore from "@/store/leadsStore";
import axios from "axios";
import { ownerOptions, sourceOptions, stageOptions, statusOptions } from "@/lib/data";
import { useAuthStore } from "@/store/store";

const CustomerProfileForm = ({ isOpen, setIsCustomerProfileOpen, customer_profile }) => {
  const { companyslug } = useAuthStore();
  const [customerProfile, setCustomerProfile] = useState(customer_profile);
  const [isUpdating, setIsUpdating] = useState(false);
  const {refetchApi} = useLeadsStore();

  const handleChange = (field, value) => {
    setCustomerProfile({ ...customerProfile, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/edit-company-customer-profile/${companyslug}/`, {"customer_profile_data": customerProfile});
      // setEvents(response.data?.results);
      setIsCustomerProfileOpen(false)
      refetchApi();
    } catch (error) {
      console.error("Error Updating customer profile:", error.response ? error.response.data : error.message);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsCustomerProfileOpen}>
      <DialogContent
      className={
          "lg:max-w-2xl overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
        }>
        <DialogHeader>
          <DialogTitle>Edit Enquiry</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="form-group">
            <Label htmlFor="name">Full Name</Label>
            <Input
              name="name"
              value={customerProfile.fullname}
              onChange={(e) => handleChange("fullname", e.target.value)}
              type="text"
              placeholder="Full Name"
              required
            />
          </div>

          <div className="form-group">
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              value={customerProfile.email}
              onChange={(e) => handleChange("email", e.target.value)}
              placeholder="Email Address"
            />
          </div>

          <div className="form-group">
            <Label htmlFor="mobile">Contact No.</Label>
            <Input
              type="text"
              name="mobile"
              value={customerProfile.mobile}
              onChange={(e) => handleChange("mobile", e.target.value)}
              maxLength={10}
              pattern="[1-9]{1}[0-9]{9}"
              placeholder="Contact No."
            />
          </div>

          <div className="form-group">
            <Label htmlFor="gst_number">GST Number</Label>
            <Input
              name="gst_number"
              value={customerProfile.gst_number}
              onChange={(e) => handleChange("gst_number", e.target.value)}
              type="text"
              placeholder="GST Number"
            />
          </div>

          {/* <div className="form-group">
            <Label htmlFor="city">Customer Location</Label>
            <Select
              onValueChange={(value) => handleChange("city", value)}
              value={customerProfile.city}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select city" />
              </SelectTrigger>
              <SelectContent>
                {citiesList.map((city) => (
                  <SelectItem key={city.slug} value={city.slug}>
                    {city.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div> */}

          <div className="form-group">
            <Label htmlFor="gender">Gender</Label>
            <Select
              onValueChange={(value) => handleChange("gender", value)}
              value={customerProfile.gender}
            >
              <SelectTrigger>
                <SelectValue placeholder="----" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="male">Male</SelectItem>
                <SelectItem value="female">Female</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="form-group">
            <label>Source</label>
            <Select
              onValueChange={(value) => handleChange("source", value)}
              value={customerProfile.source}
            >
              <SelectTrigger className="max-w-48">
                <SelectValue placeholder="Select Source" />
              </SelectTrigger>
              <SelectContent>
                {sourceOptions?.map((source) => (
                  <SelectItem
                    key={source.value}
                    value={source.value.toString()}
                    className="max-w-48"
                  >
                    {source.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="form-group">
              <Label htmlFor="age">Age</Label>
              <Input
                type="number"
                name="age"
                value={customerProfile.age}
                onChange={(e) => handleChange("age", e.target.value)}
              />
            </div>

            <div className="form-group">
              <Label htmlFor="dob">Date of Birth</Label>
              <DatePicker
                selected={customerProfile.dob}
                onChange={(date) => handleChange("dob", date)}
                placeholderText="Select date"
              />
            </div>
          </div>

          <input
            type="hidden"
            name="facebook_user_id"
            value={customerProfile.facebook_user_id}
          />
          <input
            type="hidden"
            name="instagram_user_id"
            value={customerProfile.instagram_user_id}
          />

          <div className="modal-footer">
            <Button type="submit" disabled={isUpdating}>
              {isUpdating ? "Updating..." : "Update"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerProfileForm;
