"use client"

import { X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Badge } from "@/components/ui/badge"
import { DataTableViewOptions } from "./data-table-view-options"

export function DataTableToolbar({
  table,
  filterOptions
}) {
  const isFiltered = table.getState().columnFilters.length > 0

  return (
    <div className="flex items-center justify-between">
        <div className="flex gap-2 max-w-[90vw] overflow-x-auto flex-nowrap text-nowrap items-center">

        </div>
      <DataTableViewOptions table={table} />
    </div>
  )
}
