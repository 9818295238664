"use client";

import { ChevronRight } from "lucide-react";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { UsersRound } from "lucide-react";
import { useSidebar } from "@/components/ui/sidebar";

export function NavMain({ items }) {
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const { setOpenMobile } = useSidebar();

  const updateRoute = (value) => {
    if(value.startsWith("/")){
      window.open(import.meta.env.VITE_APP_API_URL + value);
      return;
    }
    if(value.startsWith("https")){
      window.open(value);
      return
    }
    queryParams.set("switchVar", value);

    // Construct the new URL
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    // Navigate to the updated URL
    navigate(newUrl);
    setOpenMobile(false);
  }

  return (
    <SidebarGroup>
      <SidebarMenu className="gap-2">
        {items.map((item) =>
          item?.items ? (
            <Collapsible
              key={item.title}
              asChild
              defaultOpen={item.isActive}
              className="group/collapsible"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton tooltip={item.title}>
                    {item.icon && <item.icon size={16}/>}
                    <span>{item.title}</span>
                    <ChevronRight size={16} className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {item.items?.map((subItem) => (
                      <SidebarMenuSubItem onClick={()=>updateRoute(subItem.url)} key={subItem.title}>
                        <SidebarMenuSubButton asChild>
                          <div className="flex items-center gap-2">
                        {subItem.icon && <subItem.icon size={16}/>}
                            <span className="cursor-pointer" >{subItem.title}</span>
                          </div>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          ) : (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton tooltip={item.title} onClick={()=>updateRoute(item.url)}>
                {item.icon && <item.icon size={16}/>}
                <span className="cursor-pointer" >{item.title}</span>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}
